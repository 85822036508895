export enum TeamConfigType {
  LIST = 'list',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  STRING = 'string',
}

export enum TeamConfigAlias {
  SERVICE_FILTER = 'service_filter',
  TEAM_FILTER = 'team_filter',
  SERVICE_PRODUCTION_LAST_STEP = 'service_production_last_step',
  HAS_MULTIPLE_SERVICES = 'has_multiple_services',
}

export type TeamConfig = {
  team: number;
  value: string;
  type: TeamConfigType;
  alias: TeamConfigAlias;
};
