import { Card, Flex, Text } from '@mantine/core';
import { IconReceipt } from '@tabler/icons';
import { formatLocale } from '../../providers/dayjs-plugins';
import { formatBRL } from '../../utils/helpers';
import { GetReceiptsByOrderResponse } from '../../utils/types/data/services/order-receipts';

interface NfReceiptItemProps {
  data: GetReceiptsByOrderResponse[];
}

export function NfReceiptItem({ data }: NfReceiptItemProps) {
  return (
    <>
      {data?.map((rItem) => (
        <Card mt={16} withBorder mb={16} key={rItem.id}>
          <Flex>
            <IconReceipt size={20} />
            <Text ml={8} fw="bold" size={16}>
              Recibo: {rItem.code}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Valor total
              </Text>
              <Text>{formatBRL(rItem.total_value)}</Text>
            </Flex>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Parcelado em
              </Text>
              <Text>{rItem.installments}x</Text>
            </Flex>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Emitido em
              </Text>
              <Text>{formatLocale(rItem.created_at, 'DD/MM/YYYY')}</Text>
            </Flex>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Emitido por
              </Text>
              <Text>{rItem.receptionist}</Text>
            </Flex>
          </Flex>
          <Text fw="bold" color="gray" size={12}>
            OS's relacionadas:
          </Text>
          <Text>{rItem.orders?.map((ordItem) => ordItem.os).join(',')}</Text>
        </Card>
      ))}
    </>
  );
}
