export const apiConfig = {
  url: process.env.REACT_APP_BASE_API,
};

export const appConfig = {
  devName: process.env.REACT_APP_DEV_NAME,
  name: process.env.REACT_APP_NAME,
  sidebarImage: process.env.REACT_APP_SIDEBAR_IMAGE,
  notificationIcon: process.env.REACT_APP_NOTIFICATION_ICON,
};
