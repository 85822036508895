import axios from 'axios';
import { logoutDispatcher } from '../store/auth';
import { LocalStorageKeys } from '../utils/enums';
import { errorNotification } from './mantine-notifications';

let store: {
  getState: any;
  dispatch: any;
};

export const injectStore = (_store: any) => {
  store = _store;
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const authToken = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);

  if (authToken && config.headers) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const {signed} = store.getState().auth;

    const status = error.response ? error.response.data.message : null;
    if (status === 'invalid signature' || status === 'jwt expired') {
      store.dispatch(logoutDispatcher());
      errorNotification({
        title: 'Erro de autenticação!',
        message: 'faça o login novamente',
      });
    } else {
      throw error;
    }
  },
);
