import { Checkbox } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface SublimacaoFormItemProps {
  form: UseFormReturnType<any>;
}

export function SublimacaoEditItem({ form }: SublimacaoFormItemProps) {
  return (
    <>
      <Checkbox
        label="Possui Dados variavies ?"
        value="rotacionado"
        color="green"
        name="hasManyData"
        mb={16}
        checked={form.values.details.hasManyData}
        {...form.getInputProps('details.hasManyData')}
      />
      <Checkbox
        label="É Gravação 360˚ ?"
        value="rotacionado"
        color="green"
        name="has360Engraving"
        mb={16}
        checked={form.values.details.has360Engraving}
        {...form.getInputProps('details.has360Engraving')}
      />
      <Checkbox
        label="Tem manuseio de aplicação ?"
        description="aplicação no produto acima"
        color="green"
        name="hasManipulation"
        mb={16}
        {...form.getInputProps('details.hasManipulation')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}
