import { useEffect, useState } from 'react';
import {
  Image,
  Text,
  TextInput,
  Group,
  Button,
  ThemeIcon,
  Container,
  Paper,
  Flex,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconEye, IconMail } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components/Page';
import { AppRoutePaths } from '../../utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../providers/store';
import { loginDispatcher } from '../../store/auth';
import { CustomLoader } from '../../components/CustomLoader';
import { appConfig } from '../../config';

export function Login() {
  const navigation = useNavigate();
  const { loading, user } = useSelector((state: RootState) => state.auth);
  const [showPassword, setShowPassword] = useState<'password' | 'text'>(
    'password',
  );
  const dispatch = useDispatch<any>();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'e-mail inválido'),
    },
  });

  const handleSubmit = ({ email, password }: typeof form.values) => {
    dispatch(loginDispatcher({ email, password }));
  };

  function redirectToA3() {
    return (window.location.href = 'https://a3apps.com.br');
  }

  function toResetPassword() {
    navigation(AppRoutePaths.RESET_PASSWORD);
  }

  const redirectUrl = localStorage.getItem('redirect_url');

  useEffect(() => {
    if (user) {
      if (redirectUrl) {
        navigation(redirectUrl);
        localStorage.removeItem('redirect_url');
      } else {
        navigation({ pathname: AppRoutePaths.HOME });
      }
    }
  });

  return (
    <Page>
      <CustomLoader loading={loading} />
      <Container maw="360px" p={0}>
        <Paper miw="300px" mt={60} radius={8} p={24} shadow="lg">
          <Flex direction="column">
            <Image
              style={{ alignSelf: 'center' }}
              src={appConfig.sidebarImage}
              width={200}
              alt="logo image"
              mt={32}
              mb={8}
            />
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <TextInput
                withAsterisk
                label="Email"
                placeholder="seu@email.com.br"
                {...form.getInputProps('email')}
                rightSection={
                  <ThemeIcon color="orange" variant="light">
                    <IconMail size={16} />
                  </ThemeIcon>
                }
                onChange={(event) =>
                  form.setFieldValue('email', event.target.value.trim())
                }
                mb={16}
              />
              <TextInput
                withAsterisk
                label="Senha"
                placeholder="digite sua senha"
                {...form.getInputProps('password')}
                mb={16}
                security="true"
                rightSection={
                  <ThemeIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setShowPassword(
                        showPassword === 'text' ? 'password' : 'text',
                      )
                    }
                    color="orange"
                    variant="light"
                  >
                    <IconEye size={16} />
                  </ThemeIcon>
                }
                type={showPassword}
              />
              <Group position="right" mt="md">
                <Button color="ltpBlue.9" type="submit">
                  Logar
                </Button>
              </Group>
            </form>
          </Flex>
        </Paper>
        <Group mt={16} grow>
          <Text
            style={{ cursor: 'pointer' }}
            align="center"
            color="dark.4"
            variant="link"
            onClick={toResetPassword}
          >
            você esqueceu a senha ?
          </Text>
        </Group>
        <Flex mt={32} align="center" justify="center">
          <Text
            style={{ cursor: 'pointer' }}
            fw="bold"
            size={12}
            mb={-19}
            color="gray.9"
            align="center"
            onClick={redirectToA3}
          >
            Desenvolvido por
          </Text>
          <Image
            onClick={redirectToA3}
            style={{ cursor: 'pointer' }}
            height={46}
            width={46}
            src="https://orders-files.nyc3.cdn.digitaloceanspaces.com/media/a3-classic-logo.png"
          />
        </Flex>
      </Container>
    </Page>
  );
}
