import { useEffect } from 'react';

export function useUrlParams(
  filters: any,
  currentPage: React.MutableRefObject<number>,
  searchParams: URLSearchParams,
  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>,
) {
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(filters.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        if (key !== 'limit') {
          newParams.set(key, val.toString());
          if (key === 'page') {
            newParams.set(key, currentPage.current.toString());
          }
        }
      } else {
        newParams.delete(key);
      }
    });

    setSearchParams(newParams);
  }, [filters.values, setSearchParams, searchParams, currentPage.current]);
}
