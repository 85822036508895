import { ThemeIcon, NavLink } from '@mantine/core';
import { IconWriting } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface ActivitiesLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function ActivityLayoutLink({
  navigate,
  themeColor,
}: ActivitiesLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Layouts"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="teal" variant="light">
          <IconWriting size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.ACTIVITY_LAYOUTS)}
      />
    </NavLink>
  );
}
