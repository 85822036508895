import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { RootState } from '../../providers/store';
import { getReceiptsPaginatedDispatcher } from '../../store/receipt';
import { FinancialHomeWeb } from './financial';
import { realToUSCash } from '../../utils/helpers';
import { useRemoveReceipt } from '../../data/hooks/receipts';
import { Page } from '../../components/Page';
import { Button, Group, Modal, Text } from '@mantine/core';
import { ReceiptResponse } from '../../utils/types/data/services/receipts';
import { successNotification } from '../../providers/mantine-notifications';

export type FormFilterType = {
  page: number;
  limit: number;
  os?: string | null;
  createdAt?: string | null;
  code_receipt?: string | null;
  value_receipt?: string | null;
  showCancelled?: boolean;
};

export function ReceiptList() {
  const dispatch = useDispatch<any>();
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [pageModal, setPageModal] = useState(false);
  const [selectedRecepit, setSelectedRecepit] = useState<ReceiptResponse>();
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.receipt,
  );
  const { fetch: removeReceiptFetcher, loading: removeReceiptLoader } =
    useRemoveReceipt();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      os: '',
      createdAt: undefined,
      code_receipt: '',
      value_receipt: '',
      showCancelled: false,
    },
  });

  function handleSubmit() {
    currentPage.current = 1;
    getReceiptPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getReceiptPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getReceiptPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getReceiptPaginated(true);
  }

  function getReceiptPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        if (key === 'value_receipt') {
          validatedFilter['value_receipt'] = realToUSCash(val.toString());
        } else {
          validatedFilter[key] = val;
        }
      }
    });

    if (isReset) {
      dispatch(
        getReceiptsPaginatedDispatcher({
          page: 1,
          limit: 10,
        }),
      );
    } else {
      dispatch(
        getReceiptsPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
        }),
      );
    }
  }

  function handleCancelReceipt() {
    setPageModal(true);
  }

  function abortCancelReceipt() {
    setPageModal(false);
    setSelectedRecepit(undefined);
  }

  async function cancelReceipt() {
    if (selectedRecepit) {
      await removeReceiptFetcher({
        params: {
          id: selectedRecepit?.id,
        },
        onSuccess: () => {
          getReceiptPaginated();
          abortCancelReceipt();
          successNotification({
            title: 'Recibo cancelado!',
            message: 'Tudo certo.',
          });
        },
      });
    }
  }

  useEffect(() => {
    getReceiptPaginated();
  }, []);

  return (
    <Page>
      <CustomLoader loading={loading || removeReceiptLoader} />
      <FinancialHomeWeb
        items={items}
        meta={meta}
        currentPage={currentPage}
        formFilter={formFilter}
        handleSubmit={handleSubmit}
        handleChangePageLimit={handleChangePageLimit}
        handlePaginate={handlePaginate}
        handleClear={handleClearFilter}
        handleCancelReceipt={handleCancelReceipt}
        setSelectedRecepit={setSelectedRecepit}
      />
      <Modal
        size={700}
        opened={pageModal}
        onClose={() => setPageModal(false)}
        title={<Text fw="bold">Cancelar recibo</Text>}
      >
        <Text>Deseja cancelar este recibo "{selectedRecepit?.code}" ?</Text>
        <Group mt={16}>
          <Button onClick={cancelReceipt} color="green" type="submit">
            Sim
          </Button>
          <Button onClick={abortCancelReceipt} color="red" type="submit">
            Não
          </Button>
        </Group>
      </Modal>
    </Page>
  );
}
