import { AxiosResponse } from 'axios';
import { User, UserPopulated } from '../../models/user';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  CreateUserData,
  GetUsersPaginatedQuery,
} from '../../utils/types/data/services/users';

export async function getUsersListResquest(
  query: GetUsersPaginatedQuery,
): Promise<PaginatedResponse<UserPopulated>> {
  const response = await api.get<PaginatedResponse<UserPopulated>>(`users`, {
    params: query,
  });

  return response.data;
}

export async function createUserResquest(
  payload: CreateUserData,
): Promise<AxiosResponse<User>> {
  const response = await api.post<User>(`users`, payload);

  return response;
}

export async function updateUserResquest(
  id: number,
  payload: CreateUserData,
): Promise<AxiosResponse<User>> {
  const response = await api.put<User>(`users/${id}`, payload);

  return response;
}

export async function findUserResquest(id: number): Promise<User> {
  const response = await api.get<User>(`users/${id}`);

  return response.data;
}
