import { Text, Image, Title, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components/Page';
import { appConfig } from '../../config';
import { AppRoutePaths } from '../../utils/enums';

export function ConfirmBudget() {
  const navigation = useNavigate();

  return (
    <Page>
      <Image src={appConfig.sidebarImage} />
      <Title
        size={36}
        fw="bolder"
        color="ltp-blue"
        order={1}
        mt={24}
        align="left"
      >
        Orçamento Solicitado
      </Title>
      <Text align="left" color="gray">
        Muito bem, daqui a pouco um de nossos vendedores vai entrar em contato
        com você para os próximos passos.
      </Text>
      <Text align="left" color="gray">
        Até la! 👋
      </Text>
      <Group position="left" mt="md">
        <Button
          color="ltpBlue.9"
          onClick={() => navigation(AppRoutePaths.BUDGET)}
        >
          Voltar
        </Button>
      </Group>
    </Page>
  );
}
