import { useFetch } from '../../../hooks/use-fetch';
import { OrderPacking } from '../../../models/order-packing';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  CreateOrderPacking,
  getLastOrderPackingRequest,
  getOrderPackingsRequest,
  setOrderPackingRequest,
  setOutOrderPackingRequest,
  setPackUnpackOrderPackingClockoutRequest,
  SetPackUnpackOrderPackingsRequestData,
} from '../../services/order-packing';

interface UseSetPackUnpackOrderPackingsProps {
  id: number;
  data: SetPackUnpackOrderPackingsRequestData;
  onSuccess: () => void;
}

interface SetOrderPackingProps {
  orderId: number;
  data: CreateOrderPacking;
  onSuccess: (res: OrderPacking) => void;
}

export function useGetOrderPackings() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderPacking[]>();

  const fetch = ({
    orderId,
    onSuccess,
  }: {
    orderId: number;
    onSuccess: (res: OrderPacking[]) => void;
  }) =>
    fetchData({
      fetcher: () => getOrderPackingsRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar registros.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetLastOrderPacking() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderPacking>();

  const fetch = ({
    orderId,
    onSuccess,
  }: {
    orderId: number;
    onSuccess: (res: OrderPacking) => void;
  }) =>
    fetchData({
      fetcher: () => getLastOrderPackingRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar registro.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useSetPackUnpackOrderPackings() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderPacking>();

  const fetch = ({ id, data, onSuccess }: UseSetPackUnpackOrderPackingsProps) =>
    fetchData({
      fetcher: () => setPackUnpackOrderPackingClockoutRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao finalizar etapa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useSetOrderPacking() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderPacking>();

  const fetch = ({ orderId, data, onSuccess }: SetOrderPackingProps) =>
    fetchData({
      fetcher: () => setOrderPackingRequest(orderId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao iniciar etapa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useSetOutOrderPacking() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderPacking>();

  const fetch = ({ id, onSuccess }: { id: number; onSuccess: () => void }) =>
    fetchData({
      fetcher: () => setOutOrderPackingRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao finalizar etapa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
