/* eslint-disable @typescript-eslint/no-explicit-any */
import { NumberInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';

interface DecalqueFormItemProps {
  form: UseFormReturnType<any>;
}

export function DecalqueFormItem({ form }: DecalqueFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        sidesAmount: '',
        personalizationArea: '',
        hasSample: false,
      },
    });
  }, []);

  return (
    <>
      <TextInput
        required
        withAsterisk
        label="Detalhe o local de aplicação"
        placeholder="ex: frente, verso, fundo, borda e etc..."
        mb={16}
        name="personalizationArea"
        maxLength={255}
        {...form.getInputProps('details.personalizationArea')}
      />
      <NumberInput
        required
        withAsterisk
        label="Cores"
        placeholder="quantidade de cores"
        mb={16}
        min={0}
        name="colorsAmount"
        {...form.getInputProps('details.colorsAmount')}
      />
    </>
  );
}
