import { Flex } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { Dayjs } from 'dayjs';
import { useRef } from 'react';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import { CustomDatePicker } from '../CustomDatePicker';

interface CustomDateTimePickerProps {
  date: Date;
  onChange: (dateTime: Date) => void;
  label: string;
  disabled?: boolean;
  mt?: number;
  dropdownType?: 'modal' | 'popover';
}

export function CustomDateTimePicker({
  date,
  onChange,
  label,
  disabled = false,
  mt,
  dropdownType,
}: CustomDateTimePickerProps) {
  const dateOverride = useRef(date);

  function setDateTime(datex: Dayjs, time: Dayjs) {
    onChange(
      dayjsPlugins(
        `${datex.format('YYYY-MM-DD')}T${time.format('HH:mm:ss')}`,
      ).toDate(),
    );
  }

  return (
    <Flex>
      <CustomDatePicker
        dropdownType={dropdownType}
        mt={mt}
        disabled={disabled}
        label={label}
        clearable={false}
        placeholder="selecione uma data"
        styles={{
          input: { border: 0 },
        }}
        value={dateOverride.current}
        onChange={(dates: Date) => {
          dateOverride.current = dates;
          setDateTime(
            dayjsPlugins(dateOverride.current),
            dayjsPlugins(dateOverride.current),
          );
        }}
      />
      <TimeInput
        mt={mt}
        disabled={disabled}
        label={label ? 'Hora (24h)' : undefined}
        clearable={false}
        styles={{ input: { border: 0 } }}
        value={dateOverride.current}
        onChange={(values) =>
          setDateTime(dayjsPlugins(dateOverride.current), dayjsPlugins(values))
        }
      />
    </Flex>
  );
}
