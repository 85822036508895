import {
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Modal,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomDatePicker } from '../../components/CustomDatePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigate } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import {
  useGetOrdersToDispatch,
  useSetOrdersToDispatch,
} from '../../data/hooks/orders';
import { formatLocale } from '../../providers/dayjs-plugins';
import { orderStatusColor, orderStatusHumanized } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';
import { GetOrdersToDispatchResponse } from '../../utils/types/data/services/orders';

export function OrderDispatch() {
  const { hash } = useParams();
  const [pageModal, setPageModal] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<
    GetOrdersToDispatchResponse[]
  >([]);
  const {
    fetch: getOrdersFetcher,
    loading: getOrdersLoader,
    response: getOrdersData,
  } = useGetOrdersToDispatch();

  const { fetch: setOrdersFetcher, loading: setOrdersLoader } =
    useSetOrdersToDispatch();

  const setDispatchForm = useForm({
    initialValues: {
      dispatchForecast: new Date(),
      dispatchName: '',
    },
  });

  async function getOrders() {
    await getOrdersFetcher({
      hash: String(hash),
    });
  }

  async function handleSetOrdersDispatch(
    values: typeof setDispatchForm.values,
  ) {
    if (selectedOrders.length) {
      await setOrdersFetcher({
        data: {
          orderIds: selectedOrders.map((item) => item.id),
          dispatchDate: values.dispatchForecast.toISOString(),
          dispatchName: values.dispatchName,
        },
        onSuccess: () => {
          setPageModal(true);
        },
      });
    }
  }

  useEffect(() => {
    getOrders();
  }, [hash]);

  return (
    <Page>
      <CustomLoader loading={getOrdersLoader || setOrdersLoader} />
      <Flex justify="center" w="100%">
        <Card shadow="md" p={32} maw={640}>
          <Title size={24}>Retirada de Pedidos</Title>
          <Text>
            Selecione os pedidos que deseja retirar, informe a data e nos diga o
            nome de quem irá buscar.
          </Text>
          <DataTable
            selectedRecords={selectedOrders}
            onSelectedRecordsChange={setSelectedOrders}
            mt={16}
            height={400}
            noRecordsText="Sem pedidos"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getOrdersData ?? []}
            columns={[
              {
                accessor: 'internalNumber',
                title: 'Identificador',
              },
              {
                accessor: 'os',
                title: 'OS',
              },
              {
                accessor: 'status',
                title: 'Status',
                render: ({ status }) => (
                  <Badge color={orderStatusColor[status]} mb={6}>
                    {orderStatusHumanized[status]}
                  </Badge>
                ),
              },
              {
                accessor: 'service.name',
                title: 'Serviço',
              },
              {
                accessor: 'engravingText',
                title: 'Logotipo',
              },
              {
                accessor: 'quantity',
                title: 'Qtde',
                width: 60,
              },
              {
                accessor: 'product',
                title: 'Produto',
              },
              {
                accessor: 'finishForecast',
                title: 'Finalizado em',
                render: ({ finishedAt }) =>
                  finishedAt && formatLocale(finishedAt, 'DD/MM/YY HH:mm'),
                width: 110,
              },
              {
                accessor: 'createdAt',
                title: 'Criado em',
                render: ({ createdAt }) =>
                  createdAt && formatLocale(createdAt, 'DD/MM/YY'),
                width: 110,
              },
            ]}
          />
          <form onSubmit={setDispatchForm.onSubmit(handleSetOrdersDispatch)}>
            <Grid mt={8} columns={2}>
              <Grid.Col xs={2} sm={1} md={1}>
                <CustomDatePicker
                  label="Data de Retirada"
                  placeholder="selecione uma data"
                  lang="br"
                  mb={16}
                  mr={8}
                  {...setDispatchForm.getInputProps('dispatchForecast')}
                />
              </Grid.Col>
              <Grid.Col xs={2} sm={1} md={1}>
                <TextInput
                  required
                  withAsterisk
                  label="Responsável por retirada"
                  placeholder="informe o nome"
                  mb={16}
                  mr={8}
                  type="text"
                  {...setDispatchForm.getInputProps('dispatchName')}
                />
              </Grid.Col>
            </Grid>
            <Group position="right">
              <Button color="ltpBlue.7" type="submit">
                Salvar
              </Button>
            </Group>
          </form>
        </Card>
      </Flex>
      <Modal
        opened={pageModal}
        onClose={() => setPageModal(false)}
        title=""
        size="md"
      >
        <Flex mb={16} align="center" direction="column">
          <ThemeIcon size={44} color="green">
            <IconCheck size={40} />
          </ThemeIcon>
          <Title>Tudo certo!</Title>
          <Text>Você ja pode ir retirar seus pedidos</Text>
        </Flex>
        <Group position="right">
          <Button
            onClick={() => rootNavigate(AppRoutePaths.LOGIN)}
            color="ltpBlue.7"
            type="submit"
          >
            Confirmar
          </Button>
        </Group>
      </Modal>
    </Page>
  );
}
