import { ThemeIcon, NavLink } from '@mantine/core';
import { IconBuilding } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface CompaniesLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function CompaniesLink({ navigate, themeColor }: CompaniesLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Empresas"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="yellow" variant="light">
          <IconBuilding size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Nova"
        onClick={() => navigate(AppRoutePaths.COMPANY_CREATE)}
      />
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.COMPANY)}
      />
    </NavLink>
  );
}
