import { useEffect } from 'react';
import { appConfig } from '../config';

function sendNotification({
  message,
  user,
  title = 'Notificação',
  to = '/home',
  callback,
}: {
  message: string;
  user: string;
  title?: string;
  to?: string;
  callback?: () => void;
}) {
  const notification = new Notification(title, {
    icon: appConfig.notificationIcon,
    body: `@${user}: \n${message}`,
    requireInteraction: true,
  });
  notification.addEventListener('click', () => {
    window.focus();
    if (callback) {
      callback();
    }
    window.location.replace(to);
    notification.removeEventListener('click', () => {});
    notification.close();
  });
}

export function useNativeNotification() {
  useEffect(() => {
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications!');
    } else if (Notification.permission === 'granted') {
      // sendNotification({
      //   message: 'Você acaba de receber uma notifição',
      //   user: 'Antoniel Amaral',
      // });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          // sendNotification(message, user);
        }
      });
    }
  }, []);

  return {
    sendNotification,
  };
}
