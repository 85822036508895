import { useFetch } from '../../../hooks/use-fetch';
import { Notification } from '../../../models/notification';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseGetNotificationsProps,
  UseSetNotificationsReadedProps,
} from '../../../utils/types/data/hooks/notifications';
import {
  getNotificationsRequest,
  readNotificationsRequest,
} from '../../services/notifications';

export function useGetNotifications() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<Notification[]>();

  const fetch = ({ onSuccess }: UseGetNotificationsProps) =>
    fetchData({
      fetcher: () => getNotificationsRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar notificações.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useSetNotificationsReaded() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseSetNotificationsReadedProps) =>
    fetchData({
      fetcher: () => readNotificationsRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao ler notificações.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}
