import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import {
  AuthLoginResponse,
  loginRequest,
  ResetCodeData,
  resetCodeRequest,
  SetNewPasswordData,
  setNewPasswordRequest,
} from '../data/services/auth';
import {
  errorNotification,
  successNotification,
} from '../providers/mantine-notifications';
import { User, UserRole, UserType } from '../models/user';
import { LocalStorageKeys } from '../utils/enums';
import { rootNavigate } from '../components/CustomRouter';
import { translateServerHttpErrors } from '../utils/helpers';
import { Team } from '../models/team';
import { showTeamRequest } from '../data/services/teams';

interface InitialStateProps {
  loading: boolean;
  signed: boolean;
  user: User | null;
  userTeam?: Team;
}

const initialState: InitialStateProps = {
  signed: false,
  loading: false,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<AuthLoginResponse>) => {
      state.user = action.payload.user;
      state.signed = true;
    },
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    handleLogout: (state) => {
      state.loading = initialState.loading;
      state.signed = initialState.signed;
      state.user = initialState.user;
      state.userTeam = undefined;
    },
    setUserTeam: (state, action: PayloadAction<Team>) => {
      state.userTeam = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
const { endLoading, initLoading } = authActions;
export default authSlice.reducer;

export const loginDispatcher =
  (payload: { email: string; password: string }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(initLoading());
      const response = await loginRequest(payload);
      dispatch(endLoading());
      dispatch(authActions.setLoggedUser(response));
      if (
        response.user.type === UserType.USER &&
        response.user.role !== UserRole.COMPANY
      ) {
        dispatch(getUserTeamDispacher(Number(response.user.team)));
      }
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, response.access_token);
      rootNavigate('/home');
    } catch (error) {
      dispatch(endLoading());
      errorNotification({
        title: 'Erro ao fazer login!',
        message: 'email ou senha incorreto 🔐',
      });
    }
  };

export const logoutDispatcher = () => async (dispatch: Dispatch) => {
  localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
  localStorage.removeItem(LocalStorageKeys.NOTIFICATIONS);
  localStorage.removeItem('redirect_url');
  dispatch(authActions.handleLogout());
  rootNavigate('/');
};

export const requestResetCodeDispatcher =
  (payload: ResetCodeData) => async (dispatch: Dispatch) => {
    try {
      dispatch(initLoading());
      await resetCodeRequest(payload);
      dispatch(endLoading());
      successNotification({
        title: 'Alteração solicitada',
        message: 'verifique seu e-mail',
      });
    } catch (error) {
      dispatch(endLoading());
    }
  };

export const newPasswordDispatcher =
  (code: string, payload: SetNewPasswordData) => async (dispatch: Dispatch) => {
    try {
      dispatch(initLoading());
      await setNewPasswordRequest(code, payload);
      dispatch(endLoading());
      successNotification({
        title: 'Senha alterada!',
        message: 'agora faça o login.',
      });
      rootNavigate('/');
    } catch (error: any) {
      dispatch(endLoading());
      errorNotification({
        title: 'Erro ao alterar senha!',
        message: translateServerHttpErrors(error, ''),
      });
    }
  };

export const getUserTeamDispacher =
  (teamId: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(initLoading());
      const response = await showTeamRequest(teamId);
      dispatch(authActions.setUserTeam(response));
      dispatch(endLoading());
    } catch (error: any) {
      dispatch(endLoading());
      errorNotification({
        title: 'Erro ao buscar time de usuário!',
        message: translateServerHttpErrors(error, ''),
      });
    }
  };

export const resetLoadingDispatcher = () => async (dispatch: Dispatch) => {
  dispatch(endLoading());
};
