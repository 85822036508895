import { Purchase } from '../../models/purchase';
import { api } from '../../providers/base-api';

export type CreatePurchaseData = {
  name: string;
  description?: string;
  boughtAt: Date;
  price: string;
  receipt?: number;
};

export async function getPurchasesRequest(): Promise<Purchase[]> {
  const response = await api.get<Purchase[]>(`purchases`);

  return response.data;
}

export async function showPurchaseRequest(id: number): Promise<Purchase> {
  const response = await api.get<Purchase>(`purchases/${id}`);

  return response.data;
}

export async function createPurchaseRequest(
  data: CreatePurchaseData,
): Promise<Purchase> {
  const response = await api.post<Purchase>(`purchases`, data);

  return response.data;
}

export async function updatePurchaseRequest(
  id: number,
  data: CreatePurchaseData,
): Promise<Purchase> {
  const response = await api.put<Purchase>(`purchases/${id}`, data);

  return response.data;
}

export async function removePurchaseRequest(id: number): Promise<void> {
  const response = await api.delete<void>(`purchases/${id}`);

  return response.data;
}
