import { Card, Flex, Text } from '@mantine/core';

interface GoalCardProps {
  title: string;
  currentGoal: string;
  icon: JSX.Element;
}

export function GoalCard({ currentGoal, title, icon }: GoalCardProps) {
  return (
    <Card shadow="sm" radius="md" withBorder>
      <Card.Section p="xs">
        <Flex justify="space-between" align="center">
          <Text size="xs" color="gray">
            {title}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Flex mt="xs">
            <Text size="lg">{currentGoal}</Text>
          </Flex>
          <Flex>{icon}</Flex>
        </Flex>
      </Card.Section>
    </Card>
  );
}
