import { Checkbox, Group, NumberInput, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';
interface GravacaoLaserFormItemProps {
  form: UseFormReturnType<any>;
}

export function GravacaoLaserFormItem({ form }: GravacaoLaserFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        isRotated: false,
        hasManyData: false,
        personalizationSides: [],
        hasSample: false,
      },
    });
  }, []);

  return (
    <>
      <Checkbox
        label="É rotacionado ?"
        value="rotacionado"
        color="green"
        name="isRotated"
        mb={16}
        {...form.getInputProps('details.isRotated')}
      />
      <Checkbox
        label="Tem dados variados ?"
        value="dados variados"
        color="green"
        name="hasManyData"
        mb={16}
        {...form.getInputProps('details.hasManyData')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
      <Text size="sm">Tamanho da gravação</Text>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
    </>
  );
}
