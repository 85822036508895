import { Card, Flex, Text } from '@mantine/core';
import { IconFileX } from '@tabler/icons';

interface EmptyProps {
  text: string;
}

export function Empty({ text }: EmptyProps) {
  return (
    <Card withBorder>
      <Flex direction="column" align="center" justify="center">
        <IconFileX size={40} />
        <Text>{text}</Text>
      </Flex>
    </Card>
  );
}
