/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Select,
  Stepper,
  Switch,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';

import { CustomDateTimePicker } from '../../components/CustoDateTimePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { CustomMultipleFileInput } from '../../components/CustomMutipleFileInput';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { uploadMultipleFilesRequest } from '../../data/services/files';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { createOrderMaterialRequest } from '../../data/services/order-material';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums';
import { textCapitalize } from '../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';

type FormType = {
  company: number | null;
  product: string;
  productColor: string;
  productMaterial: string;
  quantity: number | null;
  quantityVolume: number | null;
  note?: string;
  receivedAt: Date;
  hasNf: boolean;
  nfNumber: string;
  files: File[];
};

export function OrderMaterialCreate() {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);

  const form = useForm<FormType>({
    initialValues: {
      company: null,
      product: '',
      productColor: '',
      productMaterial: '',
      quantity: null,
      quantityVolume: null,
      receivedAt: new Date(),
      note: '',
      hasNf: false,
      nfNumber: '',
      files: [],
    },
  });

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function handleSubmit(values: typeof form.values) {
    try {
      setLoading(true);
      let fileIds: number[] = [];
      if (values.files.length) {
        fileIds = await handleUpdateFiles(values.files);
      }

      await createOrderMaterialRequest({
        ...values,
        company: { id: Number(values.company) },
        quantity: Number(values.quantity),
        quantityVolume: Number(values.quantityVolume),
        files: fileIds.length ? fileIds : undefined,
      });
      setLoading(false);
      successNotification({
        title: 'Nova AR adicionada!',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.ORDER_MATERIALS);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao criar AR!',
        message: 'ops! tente novamente',
      });
    }
  }

  async function handleUpdateFiles(files: File[]): Promise<number[]> {
    const fileIds = await uploadMultipleFilesRequest({ files });

    return fileIds;
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Page>
      <Container miw={{ xs: 320, sm: 320, md: 500, lg: 500, xl: 500 }}>
        <CustomLoader loading={loading} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Criar AR
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="AR" description="dados do material">
              <Grid columns={2} maw={500}>
                <Grid.Col span={2}>
                  <Select
                    searchable
                    required
                    withAsterisk
                    name="company"
                    label="Cliente"
                    placeholder="selecione o cliente"
                    data={companyList.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    mb={16}
                    {...form.getInputProps('company')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    required
                    withAsterisk
                    label="Produto"
                    placeholder="nome do produto"
                    mb={16}
                    type="text"
                    name="product"
                    {...form.getInputProps('product')}
                    onChange={(event) =>
                      form.setFieldValue(
                        'product',
                        textCapitalize(event.target.value),
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    required
                    withAsterisk
                    label="Material"
                    placeholder="material do produto"
                    mb={16}
                    type="text"
                    name="productMaterial"
                    {...form.getInputProps('productMaterial')}
                    onChange={(event) =>
                      form.setFieldValue(
                        'productMaterial',
                        textCapitalize(event.target.value),
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    required
                    withAsterisk
                    label="Cor"
                    placeholder="cor do material"
                    mb={16}
                    type="text"
                    name="productColor"
                    {...form.getInputProps('productColor')}
                    onChange={(event) =>
                      form.setFieldValue(
                        'productColor',
                        textCapitalize(event.target.value),
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    required
                    withAsterisk
                    label="Quantidade"
                    placeholder="quantidade"
                    mb={16}
                    type="number"
                    name="quantity"
                    {...form.getInputProps('quantity')}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <TextInput
                    required
                    withAsterisk
                    label="Volume"
                    placeholder="volumes"
                    mb={16}
                    type="number"
                    name="quantityVolume"
                    {...form.getInputProps('quantityVolume')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <Switch
                    mt={16}
                    checked={form.values.hasNf}
                    color="teal"
                    size="sm"
                    label="Possui Nota Fiscal?"
                    thumbIcon={
                      form.values.hasNf ? (
                        <IconCheck size="0.8rem" color="teal" stroke={3} />
                      ) : (
                        <IconX size="0.8rem" color="red" stroke={3} />
                      )
                    }
                    {...form.getInputProps('hasNf')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    disabled={!form.values.hasNf}
                    withAsterisk
                    label="Número da NF"
                    placeholder="número"
                    mb={16}
                    type="text"
                    name="nfNumber"
                    {...form.getInputProps('nfNumber')}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CustomDateTimePicker
                    placeholder="selecione uma data"
                    label="Data de recebimento"
                    lang="br"
                    name="receivedAt"
                    mb={16}
                    mr={8}
                    {...form.getInputProps('receivedAt')}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <Textarea
                    maxLength={255}
                    mt={16}
                    label="Observações"
                    placeholder="escreva aqui qualquer observação que queira fazer"
                    name="note"
                    {...form.getInputProps('note')}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CustomMultipleFileInput
                    name="files"
                    accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.ps,.bin"
                    label="Arquivos"
                    placeholder="selecione arquivo por vez"
                    formRef={form}
                  />
                </Grid.Col>
              </Grid>
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
