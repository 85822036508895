import {
  ActionIcon,
  Card,
  Indicator,
  Popover,
  ScrollArea,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { IconBell } from '@tabler/icons';
import { Notification } from '../../models/notification';
import { User } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { notificationsRedirectTo } from '../../utils/notifications-redirect-to';
import { rootNavigate } from '../CustomRouter';

interface NotificationIndicatorProps {
  notifications: Notification[];
  user: User;
  onRead: (id: number) => void;
}

interface NotificationItemProps {
  item: Notification;
}

export function NotificationIndicator({
  notifications,
  onRead,
  user,
}: NotificationIndicatorProps) {
  function handleRedirect(notification: Notification) {
    onRead(notification.id);
    rootNavigate(notificationsRedirectTo(notification, user.role));
  }

  function NotificationItem({ item }: NotificationItemProps) {
    return (
      <Card p={8} bg="gray.1" mb={16} withBorder>
        <UnstyledButton w="100%" h="100%" onClick={() => handleRedirect(item)}>
          <Text fw="bold" size="xs">
            {item.title}
          </Text>
          <Text size="xs">{item.message}</Text>
          <Text size="xs">
            {formatLocale(item.createdAt, 'DD MMM YY HH:mm')}
          </Text>
        </UnstyledButton>
      </Card>
    );
  }

  return (
    <Popover width={350} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Indicator
          disabled={!notifications.length}
          inline
          color="orange"
          label={notifications.length}
          size={16}
        >
          <ActionIcon
            color="gray"
            radius={15}
            variant="light"
            onClick={() => {}}
            size={30}
          >
            <IconBell size={20} />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm">suas ultimas notificações:</Text>
        <ScrollArea style={{ height: 240 }}>
          {!!notifications.length &&
            notifications?.map((notyItem) => (
              <NotificationItem key={notyItem.id} item={notyItem} />
            ))}
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  );
}
