import { ActivityComplexity, ActivityStatus } from '../../models/activity';

export const activityStatusHumanized = {
  [ActivityStatus.DONE]: 'feito',
  [ActivityStatus.ON_PROGRESS]: 'em progresso',
  [ActivityStatus.PENDING]: 'pendente',
  [ActivityStatus.CHANGE]: 'alteração',
  [ActivityStatus.APPROVED]: 'aprovado',
  [ActivityStatus.PAUSED]: 'pausado',
};

export const activityStatusColor = {
  [ActivityStatus.DONE]: 'green',
  [ActivityStatus.ON_PROGRESS]: 'orange',
  [ActivityStatus.PENDING]: 'gray',
  [ActivityStatus.CHANGE]: 'yellow',
  [ActivityStatus.APPROVED]: 'blue',
  [ActivityStatus.PAUSED]: 'gray',
};

export const activityComplexityHumanized = {
  [ActivityComplexity.DEFAULT]: 'indefinida',
  [ActivityComplexity.HIGH]: 'alta',
  [ActivityComplexity.LOW]: 'baixa',
};

export const activityComplexityColor = {
  [ActivityComplexity.DEFAULT]: 'gray',
  [ActivityComplexity.HIGH]: 'red',
  [ActivityComplexity.LOW]: 'blue',
};

export const activityStatusList = [
  { label: 'aprovado', value: ActivityStatus.APPROVED },
  { label: 'alteração', value: ActivityStatus.CHANGE },
  { label: 'em progresso', value: ActivityStatus.ON_PROGRESS },
  { label: 'feito', value: ActivityStatus.DONE },
  { label: 'pausado', value: ActivityStatus.PAUSED },
  { label: 'pendente', value: ActivityStatus.PENDING },
];
