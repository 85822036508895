import { ResetPassword } from '../../models/reset-password';
import { User } from '../../models/user';
import { api } from '../../providers/base-api';

export type AuthLoginResponse = {
  user: User;
  access_token: string;
};

export type ResetCodeData = {
  email: string;
};

export type SetNewPasswordData = {
  password: string;
  passwordConfirmation: string;
};

export type ResetPasswordResponse = {
  message: string;
  statusCode: number;
};

export type AuthLoginParams = { email: string; password: string };

export async function loginRequest(data: {
  email: string;
  password: string;
}): Promise<AuthLoginResponse> {
  const response = await api.post<AuthLoginResponse>('auth/login', data);

  return response.data;
}

export async function authCheckRequest(): Promise<{ message: string }> {
  const response = await api.get<{ message: string }>('auth/check');

  return response.data;
}

export async function resetCodeRequest(data: ResetCodeData): Promise<void> {
  await api.post<void>('reset-passwords', data);
}

export async function validateResetCodeRequest(
  code: string,
): Promise<ResetPassword> {
  const response = await api.get<ResetPassword>(`reset-passwords/${code}`);

  return response.data;
}

export async function setNewPasswordRequest(
  code: string,
  data: SetNewPasswordData,
): Promise<ResetPasswordResponse> {
  const response = await api.put<ResetPasswordResponse>(
    `reset-passwords/${code}`,
    data,
  );

  return response.data;
}
