import {
  Badge,
  Button,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  NumberInput,
  Paper,
  Radio,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCircleCheck,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconEye,
  IconSearch,
  IconStatusChange,
  IconTicketOff,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { TicketDetails } from '../../components/TicketDetails';
import { useCreateTicket } from '../../data/hooks/tickets';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { paginatedOrdersRequest } from '../../data/services/orders';
import { Order } from '../../models/order';
import { Ticket, TicketStatus } from '../../models/ticket';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  finishTicketDispatcher,
  getTicketsPaginatedDispatcher,
  updateTicketDispatcher,
  updateTicketStatusDispatcher,
} from '../../store/ticket';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusTicket,
  statusTicketList,
  ticketStatusColor,
  ticketStatusHumanized,
} from '../../utils/constants';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import { UserRole } from '../../models/user';

export type FormFilterType = {
  page: number;
  limit: number;
  os?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  finishedAt?: string | null;
  status?: string | null;
  assignedUser?: string | null;
  code?: string | null;
  company?: string | null;
};

export type TicketListPageModalVisible =
  | 'create-ticket'
  | 'change-status'
  | 'finish-ticket'
  | 'change-ticket'
  | 'show-ticket'
  | null;

export function SacList() {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<any>();
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [pageModalVisible, setPageModalVisible] =
    useState<TicketListPageModalVisible>(null);
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.ticket,
  );
  const [pageLoading, setPageLoading] = useState(false);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const { fetch: createTicketFetcher, loading: createTicketLoad } =
    useCreateTicket();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      os: '',
      createdBy: '',
      createdAt: undefined,
      finishedAt: undefined,
      status: null,
      code: '',
      assignedUser: '',
      company: '',
    },
  });

  const newTicket = useForm({
    initialValues: {
      assignedUser: '',
      process: 'none',
      cleanProcess: '',
      buyDescription: '',
      buyQuantity: 0,
      obs: '',
    },
  });

  const orderFilterForm = useForm({
    initialValues: {
      os: '',
      company: '',
    },
  });

  const formStatusChange = useForm({
    initialValues: {
      status: '',
    },
  });

  const formChangeTicket = useForm({
    initialValues: {
      assignedUser: '',
      obs: '',
    },
  });

  function handleSubmit() {
    if (selectedOrder) {
      if (newTicket.values.process === 'none') {
        errorNotification({
          title: 'Selecione um processo.',
          message: 'campo de processo obrigatório',
        });

        return;
      }
      createTicketFetcher({
        data: {
          ...newTicket.values,
          needBuy: newTicket.values.process === 'buy',
          needClean: newTicket.values.process === 'clean',
          buyDescription:
            newTicket.values.process === 'clean'
              ? ''
              : newTicket.values.buyDescription,
          buyQuantity:
            newTicket.values.process === 'clean'
              ? 0
              : newTicket.values.buyQuantity,
          cleanProcess:
            newTicket.values.process === 'buy'
              ? ''
              : newTicket.values.cleanProcess,
        },
        orderId: selectedOrder.id,
        onSuccess: () => {
          successNotification({
            title: 'Novo ticket criado!',
            message: 'tudo certo.',
          });
          newTicket.reset();
          setSelectedOrder(undefined);
          currentPage.current = 1;
          getTicketsPaginated();
          setPageModalVisible(null);
        },
      });
    }
  }

  function handleListTickets() {
    currentPage.current = 1;
    getTicketsPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getTicketsPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getTicketsPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getTicketsPaginated(true);
  }

  function getTicketsPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getTicketsPaginatedDispatcher({
          page: 1,
          limit: 10,
          company: user?.role === UserRole.COMPANY ? user.id : undefined,
        }),
      );
    } else {
      dispatch(
        getTicketsPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
          company: user?.role === UserRole.COMPANY ? user.id : undefined,
        }),
      );
    }
  }

  function handleFilterOrders() {
    getOrdersPaginated();
    setSelectedOrder(undefined);
  }

  async function getOrdersPaginated() {
    const validatedFilter: any = {};

    Object.entries(orderFilterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setPageLoading(true);
      const result = await paginatedOrdersRequest({
        page: 1,
        limit: 10,
        ...validatedFilter,
        company: user?.role === UserRole.COMPANY ? user.id : undefined,
      });
      setOrderList(result.items);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  function handleSelectTicket(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('show-ticket');
  }

  function handleChangeTicket(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('change-ticket');
  }

  function handleSubmitChangeStatus(values: typeof formStatusChange.values) {
    if (!selectedTicket) return;

    if (
      values.status &&
      statusTicket[values.status as TicketStatus] >
        statusTicket[selectedTicket.status]
    ) {
      dispatch(
        updateTicketStatusDispatcher(selectedTicket.id, {
          status: values.status as TicketStatus,
        }),
      );
      formStatusChange.reset();
      setPageModalVisible(null);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'você nao pode alterar para um status anterior ao atual',
      });
    }
  }

  function handleChangeTicketStatus(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('change-status');
  }

  function handleFinishTicket(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('finish-ticket');
  }

  async function finishTicket() {
    if (!selectedTicket) return;

    if (!selectedTicket.finishedAt) {
      dispatch(finishTicketDispatcher(selectedTicket.id));
      setPageModalVisible(null);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'este ticket já está finalizado!',
      });
    }
  }

  function abortFinishTicket() {
    setPageModalVisible(null);
    setSelectedTicket(undefined);
  }

  async function changeTicket() {
    if (!selectedTicket) return;

    if (!selectedTicket.finishedAt) {
      dispatch(
        updateTicketDispatcher(selectedTicket.id, {
          assignedUser: formChangeTicket.values.assignedUser,
          obs: formChangeTicket.values.obs,
        }),
      );
      setPageModalVisible(null);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'este ticket já está finalizado!',
      });
    }
  }

  useEffect(() => {
    getTicketsPaginated();
    getCompanies();
  }, []);

  return (
    <Page title="SAC [Andamento]">
      <CustomLoader loading={loading || pageLoading || createTicketLoad} />
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => setPageModalVisible('create-ticket')}
          >
            Novo Ticket (SAC)
          </Button>
        </Flex>
        <form onSubmit={formFilter.onSubmit(() => handleListTickets())}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="Nº Ticket"
                placeholder="número do Ticket"
                mb={16}
                mr={8}
                type="text"
                name="code_ticket"
                {...formFilter.getInputProps('code')}
              />
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...formFilter.getInputProps('os')}
              />
              <TextInput
                label="Responsável"
                placeholder="digite o nome do responsável"
                mb={16}
                mr={8}
                type="text"
                name="assignedUser"
                {...formFilter.getInputProps('assignedUser')}
              />
              <TextInput
                label="Criado por"
                placeholder="digite o nome"
                mb={16}
                mr={8}
                type="text"
                name="createdBy"
                {...formFilter.getInputProps('createdBy')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Criado em"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('createdAt')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Finalizado em"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('finishedAt')}
              />
              <Select
                name="status"
                label="Status"
                placeholder="selecione o status do pedido"
                data={statusTicketList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('status')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClearFilter}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>
        <DataTable
          minHeight={200}
          noRecordsText="Sem tickets"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          height={390}
          page={currentPage.current}
          totalRecords={meta?.totalItems}
          onPageChange={handlePaginate}
          recordsPerPageLabel="Itens por página"
          recordsPerPage={meta?.itemsPerPage ?? 10}
          recordsPerPageOptions={[10, 50, 100, 200]}
          onRecordsPerPageChange={(recordRange: number) =>
            handleChangePageLimit(recordRange)
          }
          records={items}
          columns={[
            {
              accessor: 'code',
              title: 'Código',
            },
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={ticketStatusColor[status]} mb={6}>
                  {ticketStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'needClean',
              title: 'Limpeza',
              render: ({ needClean }) =>
                needClean ? (
                  <Badge color="green" mb={6}>
                    Sim
                  </Badge>
                ) : (
                  <Badge color="red" mb={6}>
                    Não
                  </Badge>
                ),
            },
            {
              accessor: 'needBuy',
              title: 'Compra',
              render: ({ needBuy }) =>
                needBuy ? (
                  <Badge color="green" mb={6}>
                    Sim
                  </Badge>
                ) : (
                  <Badge color="red" mb={6}>
                    Não
                  </Badge>
                ),
            },
            {
              accessor: 'obs',
              title: 'Obs',
              width: 410,
            },
            {
              accessor: 'assignedUser',
              title: 'Responsável',
            },
            {
              accessor: 'createdBy',
              title: 'Criado por',
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'finishedAt',
              title: 'Finalizado em',
              render: ({ finishedAt }) =>
                finishedAt && formatLocale(finishedAt, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'menu',
              title: '',
              render: (ticket) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => handleSelectTicket(ticket)}
                      icon={<IconEye size={14} />}
                    >
                      Ver
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !!ticket.finishedAt || user?.role === UserRole.COMPANY
                      }
                      onClick={() => handleChangeTicket(ticket)}
                      icon={<IconEdit size={14} />}
                    >
                      Alterar
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !!ticket.finishedAt || user?.role === UserRole.COMPANY
                      }
                      onClick={() => handleChangeTicketStatus(ticket)}
                      icon={<IconStatusChange size={14} />}
                    >
                      Alterar status
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        !!ticket.finishedAt || user?.role === UserRole.COMPANY
                      }
                      onClick={() => handleFinishTicket(ticket)}
                      icon={<IconTicketOff size={14} />}
                    >
                      Finalizar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        size={700}
        opened={pageModalVisible === 'create-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Criar Ticket (SAC)</Text>}
      >
        <form onSubmit={orderFilterForm.onSubmit(handleFilterOrders)}>
          <Grid gutter="xs" columns={5} mb={8}>
            <Grid.Col span={2}>
              <TextInput
                label="OS"
                placeholder="digite a OS"
                type="text"
                name="os"
                {...orderFilterForm.getInputProps('os')}
              />
            </Grid.Col>
            {user?.role !== UserRole.COMPANY ? (
              <Grid.Col span={2}>
                <Select
                  clearable
                  searchable
                  name="company"
                  label="Cliente"
                  placeholder="selecione o cliente"
                  data={companyList.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  {...orderFilterForm.getInputProps('company')}
                />
              </Grid.Col>
            ) : (
              ''
            )}
            <Grid.Col span={1}>
              <Button color="blue" mt={24} type="submit">
                <IconSearch size={20} />
              </Button>
            </Grid.Col>
          </Grid>
        </form>
        <DataTable
          mb={16}
          height={320}
          noRecordsText="Sem tickets"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          onRowClick={(order) => setSelectedOrder(order)}
          records={orderList}
          columns={[
            {
              accessor: 'selected',
              title: '',
              render: ({ id }) =>
                selectedOrder?.id === id ? (
                  <IconCircleCheck color="green" size="28" />
                ) : null,
            },
            {
              accessor: 'os',
              title: 'OS',
              width: 110,
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
              width: 90,
            },
            {
              accessor: 'internalNumber',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Quantidade',
              width: 70,
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY HH:mm'),
              width: 110,
            },
          ]}
        />
        {selectedOrder ? (
          <form onSubmit={newTicket.onSubmit(() => handleSubmit())}>
            <Grid gutter="xs" columns={4}>
              <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4} mb={5}>
                <TextInput
                  w={200}
                  description="quem será o responsável"
                  label="Nome"
                  placeholder="digite o nome"
                  type="text"
                  name="assignedUser"
                  {...newTicket.getInputProps('assignedUser')}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                <Textarea
                  w={300}
                  description="Observações do ticket"
                  label="Obs"
                  type="text"
                  name="obs"
                  {...newTicket.getInputProps('obs')}
                />
              </Grid.Col>
              <Grid.Col>
                <Radio.Group
                  name="process"
                  label="Processo"
                  description="nos diga oque será feito"
                  withAsterisk
                  required
                  {...newTicket.getInputProps('process')}
                >
                  <Radio value="clean" label="conseguimos limpar as peças?" />
                  <Radio value="buy" label="precisará comprar peças?" />
                </Radio.Group>
              </Grid.Col>
              <Grid.Col>
                {newTicket.values.process === 'clean' ? (
                  <>
                    <Textarea
                      withAsterisk
                      label="Processo de limpeza"
                      placeholder="Oque foi feito para limpar a peça?"
                      type="text"
                      name="cleanProcess"
                      maxLength={255}
                      required
                      {...newTicket.getInputProps('cleanProcess')}
                    />
                  </>
                ) : newTicket.values.process === 'buy' ? (
                  <>
                    <TextInput
                      withAsterisk
                      label="Descrição de compra"
                      placeholder="valor da peça e total"
                      type="text"
                      name="buyDescription"
                      maxLength={255}
                      mb={5}
                      required
                      {...newTicket.getInputProps('buyDescription')}
                    />
                    <NumberInput
                      required
                      name="buyQuantity"
                      withAsterisk
                      label="Quantidade da compra"
                      placeholder="digite a quantidade"
                      min={0}
                      {...newTicket.getInputProps('buyQuantity')}
                    />
                  </>
                ) : (
                  ''
                )}
              </Grid.Col>
            </Grid>
            <Group position="right" mt="xl">
              <Button color="ltpBlue.9" type="submit">
                Criar
              </Button>
            </Group>
          </form>
        ) : (
          ''
        )}
      </Modal>
      <Modal
        size={400}
        opened={pageModalVisible === 'change-status'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Alterar status</Text>}
      >
        <form
          onSubmit={formStatusChange.onSubmit((values) =>
            handleSubmitChangeStatus(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                withAsterisk
                name="status"
                label="Status"
                placeholder="selecione o novo status do ticket"
                data={statusTicketList.slice(0, 3)}
                mb={16}
                {...formStatusChange.getInputProps('status')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={400}
        opened={pageModalVisible === 'finish-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Finalizar Ticket (SAC)</Text>}
      >
        <Text>Deseja finalizar este ticket "{selectedTicket?.code}" ?</Text>
        <Group mt={16}>
          <Button onClick={finishTicket} color="green" type="submit">
            Sim
          </Button>
          <Button onClick={abortFinishTicket} color="red" type="submit">
            Não
          </Button>
        </Group>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'change-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Alterar Ticket (SAC)</Text>}
      >
        <form onSubmit={formChangeTicket.onSubmit(() => changeTicket())}>
          <Group grow mb={10}>
            <TextInput
              w={200}
              description="quem será o responsável"
              label="Nome"
              placeholder="digite o nome"
              type="text"
              name="assignedUser"
              defaultValue={selectedTicket?.assignedUser}
              onChange={(e) =>
                formChangeTicket.setFieldValue('assignedUser', e.target.value)
              }
            />
          </Group>
          <Group grow>
            <Textarea
              w={200}
              description="Observações do ticket"
              label="Obs"
              name="obs"
              defaultValue={selectedTicket?.obs}
              onChange={(e) =>
                formChangeTicket.setFieldValue('obs', e.target.value)
              }
            />
          </Group>

          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Alterar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={700}
        opened={pageModalVisible === 'show-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Ticket (SAC)</Text>}
      >
        <ScrollArea h={600}>
          {selectedTicket && <TicketDetails ticketId={selectedTicket?.id} />}
        </ScrollArea>
      </Modal>
    </Page>
  );
}
