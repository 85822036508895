import {
  Badge,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Radio,
  Select,
  Stepper,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconArrowLeft, IconRefreshDot } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { CustomDateTimePicker } from '../../../components/CustoDateTimePicker';
import { CustomMultipleFileInput } from '../../../components/CustomMutipleFileInput';
import { rootNavigateGoBack } from '../../../components/CustomRouter';
import { getFilterCompaniesListRequest } from '../../../data/services/filters';
import { Option } from '../../../models/option';
import { OrderStatus } from '../../../models/order';
import { OrderFile } from '../../../models/order-file';
import { statusOrder } from '../../../utils/constants';
import { orderPaymentTypeList } from '../../../utils/constants/order';
import {
  formatBRL,
  realToUSCash,
  textCapitalize,
} from '../../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';
import { FileManager } from '../subcomponents/file-manager';

interface VendorEditOrderMobileProps {
  orderStatus: OrderStatus;
  navigation: NavigateFunction;
  form: UseFormReturnType<any>;
  handleSubmit: (values: any) => void;
  handleSelectService: (id: string) => void;
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  services: {
    label: string;
    value: string;
  }[];
  files: OrderFile[];
  selectedService: string;
  servicerFormRender: any;
  canGoToNextStep: boolean;
  handleRemoveFile(id: number): void;
  locationOptions: Option[] | undefined;
  isRework: boolean | undefined;
  isSample: boolean | undefined;
}

export function AdminEditOrderMobile({
  form,
  handleSubmit,
  currentStep,
  services,
  handleSelectService,
  selectedService,
  servicerFormRender,
  files,
  handleRemoveFile,
  locationOptions,
  isRework,
  isSample,
  orderStatus,
}: VendorEditOrderMobileProps) {
  const [companyList, setCompanyList] =
    useState<GetFilterCompaniesListResponse[]>();
  const companyOptions = companyList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));
  const price = Number(realToUSCash(String(form.values.price))) || 0;
  const samplePrice =
    Number(realToUSCash(String(form.values.samplePrice))) || 0;
  const photolitoPrice =
    Number(realToUSCash(String(form.values.pholitoPrice))) || 0;
  const manipulationPrice =
    Number(realToUSCash(String(form.values.manipulationPrice))) || 0;
  const vectorizationPrice =
    Number(realToUSCash(String(form.values.vectorizationPrice))) || 0;
  const clichePrice =
    Number(realToUSCash(String(form.values.clichePrice))) || 0;
  const quantity = Number(form.values.quantity);
  const unitPrice = Math.fround(price / quantity);
  const orderUnitPrice = formatBRL(unitPrice.toFixed(2));
  const orderTotalPrice = formatBRL(
    (
      price +
      samplePrice +
      photolitoPrice +
      manipulationPrice +
      vectorizationPrice +
      clichePrice
    ).toFixed(2),
  );

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest({});
    setCompanyList(response);
  }

  const disableEdit =
    statusOrder[orderStatus] >= statusOrder[OrderStatus.FINISHED];

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Container>
      <Grid gutter="xs" columns={5}>
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center"></Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex align="center" justify="end"></Flex>
        </Grid.Col>
      </Grid>
      <Title size={40} fw="bolder" color="orange" order={1} align="center">
        Editar Pedido
      </Title>
      {isRework && (
        <Center>
          <Badge
            leftSection={<IconRefreshDot size={24} />}
            p={16}
            color="grape"
            variant="light"
          >
            Retrabalho
          </Badge>
        </Center>
      )}
      {isSample && (
        <Center>
          <Badge
            leftSection={<IconRefreshDot size={24} />}
            p={16}
            color="grape"
            variant="light"
          >
            Amostra
          </Badge>
        </Center>
      )}
      <Divider m={16} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stepper
          color="orange"
          orientation="horizontal"
          iconSize={37}
          active={currentStep}
        >
          <Stepper.Step label="Pedido" description="dados do pedido">
            <TextInput
              label="Identificação interna"
              placeholder="código customizavel para identificar o pedido"
              mb={16}
              type="text"
              name="internalNumber"
              value={form.values.internalNumber}
              maxLength={255}
              {...form.getInputProps('internalNumber')}
            />
            <Select
              searchable
              required
              withAsterisk
              name="company"
              label="Cliente"
              placeholder="selecione o cliente do pedido"
              data={companyOptions ?? []}
              mb={16}
              {...form.getInputProps('company')}
            />
            <TextInput
              disabled
              label="OS"
              placeholder="os do pedido"
              mb={16}
              type="text"
              name="os"
              maxLength={255}
              {...form.getInputProps('os')}
            />
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Produto"
                placeholder="descreva o produto ex: Caneta, Caneca"
                mb={16}
                type="text"
                name="product"
                value={form.values.product}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'product',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <NumberInput
                required
                name="quantity"
                withAsterisk
                label="Quantidade"
                placeholder="digite a quantidade"
                mb={16}
                min={0}
                {...form.getInputProps('quantity')}
              />
            </Group>
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Cor"
                placeholder="cor do produto"
                mb={16}
                type="text"
                name="productColor"
                value={form.values.productColor}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'productColor',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <TextInput
                required
                withAsterisk
                label="Material"
                placeholder="descreva se ex: plástico, metal e etc..."
                mb={16}
                type="text"
                name="productMaterial"
                value={form.values.productMaterial}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'productMaterial',
                    textCapitalize(event.target.value),
                  )
                }
              />
            </Group>
            <Group grow>
              <TextInput
                disabled={disableEdit}
                label="Preço Clichê"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="clichePrice"
                value={formatBRL(form.values.clichePrice ?? '')}
                onChange={(e) =>
                  form.setValues({ clichePrice: formatBRL(e.target.value) })
                }
              />
              <TextInput
                disabled={disableEdit}
                label="Preço Fotolito"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="pholitoPrice"
                value={formatBRL(form.values.pholitoPrice ?? '')}
                onChange={(e) =>
                  form.setValues({ pholitoPrice: formatBRL(e.target.value) })
                }
              />
            </Group>
            <Group grow>
              <TextInput
                disabled={disableEdit}
                label="Preço Manuseio"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="manipulationPrice"
                value={formatBRL(form.values.manipulationPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    manipulationPrice: formatBRL(e.target.value),
                  })
                }
              />
              <TextInput
                disabled={disableEdit}
                label="Preço Vetorização"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="vectorizationPrice"
                value={formatBRL(form.values.vectorizationPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    vectorizationPrice: formatBRL(e.target.value),
                  })
                }
              />
            </Group>
            <Group grow>
              <TextInput
                disabled={disableEdit}
                label="Preço Amostra"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="samplePrice"
                value={formatBRL(form.values.samplePrice ?? '')}
                onChange={(e) =>
                  form.setValues({ samplePrice: formatBRL(e.target.value) })
                }
              />
              <TextInput
                disabled={disableEdit}
                label="Preço (total gravação)"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="price"
                value={formatBRL(form.values.price ?? '')}
                onChange={(e) =>
                  form.setFieldValue('price', formatBRL(e.target.value))
                }
              />
            </Group>
            <Group grow>
              <div>
                <Text>Preço unitário: </Text>
                <Text color="gray">{orderUnitPrice}</Text>
              </div>
              <div>
                <Text>Preço final: </Text>
                <Text color="gray">{orderTotalPrice}</Text>
              </div>
            </Group>
            <Group grow>
              <Select
                required
                name="paymentType"
                withAsterisk
                label="Forma de pagamento"
                placeholder="selecione uma forma de pagamento"
                data={orderPaymentTypeList}
                mb={16}
                {...form.getInputProps('paymentType')}
              />
            </Group>
            <TextInput
              label="Número do JOB"
              placeholder="ex: 1/3"
              mb={16}
              type="text"
              name="job"
              maxLength={255}
              {...form.getInputProps('job')}
            />
            <Group grow>
              <Textarea
                maxLength={255}
                mb={16}
                label="Observações Fiscais"
                placeholder="escreva aqui qualquer observação fiscal que queira fazer sobre o pedido"
                name="financialNote"
                {...form.getInputProps('financialNote')}
              />
            </Group>
            <Select
              required
              name="service"
              withAsterisk
              searchable
              label="Serviço"
              placeholder="selecione um serviço"
              data={services}
              mt={16}
              mb={24}
              value={form.values.service}
              onChange={handleSelectService}
            />
            {selectedService !== '' &&
              servicerFormRender?.[selectedService as any] &&
              servicerFormRender[selectedService as any]()}
            <TextInput
              withAsterisk
              label="Nome do logotipo"
              placeholder="texto da gravação"
              mb={16}
              type="text"
              name="engravingText"
              maxLength={255}
              {...form.getInputProps('engravingText')}
            />
            <Text size={14}>Possui Correio</Text>
            <Text size={12} color="gray">
              este pedido será enviado por correio?
            </Text>
            <Group mb={16}>
              <Button
                color={form.values.needsPostal === 'true' ? 'green' : 'gray'}
                onClick={() => form.setFieldValue('needsPostal', 'true')}
              >
                Sim
              </Button>
              <Button
                color={form.values.needsPostal === 'false' ? 'red' : 'gray'}
                onClick={() => form.setFieldValue('needsPostal', 'false')}
              >
                Não
              </Button>
            </Group>
            <TextInput
              label="Fornecedor"
              placeholder="nome do fornecedor"
              mb={16}
              type="text"
              name="supplier"
              value={form.values.supplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'supplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            <TextInput
              label="Código do produto"
              placeholder="código do produto em parceiros"
              mb={16}
              type="text"
              name="productCode"
              value={form.values.productCodeSupplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'productCodeSupplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            {form.values.finishVendorForecast && (
              <CustomDateTimePicker
                key="date-picker-finishForecast"
                label="Prazo de finalização"
                date={form.values.finishVendorForecast}
                onChange={(dateTime) =>
                  form.setFieldValue('finishVendorForecast', dateTime)
                }
              />
            )}
            <Radio.Group
              name="orderAccess"
              label="Entrega do material"
              description="nos diga como vamos receber o material"
              withAsterisk
              {...form.getInputProps('orderAccess')}
            >
              <Radio value="delivered" label="será entregue na empresa" />
              <Radio value="collected" label="será retirado pela empresa" />
            </Radio.Group>
            {form.values.orderAccess === 'collected' ? (
              <>
                <Group grow>
                  <TextInput
                    disabled
                    withAsterisk
                    label="Local da retirada"
                    placeholder="endereço completo"
                    mb={16}
                    type="text"
                    name="pickUpLocation"
                    maxLength={255}
                    {...form.getInputProps('pickUpLocation')}
                  />
                  <TextInput
                    disabled
                    withAsterisk
                    label="Resposável pela retirada"
                    placeholder="com quem falar na retirada"
                    mb={16}
                    type="text"
                    name="pickUpName"
                    maxLength={255}
                    {...form.getInputProps('pickUpName')}
                  />
                </Group>
                <TextInput
                  disabled
                  withAsterisk
                  label="Codigo para identificação no fornecedor"
                  placeholder="necessário para retirada de pedidos na SPOT,ASIA"
                  mb={16}
                  type="text"
                  name="pickUpNumber"
                  maxLength={255}
                  {...form.getInputProps('pickUpNumber')}
                />
                <CustomDateTimePicker
                  key="date-picker-dispatchForecast"
                  label="Previsão de retirada do material"
                  date={form.values.pickUpForecast}
                  onChange={(dateTime) =>
                    form.setValues({ pickUpForecast: dateTime })
                  }
                />
              </>
            ) : (
              <>
                <TextInput
                  withAsterisk
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  maxLength={255}
                  {...form.getInputProps('carrierName')}
                />
                <CustomDateTimePicker
                  key="date-picker-receiptForecast"
                  label="Previsão de entrega do material"
                  date={form.values.receiptForecast}
                  onChange={(dateTime) =>
                    form.setValues({ receiptForecast: dateTime })
                  }
                />
              </>
            )}
            <Select
              required
              name="dispatchLocation"
              withAsterisk
              label="Unidade para retirada"
              placeholder="selecione um local"
              data={locationOptions?.map((item) => ({
                label: item.name,
                value: item.value,
              }))}
              mt={16}
              mb={24}
              {...form.getInputProps('dispatchLocation')}
            />
            <Textarea
              maxLength={255}
              mb={16}
              label="Observações"
              placeholder="escreva aqui qualquer observação que queira fazer sobre seu pedido"
              name="note"
              {...form.getInputProps('note')}
            />
            <Text>Arquivos</Text>
            <Group maw={440}>
              <FileManager files={files} handleRemoveFile={handleRemoveFile} />
            </Group>
            <CustomMultipleFileInput
              required
              withAsterisk
              name="newFiles"
              inputName="newFiles"
              accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
              label="Arquivos"
              placeholder="selecione todos os arquivos de uma vez"
              formRef={form}
            />
          </Stepper.Step>
        </Stepper>
        <Group position="right" mt="xl">
          <Button color="ltpBlue.9" type="submit">
            Salvar
          </Button>
        </Group>
      </form>
    </Container>
  );
}
