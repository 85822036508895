import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { rootNavigate } from '../components/CustomRouter';
import {
  createCompanyRequest,
  updateCompanyRequest,
} from '../data/services/companies';
import { Company } from '../models/company';
import { errorNotification } from '../providers/mantine-notifications';
import { AppRoutePaths } from '../utils/enums';
import {
  CreateCompanyRequestData,
  UpdateCompanyRequestData,
} from '../utils/types/data/services/companies';

interface InitialStateProps {
  loading: boolean;
  items: Company[];
}

const initialState: InitialStateProps = {
  loading: false,
  items: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    setAllCompanies: (state, action: PayloadAction<Company[]>) => {
      state.items = action.payload;
    },
  },
});

export const companiesActions = companiesSlice.actions;
export default companiesSlice.reducer;

export const createCompanyDispatcher =
  (payload: CreateCompanyRequestData) => async (dispatch: Dispatch) => {
    try {
      dispatch(companiesActions.initLoading());
      await createCompanyRequest(payload);
      dispatch(companiesActions.endLoading());
      rootNavigate(AppRoutePaths.COMPANY);
    } catch (error) {
      dispatch(companiesActions.endLoading());
      errorNotification({
        title: 'Erro ao criar empresa!',
        message: 'tente novamente.',
      });
    }
  };

export const updateCompanyDispatcher =
  (id: number, payload: UpdateCompanyRequestData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(companiesActions.initLoading());
      await updateCompanyRequest(id, payload);
      dispatch(companiesActions.endLoading());
      rootNavigate(AppRoutePaths.COMPANY);
    } catch (error) {
      dispatch(companiesActions.endLoading());
      errorNotification({
        title: 'Erro ao atualizar empresa!',
        message: 'tente novamente.',
      });
    }
  };
