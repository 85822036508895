import { useFetch } from '../../../hooks/use-fetch';
import { SaleGoal } from '../../../models/sale-goal';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateSaleGoal,
  UseGetSaleGoalsProps,
  UseRemoveSaleGoal,
} from '../../../utils/types/data/hooks/sale-goals';
import {
  createSaleGoal,
  getSaleGoals,
  removeSaleGoal,
} from '../../services/sale-goals';

export function useCreateSaleGoal() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<SaleGoal>();

  const fetch = ({ teamId, data, onSuccess }: UseCreateSaleGoal) =>
    fetchData({
      fetcher: () => createSaleGoal(teamId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar meta.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetSaleGoals() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<SaleGoal[]>();

  const fetch = ({ query, onSuccess }: UseGetSaleGoalsProps) =>
    fetchData({
      fetcher: () => getSaleGoals(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar metas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRemoveSaleGoal() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseRemoveSaleGoal) =>
    fetchData({
      fetcher: () => removeSaleGoal(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover meta.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}
