/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, ScrollArea, Text, ThemeIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconRefreshDot, IconTestPipe2 } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';

import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigate } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';
import { Page } from '../../components/Page';
import { Order } from '../../models/order';
import { UserRole, UserType } from '../../models/user';
import { RootState } from '../../providers/store';
import { getOrdersLatePaginatedDispatcher } from '../../store/orders';
import { AdminHomeWeb } from './admin/web';
import { VendorHomeWeb } from './vendor/web';

export type FormFilterType = {
  company?: number | undefined;
  limit: number;
  page: number;
  services?: string | null;
};

export function OrderLateList() {
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.orders,
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const currentPageLimit = useRef(10);
  const currentPage = useRef(1);
  const dispatch = useDispatch<any>();

  const [orderViewModalVisible, setOrderViewModalVisible] = useState(false);

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      services: undefined,
      page: 1,
      company: undefined,
    },
  });

  function handleSubmit() {
    currentPage.current = 1;
    getOrderPaginated();
  }

  function handleClearFilter() {
    Object.entries(formFilter.values).forEach(([key]) => {
      if (key !== 'page' && key !== 'limit') {
        formFilter.setFieldValue(key, '');
      }
    });
    currentPage.current = 1;
    currentPageLimit.current = 10;
    getOrderPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getOrderPaginated();
  }

  async function getOrderPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getOrdersLatePaginatedDispatcher({
          page: 1,
          limit: 10,
        }),
      );
    } else {
      dispatch(
        getOrdersLatePaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
        }),
      );
    }
  }

  useEffect(() => {
    getOrderPaginated();
  }, []);

  return (
    <Page title="Pedidos [Pendência Financeira]">
      <CustomLoader loading={loading} />
      {user?.role === UserRole.VENDOR && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
        />
      )}
      {user?.role === UserRole.FINANCIAL && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
        />
      )}
      {user?.type === UserType.MASTER && (
        <AdminHomeWeb
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
        />
      )}
      <Modal
        opened={orderViewModalVisible}
        onClose={() => setOrderViewModalVisible(false)}
        title={
          <Text fw="bold">
            Detalhes do Pedido
            {selectedOrder?.rework && user?.role !== UserRole.COMPANY ? (
              <ThemeIcon ml={10} color="grape" variant="light">
                <IconRefreshDot size={24} />
              </ThemeIcon>
            ) : (
              ''
            )}
            {selectedOrder?.isSample && (
              <ThemeIcon ml={10} color="green" variant="light">
                <IconTestPipe2 size={24} />
              </ThemeIcon>
            )}
          </Text>
        }
        size="lg"
      >
        <ScrollArea h={600}>
          {selectedOrder && (
            <OrderDetails
              sections={[
                'basic',
                'prices',
                'service-details',
                'historic',
                'dates',
                'payment',
                'order-receipt-files',
              ]}
              orderId={selectedOrder?.id}
            />
          )}
        </ScrollArea>
      </Modal>
    </Page>
  );
}
