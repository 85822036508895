import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  ArtFinalListExportableRequestQuery,
  InvoiceOrderListExportableRequestQuery,
  OrderListExportableRequestQuery,
  OrderPaymentReportListExportableRequestQuery,
} from '../../utils/types/data/services/exportables';

export async function orderListExportableRequest(
  query: OrderListExportableRequestQuery,
): Promise<AxiosResponse<Blob>> {
  const response = await api.get<Blob>(`exportables/order-list`, {
    params: query,
    responseType: 'blob',
  });

  return response;
}

export async function invoiceOrderListExportableRequest(
  query: InvoiceOrderListExportableRequestQuery,
): Promise<AxiosResponse<Blob>> {
  const response = await api.get<Blob>(`exportables/invoice-order`, {
    params: query,
    responseType: 'blob',
  });

  return response;
}

export async function orderPaymentExportableRequest(
  query: OrderPaymentReportListExportableRequestQuery,
): Promise<AxiosResponse<Blob>> {
  const response = await api.get<Blob>(`exportables/payment-report`, {
    params: query,
    responseType: 'blob',
  });

  return response;
}

export async function artFinalListExportableRequest(
  query: ArtFinalListExportableRequestQuery,
): Promise<AxiosResponse<Blob>> {
  const response = await api.get<Blob>(`exportables/art-final`, {
    params: query,
    responseType: 'blob',
  });

  return response;
}
