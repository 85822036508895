import {
  Badge,
  Button,
  Paper,
  Flex,
  Select,
  TextInput,
  Menu,
  Indicator,
  Group,
  Checkbox,
  Tooltip,
  ThemeIcon,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconDotsVertical,
  IconFile,
  IconFileUpload,
  IconRefreshDot,
  IconTestPipe2,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { FormFilterType } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { getFilterCompaniesListRequest } from '../../../data/services/filters';
import { Order, Order as OrderModel } from '../../../models/order';
import { LayoutStatus } from '../../../models/order-layout';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusList,
} from '../../../utils/constants';
import { PageMeta } from '../../../utils/types';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';

interface FinalArtHomeWebProps {
  items: OrderModel[];
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  formDispatchChange: UseFormReturnType<any>;
  handleClear: () => void;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderFilesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderLayoutModalVisible: React.Dispatch<
    React.SetStateAction<'send' | null>
  >;
}

export function FinalArtHomeWeb({
  items,
  formFilter,
  currentPage,
  handleClear,
  handleSubmit,
  handlePaginate,
  meta,
  setOrderFilesModalVisible,
  setSelectedOrder,
  setOrderLayoutModalVisible,
}: FinalArtHomeWebProps) {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  function handleViewOrderFiles(order: Order) {
    setSelectedOrder(order);
    setOrderFilesModalVisible(true);
  }

  function handleSendOrderLayout(order: Order) {
    setSelectedOrder(order);
    setOrderLayoutModalVisible('send');
  }

  if (!companyList) return null;

  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex>
            <TextInput
              label="Identificador"
              placeholder="código interno do pedido"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <Select
              searchable
              name="company"
              label="Clientes"
              placeholder="selecione o cliente"
              defaultValue={
                formFilter.values.company &&
                companyList[Number(formFilter.values.company)]?.id
              }
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <Select
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              defaultValue={formFilter.values.status}
              onChange={(values) => {
                formFilter.setFieldValue('status', values?.toString());
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de Retirada"
              lang="br"
              name="receiptForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.receiptForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.receiptForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'receiptForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('receiptForecast', '');
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Prev. de Finalização"
              name="finishForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.finishForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.finishForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'finishForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('finishForecast', '');
              }}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="dark"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="dark" type="submit">
              Filtrar
            </Button>
            <Checkbox
              mt={30}
              label="Mostrar retrabalhos"
              checked={formFilter.values.showRework}
              {...formFilter.getInputProps('showRework')}
            />
          </Group>
        </Paper>
      </form>
      <DataTable
        height="75vh"
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={10}
        onPageChange={handlePaginate}
        records={items}
        rowStyle={(record) =>
          record.rework
            ? { backgroundColor: 'rgba(255, 17, 0, 0.027)' }
            : record.isSample
            ? { backgroundColor: 'rgba(0, 255, 0, 0.027)' }
            : {}
        }
        columns={[
          {
            accessor: 'types',
            title: '',
            render: ({ rework, isSample }) =>
              rework ? (
                <Tooltip color="grape" label="retrabalho">
                  <ThemeIcon color="grape" variant="light">
                    <IconRefreshDot size={24} />
                  </ThemeIcon>
                </Tooltip>
              ) : isSample ? (
                <Tooltip color="green" label="amostra">
                  <ThemeIcon color="green" variant="light">
                    <IconTestPipe2 size={24} />
                  </ThemeIcon>
                </Tooltip>
              ) : (
                ''
              ),
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'company.team.name',
            title: 'Time',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'fileLink',
            title: 'Arquivos',
            render: (order) => (
              <Indicator
                disabled={
                  !(
                    order.layout?.status === LayoutStatus.REPOROVED ||
                    order.layout?.status === LayoutStatus.ON_PROGRESS
                  )
                }
                inline
                color="orange"
              >
                <Button
                  color="blue"
                  variant="subtle"
                  onClick={() => handleViewOrderFiles(order)}
                  w={40}
                  p={0}
                >
                  <IconFile />
                </Button>
              </Indicator>
            ),
            width: 110,
          },
          {
            accessor: 'carrierName',
            title: 'Portador',
          },
          {
            accessor: '',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    disabled={!!orderItem.layout}
                    onClick={() => handleSendOrderLayout(orderItem)}
                    icon={<IconFileUpload size={14} />}
                  >
                    Enviar layout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
