import { useFetch } from '../../../hooks/use-fetch';
import { Postal } from '../../../models/postal';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseCreatePostalsProps,
  UseGetPostalOrdersProps,
  UseListPostalsProps,
  UseSetPostalInvoicedProps,
  UseShowPostalProps,
  UseUpdatePostalsProps,
} from '../../../utils/types/data/hooks/postals';
import {
  GetPostalOrdersResponse,
  ListPostalsResponse,
  OrderPostal,
} from '../../../utils/types/data/services/postals';
import {
  createPostalRequest,
  getPostalOrdersRequest,
  getPostalsByOrderRequest,
  listPostalsRequest,
  setPostalInvoicedRequest,
  showPostalRequest,
  updatePostalRequest,
} from '../../services/postals';

export function useCreatePostal() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseCreatePostalsProps) =>
    fetchData({
      fetcher: () => createPostalRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(
            error,
            'Erro ao criar saida de correio.',
          ),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPostalOrders() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetPostalOrdersResponse[]>();

  const fetch = ({ postalId }: UseGetPostalOrdersProps) =>
    fetchData({
      fetcher: () => getPostalOrdersRequest(postalId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar saida de correio.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useShowPostal() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Postal>();

  const fetch = ({ id }: UseShowPostalProps) =>
    fetchData({
      fetcher: () => showPostalRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar saida de correio.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPostalsByOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderPostal[]>();

  const fetch = (orderIds: number[]) =>
    fetchData({
      fetcher: () => getPostalsByOrderRequest(orderIds),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar saida de correio.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useListPostals() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<ListPostalsResponse>>();

  const fetch = ({ query }: UseListPostalsProps) =>
    fetchData({
      fetcher: () => listPostalsRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar saidas de correio.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdatePostal() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseUpdatePostalsProps) =>
    fetchData({
      fetcher: () => updatePostalRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(
            error,
            'Erro ao atualizar saida de correio.',
          ),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetPostalInvoiced() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseSetPostalInvoicedProps) =>
    fetchData({
      fetcher: () => setPostalInvoicedRequest(id),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao faturar correio.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
