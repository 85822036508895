import axios from 'axios';
import { FilePreview } from '../../../components/FilePreview';
import { OrderFile } from '../../../models/order-file';

export interface FileManagerProps {
  files: OrderFile[];
  handleRemoveFile(id: number): void;
}

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function FileManager({ files, handleRemoveFile }: FileManagerProps) {
  return (
    <>
      {files.map((fileItem) => (
        // <Card p={8} withBorder key={index}>
        //   <Image
        //     height={100}
        //     src={fileItem.file.url}
        //     alt="file preview"
        //     withPlaceholder
        //   />
        //   <Flex align="center">
        //     <IconFile size={14} style={{ marginRight: 5 }} />
        //     <Button
        //       variant="subtle"
        //       onClick={() =>
        //         downloadFile(fileItem.file.url, fileItem.file.name)
        //       }
        //     >
        //       <Text
        //         style={{
        //           whiteSpace: 'nowrap',
        //           textOverflow: 'ellipsis',
        //           overflow: 'hidden',
        //           maxWidth: 100,
        //           display: 'inline-block',
        //         }}
        //       >
        //         {fileItem.file.name}
        //       </Text>
        //       <Text>{fileItem.file.ext}</Text>
        //     </Button>
        //   </Flex>
        // </Card>
        <FilePreview
          key={fileItem.file.id}
          file={{
            id: fileItem.file.id,
            url: fileItem.file.url,
            type: '',
            ext: fileItem.file.name.split('.')[1],
            name: fileItem.file.name.split('.')[0],
          }}
          onDelete={() => handleRemoveFile(fileItem.file.id)}
          onDownload={(url) => downloadFile(url, fileItem.file.name)}
        />
      ))}
    </>
  );
}
