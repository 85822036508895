import { AxiosResponse } from 'axios';
import { Order } from '../../models/order';
import { OrderDetail } from '../../models/order-detail';
import { api } from '../../providers/base-api';
import {
  ChangeOrderPricesRequestData,
  CreateOrderData,
  CreateOrderDetailsData,
  CreateReworkOrder,
  GetOrderGoalMetricsParams,
  GetOrderPcpRequestQuery,
  GetOrderPcpResponse,
  GetOrdersToDispatchResponse,
  OrderMetricGoals,
  OrderProceedWithProblem,
  OrdersLatePaginatedParams,
  OrdersPaginatedParams,
  OrdersPaginatedResponse,
  ReleaseToProductionRequestData,
  SetOrderChangeDispatchData,
  SetOrderDispatchmentData,
  SetOrderFinancialNoteRequestData,
  SetOrderFinishedData,
  SetOrderFinishPackProblem,
  SetOrderPausedData,
  SetOrderReceivementData,
  SetOrderToDispatchResquestData,
  UpdateOrderData,
  UpdateOrderDatesData,
  UpdateOrderStatusData,
} from '../../utils/types/data/services/orders';

export async function paginatedOrdersRequest(
  params: OrdersPaginatedParams,
): Promise<OrdersPaginatedResponse> {
  const response = await api.get<OrdersPaginatedResponse>('orders', { params });

  return response.data;
}

export async function paginatedHistoricOrdersRequest(
  params: OrdersPaginatedParams,
): Promise<OrdersPaginatedResponse> {
  const response = await api.get<OrdersPaginatedResponse>(
    'orders/get-historic',
    { params },
  );

  return response.data;
}

export async function createOrdersRequest(
  data: CreateOrderData,
): Promise<Order> {
  const response = await api.post<Order>('orders', data);

  return response.data;
}

export async function createReworkOrderRequest(
  data: CreateReworkOrder,
): Promise<Order> {
  const response = await api.post<Order>('orders/rework', data);

  return response.data;
}

export async function createOrderDetailsRequest(
  orderId: number,
  data: CreateOrderDetailsData,
): Promise<OrderDetail> {
  const response = await api.post<OrderDetail>(
    `orders/${orderId}/detail`,
    data,
  );

  return response.data;
}

export async function getOrderRequest(id: number): Promise<Order> {
  const response = await api.get<Order>(`orders/${id}`);

  return response.data;
}

export async function getOrderDetailsRequest(id: number): Promise<OrderDetail> {
  const response = await api.get<OrderDetail>(`orders/${id}/detail`);

  return response.data;
}

export async function updateOrderDatesRequest(
  id: number,
  data: UpdateOrderDatesData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/dates`, data);

  return response.data;
}

export async function updateOrderStatusRequest(
  id: number,
  data: UpdateOrderStatusData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/status`, data);

  return response.data;
}

export async function changeOrderStatusRequest(
  id: number,
  data: UpdateOrderStatusData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`orders/${id}/status`, data);

  return response;
}

export async function setOrderReceivementRequest(
  id: number,
  data: SetOrderReceivementData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/receivement`, data);

  return response.data;
}

export async function setOrderDispatchmentRequest(
  id: number,
  data: SetOrderDispatchmentData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/dispatchment`, data);

  return response.data;
}

export async function updateOrderRequest(
  id: number,
  data: UpdateOrderData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}`, data);

  return response.data;
}

export async function updateOrderDetailsRequest(
  id: number,
  data: CreateOrderDetailsData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/detail`, data);

  return response.data;
}

export async function setOrderFinishedRequest(
  id: number,
  data: SetOrderFinishedData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/finish`, data);

  return response.data;
}

export async function setOrderFinishedReceptionRequest(
  id: number,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/set-finished`);

  return response.data;
}

export async function setOrderDispatchDataRequest(
  id: number,
  data: SetOrderChangeDispatchData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/change-dispatch`, data);

  return response.data;
}

export async function setOrderProductionFinishedRequest(
  id: number,
): Promise<AxiosResponse<Order>> {
  const response = await api.put<Order>(`orders/production-finish/${id}`);

  return response;
}

export async function setOrderPackingFinishedRequest(
  id: number,
): Promise<Order> {
  const response = await api.put<Order>(`orders/packing-finish/${id}`);

  return response.data;
}

export async function setOrderPausedRequest(
  id: number,
  data: SetOrderPausedData,
): Promise<Order> {
  const response = await api.put<Order>(`orders/${id}/pause`, data);

  return response.data;
}

export async function setOrderFinancialNote(
  id: number,
  data: SetOrderFinancialNoteRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`orders/set-financial-note/${id}`, data);

  return response;
}

export async function changeOrderPricesRequest(
  id: number,
  data: ChangeOrderPricesRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`orders/set-prices/${id}`, data);

  return response;
}

export async function getOrderPCPRequest(
  query: GetOrderPcpRequestQuery,
): Promise<AxiosResponse<GetOrderPcpResponse>> {
  const response = await api.get<GetOrderPcpResponse>(`orders/get-pcp`, {
    params: query,
  });

  return response;
}

export async function getOrdersToDispatchRequest(
  hash: string,
): Promise<AxiosResponse<GetOrdersToDispatchResponse[]>> {
  const response = await api.get<GetOrdersToDispatchResponse[]>(
    `orders-dispatch/${hash}`,
  );

  return response;
}

export async function setOrdersToDispatchRequest(
  data: SetOrderToDispatchResquestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`orders-dispatch`, data);

  return response;
}

export async function paginatedOrdersLateRequest(
  params: OrdersLatePaginatedParams,
): Promise<OrdersPaginatedResponse> {
  const response = await api.get<OrdersPaginatedResponse>('orders/get-late', {
    params,
  });

  return response.data;
}

export async function setOrderPackingFinishedProblemRequest(
  id: number,
  data: SetOrderFinishPackProblem,
): Promise<Order> {
  const response = await api.put<Order>(`orders/set-pack-problem/${id}`, data);

  return response.data;
}

export async function orderProceedWithProblemRequest(
  id: number,
  data: OrderProceedWithProblem,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `orders/proceed-with-problem/${id}`,
    data,
  );

  return response;
}

export async function getOrdersGoalMetrics(
  query: GetOrderGoalMetricsParams,
): Promise<AxiosResponse<OrderMetricGoals>> {
  const response = await api.get<OrderMetricGoals>(`orders/goal-metrics`, {
    params: query,
  });

  return response;
}

export async function releaseProductionRequest(
  id: number,
  data: ReleaseToProductionRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`orders/${id}/release-production`, data);

  return response;
}
