import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import {
  createTicketResquest,
  finishTicketRequest,
  paginatedTicketsRequest,
  updateTicketResquest,
  updateTicketStatusRequest,
} from '../data/services/ticket';
import { Ticket } from '../models/ticket';
import {
  errorNotification,
  successNotification,
} from '../providers/mantine-notifications';
import { PageMeta } from '../utils/types';
import {
  CreateTicketRequestData,
  TicketPaginatedParams,
  TicketPaginatedResponse,
  UpdateTicketRequestData,
  UpdateTicketStatusRequestData,
} from '../utils/types/data/services/tickets';

interface InitialStateProps {
  items: Ticket[];
  meta: PageMeta | null;
  loading: boolean;
}

const initialState: InitialStateProps = {
  items: [],
  meta: null,
  loading: false,
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, action: PayloadAction<TicketPaginatedResponse>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    updateTicket: (state, action: PayloadAction<Ticket>) => {
      const updatedTicket = action.payload;
      const ticketList = state.items;
      const ticketIndex = ticketList.findIndex(
        (item) => item.id === updatedTicket.id,
      );

      if (ticketIndex !== -1) {
        ticketList[ticketIndex] = {
          ...updatedTicket,
        };

        state.items = ticketList;
      }
    },
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
  },
});

export const ticketsActions = ticketsSlice.actions;
export default ticketsSlice.reducer;

export const getTicketsPaginatedDispatcher =
  (params: TicketPaginatedParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(ticketsActions.initLoading());
      const response = await paginatedTicketsRequest(params);
      dispatch(ticketsActions.endLoading());
      dispatch(ticketsActions.setTickets(response));
    } catch (error) {
      dispatch(ticketsActions.endLoading());
      errorNotification({
        title: 'Erro ao buscar tickets!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const createTicketDispatcher =
  (orderId: number, payload: CreateTicketRequestData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ticketsActions.initLoading());
      await createTicketResquest(orderId, payload);
      dispatch(ticketsActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'novo ticket criado ✅',
      });
    } catch (error) {
      dispatch(ticketsActions.endLoading());
      errorNotification({
        title: 'Erro ao criar ticket!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const updateTicketStatusDispatcher =
  (id: number, payload: UpdateTicketStatusRequestData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ticketsActions.initLoading());
      const ticket = await updateTicketStatusRequest(id, payload);
      dispatch(ticketsActions.updateTicket(ticket));
      dispatch(ticketsActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'novo status atualizado ✅',
      });
    } catch (error) {
      dispatch(ticketsActions.endLoading());
      errorNotification({
        title: 'Erro ao alterar status do ticket!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const finishTicketDispatcher =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(ticketsActions.initLoading());
      const ticket = await finishTicketRequest(id);
      dispatch(ticketsActions.updateTicket(ticket));
      dispatch(ticketsActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'ticket finalizado ✅',
      });
    } catch (error) {
      dispatch(ticketsActions.endLoading());
      errorNotification({
        title: 'Erro ao finalizar ticket!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const updateTicketDispatcher =
  (id: number, payload: UpdateTicketRequestData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ticketsActions.initLoading());
      const ticket = await updateTicketResquest(id, payload);
      dispatch(ticketsActions.updateTicket(ticket));
      dispatch(ticketsActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'ticket alterado ✅',
      });
    } catch (error) {
      dispatch(ticketsActions.endLoading());
      errorNotification({
        title: 'Erro ao alterar ticket!',
        message: 'tente novamente 🤞',
      });
    }
  };
