import { Flex, Grid, Text } from '@mantine/core';

import { OrderDetail } from '../../models/order-detail';

interface CorteLaserDetailsProps {
  orderDetails: OrderDetail;
}

export function CorteLaserDetails({ orderDetails }: CorteLaserDetailsProps) {
  return (
    <>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Altura</Text>
          <Text color="gray">{orderDetails.height} cm</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Largura</Text>
          <Text color="gray">{orderDetails.width} cm</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Espessura do material</Text>
          <Text color="gray">{orderDetails.thickness} mm</Text>
        </Flex>
      </Grid.Col>
    </>
  );
}
