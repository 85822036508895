import { Button, Flex, Group, Menu, Text } from '@mantine/core';
import {
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconTrash,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { useDeleteOptions, useListOptions } from '../../data/hooks/options';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import {
  optionsSubTypesHumanized,
  optionsTypeHumanized,
} from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';
import { successNotification } from '../../providers/mantine-notifications';

export function OptionList() {
  const navigation = useNavigate();

  const {
    fetch: listOptionsFetcher,
    loading: listOptionsLoader,
    reponseData: listOptionsData,
  } = useListOptions();

  const { fetch: deleteOptionFetcher, loading: deleteOptionLoader } =
    useDeleteOptions();

  async function handleListLocationOptions() {
    await listOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.PRODUCTION_MACHINE,
      },
    });
  }

  async function handleDeleteOption(id: number) {
    await deleteOptionFetcher({
      id,
      onSuccess: () => {
        handleListLocationOptions();
        successNotification({
          title: 'Tudo certo!',
          message: 'máquina excluída com sucesso ✅',
        });
      },
    });
  }

  useEffect(() => {
    handleListLocationOptions();
  }, []);

  return (
    <Page title="Opções">
      <CustomLoader loading={listOptionsLoader || deleteOptionLoader} />
      <Flex p={8} direction="column" w="99%">
        <Group mb={16} position="right">
          <Button
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.OPTION_CREATE)}
          >
            Novo
          </Button>
        </Group>
        <DataTable
          w="100%"
          noRecordsText="Sem opções"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={listOptionsData}
          columns={[
            {
              accessor: 'name',
              title: 'Nome',
            },
            {
              accessor: 'value',
              title: 'Valor',
            },
            {
              accessor: 'type',
              title: 'Tipo',
              render: ({ type }) => <Text>{optionsTypeHumanized[type]}</Text>,
            },
            {
              accessor: 'subtype',
              title: 'Subtipo',
              render: ({ subtype }) => (
                <Text>{optionsSubTypesHumanized[subtype]}</Text>
              ),
            },
            {
              accessor: 'options-menu',
              title: '',
              render: (option) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      icon={<IconEdit size={14} />}
                      onClick={() => navigation(`/options/edit/${option.id}`)}
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconTrash size={14} />}
                      onClick={() => {
                        handleDeleteOption(option.id);
                      }}
                    >
                      Excluir
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
    </Page>
  );
}
