import { api } from '../../providers/base-api';
import { errorNotification } from '../../providers/mantine-notifications';

export type CepResponse = {
  cep: string;
  state: string;
  city: string;
  address: string;
};

export async function getAddressByCepOnProxy(cep: string) {
  try {
    const response = await api.get<CepResponse>(`/cep/${cep}`);

    return response.data;
  } catch (error) {
    errorNotification({
      title: 'Erro ao buscar CEP.',
      message: 'Ops! algo deu errado, tente novamente mais tarde.',
    });
  }
}
