import {
  Badge,
  Button,
  Card,
  Flex,
  Group,
  Image,
  Paper,
  Text,
} from '@mantine/core';
import { IconFile } from '@tabler/icons';
import axios from 'axios';
import { FileType, OrderFile as OrderFileModel } from '../../models/order-file';
import { OrderLayout } from '../../models/order-layout';
import {
  orderLayoutStatusColor,
  orderLayoutStatusHumanized,
} from '../../utils/constants';
import { orderFileApproveHumanized } from '../../utils/constants/order-file';
import { Empty } from '../Empty';

interface OrderFilesProps {
  files: OrderFileModel[];
  types?: FileType[];
}

interface OrderFileLayoutProps {
  layout: OrderLayout;
}

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

function Layout({ layout }: OrderFileLayoutProps) {
  return (
    <Paper
      w={192}
      style={{
        position: 'relative',
        padding: 16,
      }}
      withBorder
    >
      <Badge
        color={orderLayoutStatusColor[layout.status]}
        style={{
          position: 'absolute',
          zIndex: 100,
          right: -1,
          top: -10,
        }}
      >
        {orderLayoutStatusHumanized[layout.status]}
      </Badge>
      <Image height={100} src={layout.file.url} fit="cover" withPlaceholder />
      <Flex mt={10} align="center">
        <IconFile size={14} style={{ marginRight: 5 }} />
        <Button
          variant="subtle"
          onClick={() => downloadFile(layout.file.url, layout.file.name)}
        >
          <Text
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: 100,
              display: 'inline-block',
            }}
          >
            {layout.file.name}
          </Text>
          <Text>{layout.file.ext}</Text>
        </Button>
      </Flex>
    </Paper>
  );
}

function FileItem({ file }: { file: OrderFileModel }) {
  return (
    <Card w={192} withBorder>
      <Badge>
        {file.financialApprove &&
          orderFileApproveHumanized?.[file.financialApprove]}
      </Badge>
      <Image height={100} src={file.file.url} fit="cover" withPlaceholder />
      <Flex mt={10} align="center">
        <IconFile size={14} style={{ marginRight: 5 }} />
        <Button
          variant="subtle"
          onClick={() => downloadFile(file.file.url, file.file.name)}
        >
          <Text
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: 100,
              display: 'inline-block',
            }}
          >
            {file.file.name}
          </Text>
          <Text>{file.file.ext}</Text>
        </Button>
      </Flex>
    </Card>
  );
}

export function OrderFile({ files, types = [FileType.FILE] }: OrderFilesProps) {
  function renderFiles() {
    return (
      <>
        <Text color="gray.7" mt={8}>
          Arquivos
        </Text>
        <Group mt={8}>
          {files
            .filter((item) => item.type === FileType.FILE)
            .map((file) => (
              <FileItem file={file} key={file.file.id} />
            ))}
        </Group>
      </>
    );
  }

  function renderReceipts() {
    return (
      <>
        <Text color="gray.7" mt={8}>
          Comprovantes
        </Text>
        <Group mt={8}>
          {files
            .filter((item) => item.type === FileType.RECEIPT)
            .map((file) => (
              <FileItem file={file} key={file.file.id} />
            ))}
        </Group>
      </>
    );
  }

  return (
    <>
      {files.length && types?.includes(FileType.FILE) ? renderFiles() : null}
      {files.length && types?.includes(FileType.RECEIPT)
        ? renderReceipts()
        : null}
      {!files.length && <Empty text="Sem arquivos" />}
    </>
  );
}

OrderFile.Layout = Layout;
