import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { getServicesRequest } from '../data/services/services';
import { Service } from '../models/service';
import { errorNotification } from '../providers/mantine-notifications';

interface InitialStateProps {
  items: Service[];
  loading: boolean;
}

const initialState: InitialStateProps = {
  items: [],
  loading: false,
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<Service[]>) => {
      state.items = action.payload;
    },
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
  },
});

export const servicesActions = servicesSlice.actions;
export default servicesSlice.reducer;

export const getServicesDispatcher = () => async (dispatch: Dispatch) => {
  try {
    dispatch(servicesActions.initLoading());
    const response = await getServicesRequest();
    dispatch(servicesActions.endLoading());
    dispatch(servicesActions.setServices(response));
  } catch (error) {
    dispatch(servicesActions.endLoading());
    errorNotification({
      title: 'Erro ao buscar serviços!',
      message: 'tente novamente 🤞',
    });
  }
};
