import { Flex, Grid, Text } from '@mantine/core';

import { OrderDetail } from '../../models/order-detail';
import { booleanToStringConverter } from '../../utils/helpers';

interface EtiquetaResinadaDetailsProps {
  orderDetails: OrderDetail;
}

export function EtiquetaResinadaDetails({
  orderDetails,
}: EtiquetaResinadaDetailsProps) {
  return (
    <>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Altura</Text>
          <Text color="gray">{orderDetails.height}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Largura</Text>
          <Text color="gray">{orderDetails.width}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Tem manuseio de aplicação no produto acima?</Text>
          <Text color="gray">
            {booleanToStringConverter(!!orderDetails.hasManipulation)}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">É adesivo vinil fundo transparente ?</Text>
          <Text color="gray">
            {booleanToStringConverter(!!orderDetails.isTransparent)}
          </Text>
        </Flex>
      </Grid.Col>
    </>
  );
}
