import { Button, DefaultMantineColor, ThemeIcon, Text } from '@mantine/core';

interface NavbarButtonProps {
  icon: React.ReactNode;
  color: DefaultMantineColor;
  text: string;
  textColor: string;
  onClick: () => void;
}

export function NavbarButton({
  icon,
  color,
  text,
  onClick,
  textColor,
}: NavbarButtonProps) {
  return (
    <Button
      onClick={onClick}
      color="dark.4"
      styles={{
        inner: {
          justifyContent: 'start',
        },
        root: {
          '&:hover': {
            backgroundColor: 'rgba(193, 194, 197, 0.30)',
          },
        },
      }}
      leftIcon={
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>
      }
      variant="subtle"
    >
      <Text color={textColor}>{text}</Text>
    </Button>
  );
}
