import { Menu, Button, Flex } from '@mantine/core';
import {
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconTrash,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components/Page';
import {
  getPurchasesRequest,
  removePurchaseRequest,
} from '../../data/services/purchase';
import { Purchase } from '../../models/purchase';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums';
import { formatBRL } from '../../utils/helpers';

export function PurchaseList() {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [purchaseList, setPurchaseList] = useState<Purchase[]>([]);

  async function getPurchases() {
    try {
      setLoading(true);
      const response = await getPurchasesRequest();
      setPurchaseList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscar compras!',
        message: 'tente novamente.',
      });
    }
  }

  async function handleRemove(id: number) {
    try {
      setLoading(true);
      await removePurchaseRequest(id);
      const clonePurchaseList = purchaseList;
      const foundedPurchase = clonePurchaseList.findIndex(
        (item) => item.id === id,
      );
      if (foundedPurchase !== -1) {
        clonePurchaseList.splice(foundedPurchase, 1);
        setPurchaseList(clonePurchaseList);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao remover compra!',
        message: 'tente novamente.',
      });
    }
  }

  useEffect(() => {
    getPurchases();
  }, []);

  return (
    <Page title="Compras">
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="dark"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.PURCHASES_CREATE)}
          >
            Nova
          </Button>
        </Flex>
        <DataTable
          fetching={loading}
          minHeight={500}
          noRecordsText="Sem compras"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={purchaseList}
          columns={[
            {
              accessor: 'name',
              title: 'Nome',
            },
            {
              accessor: 'price',
              title: 'Valor',
              render: ({ price }) => formatBRL(price ?? ''),
            },
            {
              accessor: 'boughtAt',
              title: 'Data da Compra',
              render: ({ boughtAt }) => formatLocale(boughtAt, 'DD/MM/YYYY'),
            },
            {
              accessor: '',
              title: '',
              render: ({ id }) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => navigation(`edit/${id}`)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleRemove(id)}
                      icon={<IconTrash size={14} />}
                    >
                      Apagar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
    </Page>
  );
}
