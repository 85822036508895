import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { rootNavigate } from '../components/CustomRouter';
import {
  createOrdersRequest,
  createReworkOrderRequest,
  getOrderDetailsRequest,
  getOrderRequest,
  paginatedHistoricOrdersRequest,
  paginatedOrdersLateRequest,
  paginatedOrdersRequest,
  setOrderDispatchDataRequest,
  setOrderDispatchmentRequest,
  setOrderFinishedRequest,
  setOrderPausedRequest,
  setOrderReceivementRequest,
  updateOrderDatesRequest,
  updateOrderDetailsRequest,
  updateOrderRequest,
  updateOrderStatusRequest,
} from '../data/services/orders';
import { Order } from '../models/order';
import { OrderDetail } from '../models/order-detail';
import {
  errorNotification,
  successNotification,
} from '../providers/mantine-notifications';
import { translateServerHttpErrors } from '../utils/helpers';
import { PageMeta, PaginatedResponse } from '../utils/types';
import {
  CreateOrderData,
  CreateOrderDetailsData,
  CreateReworkOrder,
  OrdersLatePaginatedParams,
  OrdersPaginatedParams,
  SetOrderChangeDispatchData,
  SetOrderDispatchmentData,
  SetOrderFinishedData,
  SetOrderPausedData,
  SetOrderReceivementData,
  UpdateOrderData,
  UpdateOrderDatesData,
  UpdateOrderStatusData,
} from '../utils/types/data/services/orders';

type SelectedOrder = {
  order: Order | null;
  details: OrderDetail | null;
};
interface InitialStateProps {
  items: Order[];
  historic: Order[];
  selected: SelectedOrder;
  meta: PageMeta | null;
  loading: boolean;
}

const initialState: InitialStateProps = {
  items: [],
  historic: [],
  selected: {
    details: null,
    order: null,
  },
  meta: null,
  loading: false,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<PaginatedResponse<Order>>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    setHistoric: (state, action: PayloadAction<PaginatedResponse<Order>>) => {
      state.historic = action.payload.items;
      state.meta = action.payload.meta;
    },
    updateOrder: (state, action: PayloadAction<Order>) => {
      const updatedOrder = action.payload;
      const orderList = state.items;
      const orderIndex = orderList.findIndex(
        (item) => item.id === updatedOrder.id,
      );

      if (orderIndex !== -1) {
        orderList[orderIndex] = {
          ...updatedOrder,
        };

        state.items = orderList;
      }
    },
    setSelected: (state, action: PayloadAction<SelectedOrder>) => {
      state.selected = action.payload;
    },
    removeSelected: (state) => {
      state.selected = initialState.selected;
    },
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
  },
});

export const ordersActions = ordersSlice.actions;
export default ordersSlice.reducer;

export const getOrdersPaginatedDispatcher =
  (params: OrdersPaginatedParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const response = await paginatedOrdersRequest(params);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.setOrders(response));
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao buscar pedidos!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const getHistoricOrdersPaginatedDispatcher =
  (params: OrdersPaginatedParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const response = await paginatedHistoricOrdersRequest(params);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.setHistoric(response));
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao buscar pedidos!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const createOrderDispatcher =
  (OrderData: CreateOrderData) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      await createOrdersRequest(OrderData);
      dispatch(ordersActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'novo pedido cadastrado ✅',
      });
      rootNavigate('/orders');
    } catch (error: any) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao cadastrar pedido!',
        message: translateServerHttpErrors(error, 'tente novamente 🤞'),
      });
    }
  };

export const createReworkOrderDispatcher =
  (data: CreateReworkOrder) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      await createReworkOrderRequest(data);
      dispatch(ordersActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'retrabalho cadastrado ✅',
      });
      rootNavigate('/orders');
    } catch (error: any) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao cadastrar retrabalho!',
        message: translateServerHttpErrors(error, 'tente novamente 🤞'),
      });
    }
  };

export const updateOrderDatesDispatcher =
  (id: number, payload: UpdateOrderDatesData) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const order = await updateOrderDatesRequest(id, payload);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.updateOrder(order));
      successNotification({
        title: 'Tudo certo!',
        message: 'novo data atualizada ✅',
      });
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao alterar data do pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const updateOrderStatusDispatcher =
  (id: number, payload: UpdateOrderStatusData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const order = await updateOrderStatusRequest(id, payload);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.updateOrder(order));
      successNotification({
        title: 'Tudo certo!',
        message: 'novo status atualizado ✅',
      });
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao alterar status do pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const setOrderReceivementDispatcher =
  (id: number, payload: SetOrderReceivementData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const order = await setOrderReceivementRequest(id, payload);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.updateOrder(order));
      successNotification({
        title: 'Tudo certo!',
        message: 'recebimento registrado ✅',
      });
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao dar recebimento no pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const setOrderDispatchmentDispatcher =
  (id: number, payload: SetOrderDispatchmentData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const order = await setOrderDispatchmentRequest(id, payload);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.updateOrder(order));
      successNotification({
        title: 'Tudo certo!',
        message: 'retirada registrada ✅',
      });
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao dar retirada do pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const updateOrderDispatcher =
  (
    id: number,
    payload: UpdateOrderData,
    payloadDetails: CreateOrderDetailsData,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      await updateOrderRequest(id, payload);
      await updateOrderDetailsRequest(id, payloadDetails);
      dispatch(ordersActions.endLoading());
      successNotification({
        title: 'Tudo certo!',
        message: 'pedido atualizado ✅',
      });
      rootNavigate('/orders');
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao atualizar pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const getOrderDispatcher =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const orderResponse = await getOrderRequest(id);
      const orderDetailResponse = await getOrderDetailsRequest(id);
      dispatch(ordersActions.endLoading());
      dispatch(
        ordersActions.setSelected({
          details: orderDetailResponse,
          order: orderResponse,
        }),
      );
    } catch (error) {
      dispatch(ordersActions.endLoading());
    }
  };

export const setOrderFinishDispatcher =
  (id: number, payload: SetOrderFinishedData) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const updatedOrder = await setOrderFinishedRequest(id, payload);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.updateOrder(updatedOrder));
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao atualizar pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const setOrderPausedDispatcher =
  (id: number, payload: SetOrderPausedData) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const updatedOrder = await setOrderPausedRequest(id, payload);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.updateOrder(updatedOrder));
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao pausar pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const setOrderChangeDispatchDispatcher =
  (id: number, payload: SetOrderChangeDispatchData) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      await setOrderDispatchDataRequest(id, payload);
      dispatch(ordersActions.endLoading());
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao atualizar pedido!',
        message: 'tente novamente 🤞',
      });
    }
  };

export const getOrdersLatePaginatedDispatcher =
  (params: OrdersLatePaginatedParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(ordersActions.initLoading());
      const response = await paginatedOrdersLateRequest(params);
      dispatch(ordersActions.endLoading());
      dispatch(ordersActions.setOrders(response));
    } catch (error) {
      dispatch(ordersActions.endLoading());
      errorNotification({
        title: 'Erro ao buscar pedidos em atraso!',
        message: 'tente novamente 🤞',
      });
    }
  };
