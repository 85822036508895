import { Center, Flex, Title } from '@mantine/core';

interface PageProps {
  children: React.ReactNode;
  title?: string;
}

export function Page({ children, title }: PageProps) {
  return (
    <>
      <Title align="start" ml={16} size={16} mb={0}>
        {title}
      </Title>
      <Center>
        <Flex
          direction="column"
          style={{
            padding: '0.5rem 0',
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
          align="center"
        >
          {children}
        </Flex>
      </Center>
    </>
  );
}
