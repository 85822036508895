import { AxiosResponse } from 'axios';
import {
  Activity,
  ActivityComplexity,
  ActivityStatus,
  ActivityType,
} from '../../models/activity';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  GetActivityReportRequestQuery,
  GetActivityReportResponse,
} from '../../utils/types/data/services/activities';

export type GetActivitiesListParams = {
  page: number;
  limit: number;
  company?: string;
  os?: string;
  target?: number;
  createdAt?: string;
  finishedAt?: string;
  showDone?: boolean;
  team?: string;
  status?: string;
  internalNumber?: string;
};

export type CustomActivity = {
  id: number;
  order: number;
  target: number;
  type: ActivityType;
  status: ActivityStatus;
  complexity: ActivityComplexity;
  isPrioritary: boolean;
  obs: string;
  createdAt: string;
  finishedAt: string;
  os: string;
  internal_number: string;
  product: string;
  orderStatus: string;
  product_color: string;
  product_material: string;
  quantity: number;
  companyName: string;
  targetName: string;
  teamName: string;
};

export type FinishActivityData = {
  complexity: ActivityComplexity;
};

export type CreateActivityData = {
  target: number;
  obs?: string;
  isPrioritary: boolean;
};

export type UpdateActivityData = {
  obs?: string;
  isPrioritary: boolean;
  status?: ActivityStatus;
};

export async function createActivityRequest(
  orderId: number,
  data: CreateActivityData,
): Promise<CustomActivity> {
  const response = await api.post<CustomActivity>(
    `activities/${orderId}`,
    data,
  );

  return response.data;
}

export async function getActivitiesListRequest(
  params: GetActivitiesListParams,
): Promise<AxiosResponse<PaginatedResponse<CustomActivity>>> {
  const response = await api.get<PaginatedResponse<CustomActivity>>(
    'activities',
    { params },
  );

  return response;
}

export async function updateActivityRequest(
  id: number,
  data: UpdateActivityData,
): Promise<AxiosResponse<CustomActivity, any>> {
  const response = await api.put<CustomActivity>(`activities/${id}`, data);

  return response;
}

export async function showActivityRequest(id: number): Promise<Activity> {
  const response = await api.get<Activity>(`activities/${id}`);

  return response.data;
}

export async function finishActivityRequest(
  id: number,
  data: FinishActivityData,
): Promise<Activity> {
  const response = await api.put<Activity>(`activities/finish/${id}`, data);

  return response.data;
}

export async function getActivityReportRequest(
  query: GetActivityReportRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<GetActivityReportResponse>>> {
  const response = await api.get<PaginatedResponse<GetActivityReportResponse>>(
    'activities/report',
    { params: query },
  );

  return response;
}
