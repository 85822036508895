import { NavLink, ThemeIcon } from '@mantine/core';
import { IconReceipt } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface ReceiptLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function ReceiptLink({ navigate, themeColor }: ReceiptLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Recibos"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="red" variant="light">
          <IconReceipt size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.RECEIPT_LIST)}
      />
    </NavLink>
  );
}
