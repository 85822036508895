import { File } from './file';

export enum LayoutStatus {
  ON_PROGRESS = 'on_progress',
  PENDING_VENDOR = 'pending_vendor',
  PENDING_CLIENT = 'pending_client',
  APPROVED = 'approved',
  REPOROVED = 'reproved',
}

export type OrderLayout = {
  order: number;
  file: File;
  artUser: number;
  status: LayoutStatus;
  vendorApproverName: string;
  clientApproverName: string;
};
