import { Button, Card, Flex, ThemeIcon, Text } from '@mantine/core';
import { IconTrash, IconUserCircle } from '@tabler/icons';
import { GetCompanyUserResponseData } from '../../../utils/types/data/services/companies';

interface CompanyUserProps {
  user: GetCompanyUserResponseData;
  onChangeEmailPrivacy: ({
    userId,
    allow,
  }: {
    userId: number;
    allow: boolean;
  }) => void;
  onRemoveUser: ({ userId }: { userId: number }) => void;
}

export function CompanyUser({
  user,
  onChangeEmailPrivacy,
  onRemoveUser,
}: CompanyUserProps) {
  return (
    <Card w={400} mb={16} shadow="sm">
      <Flex align="center">
        <ThemeIcon color="gray" mr={16} size={40} variant="light">
          <IconUserCircle size={40} />
        </ThemeIcon>
        <Flex style={{ flex: 1 }} justify="space-between">
          <Flex direction="column">
            <Text fw="bold">{user.username}</Text>
            <Text>{user.email}</Text>
            <Flex direction="column">
              <Text fw="lighter" size={14}>
                Permitir notificações?
              </Text>
              <Flex>
                <Button
                  onClick={() =>
                    onChangeEmailPrivacy({ allow: true, userId: user.id })
                  }
                  mr={8}
                  color={user.allow_email ? 'green' : 'gray'}
                  p={0}
                  h={24}
                  w={50}
                >
                  Sim
                </Button>
                <Button
                  onClick={() =>
                    onChangeEmailPrivacy({ allow: false, userId: user.id })
                  }
                  color={user.allow_email ? 'gray' : 'red'}
                  p={0}
                  h={24}
                  w={50}
                >
                  Não
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Button
            onClick={() => onRemoveUser({ userId: user.id })}
            color="red.0"
            p={0}
            w={35}
          >
            <IconTrash color="red" />
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
