import {
  Menu,
  Button,
  Flex,
  Grid,
  Modal,
  Paper,
  Select,
  TextInput,
  Text,
  Textarea,
  Switch,
  Group,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCertificate2,
  IconCheck,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { useSetCompanyProtested } from '../../data/hooks/companies';
import { useGetCompanyDocumentsPaginated } from '../../data/hooks/company-documents';
import { getFilterTeamsListRequest } from '../../data/services/filters';
import { TeamType } from '../../models/team';
import { UserRole, UserType } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { RootState } from '../../providers/store';
import { AppRoutePaths } from '../../utils/enums';
import { clearMask, formatCNPJ } from '../../utils/formater';
import { GetCompanyDocumentsPaginatedItem } from '../../utils/types/data/services/company-documents';
import { GetFilterTeamsListResponse } from '../../utils/types/data/services/filters';

const formFilterInitialState = {
  team: '',
  createdAt: undefined,
  razaoSocial: '',
  nomeFantasia: '',
  document: '',
  isProtested: false,
};

export function CompanyList() {
  const navigation = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const [pageModalVisible, setPageModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] =
    useState<GetCompanyDocumentsPaginatedItem>();
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const {
    fetch: setCompanyProtestedFetcher,
    loading: setCompanyProtestedLoading,
  } = useSetCompanyProtested();
  const {
    fetch: getCompaniesFetcher,
    response: getCompaniesData,
    loading: getCompaniesLoader,
  } = useGetCompanyDocumentsPaginated();

  const formFilter = useForm({
    initialValues: formFilterInitialState,
  });

  const setProtestedCompanyForm = useForm({
    initialValues: {
      protested: false,
      description: '',
    },
  });

  function handleClearFilter() {
    currentPage.current = 1;
    formFilter.reset();
    getCompaniesPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getCompaniesPaginated();
  }

  function setCompanyToProtest(company: GetCompanyDocumentsPaginatedItem) {
    setProtestedCompanyForm.setFieldValue('protested', company.is_protested);
    setProtestedCompanyForm.setFieldValue(
      'description',
      company.protest_description ?? '',
    );
    setSelectedCompany(company);
    setPageModalVisible(true);
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getCompaniesPaginated();
  }

  async function getVendorTeams() {
    const response = await getFilterTeamsListRequest({ type: TeamType.VENDOR });
    setTeamList(response);
  }

  async function handleSubmit() {
    currentPage.current = 1;
    getCompaniesPaginated();
  }

  async function getCompaniesPaginated() {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    getCompaniesFetcher({
      query: {
        page: currentPage.current,
        limit: currentPageLimit.current,
        ...validatedValues,
        createdAt: validatedValues?.createdAt
          ? validatedValues.createdAt.join(',')
          : undefined,
        document: validatedValues?.document
          ? clearMask(validatedValues.document)
          : undefined,
      },
    });
  }

  async function handleProtestCompany(id: number) {
    await setCompanyProtestedFetcher({
      id,
      data: {
        protested: setProtestedCompanyForm.values.protested,
        description: setProtestedCompanyForm.values.description,
      },
      onSuccess: () => {
        setSelectedCompany(undefined);
        setProtestedCompanyForm.reset();
        setPageModalVisible(false);
        handleSubmit();
      },
    });
  }

  useEffect(() => {
    getCompaniesPaginated();
    getVendorTeams();
  }, []);

  return (
    <Page title="Empresas">
      <CustomLoader loading={getCompaniesLoader} />
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.COMPANY_CREATE)}
          >
            Nova
          </Button>
        </Flex>
        <form onSubmit={formFilter.onSubmit(handleSubmit)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="Nome fantasia"
                placeholder="digite..."
                mb={16}
                mr={8}
                type="text"
                name="name"
                {...formFilter.getInputProps('nomeFantasia')}
              />
              <TextInput
                label="Razão social"
                placeholder="digite..."
                mb={16}
                mr={8}
                type="text"
                name="name"
                {...formFilter.getInputProps('razaoSocial')}
              />
              <TextInput
                label="CNPJ"
                placeholder="digite..."
                mb={16}
                mr={8}
                type="text"
                name="name"
                {...formFilter.getInputProps('document')}
                onChange={(event) =>
                  formFilter.setFieldValue(
                    'document',
                    formatCNPJ(event.target.value),
                  )
                }
              />
              {user?.role !== UserRole.VENDOR && (
                <Select
                  clearable
                  searchable
                  name="team"
                  label="Time"
                  placeholder="selecione um time"
                  data={teamList?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('team')}
                />
              )}
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Criado em"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('createdAt')}
              />
            </Flex>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Paper>
        </form>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 300]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          recordsPerPage={getCompaniesData?.meta?.itemsPerPage ?? 10}
          totalRecords={getCompaniesData?.meta?.totalItems}
          page={currentPage.current}
          onPageChange={handlePaginate}
          fetching={setCompanyProtestedLoading}
          height="75vh"
          noRecordsText="Sem empresas"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={getCompaniesData?.items}
          columns={[
            {
              accessor: 'name',
              title: 'Nome',
            },
            {
              accessor: 'nomeFantasia',
              title: 'Nome fantasia',
            },
            {
              accessor: 'razaoSocial',
              title: 'Razão social',
            },
            {
              accessor: 'document',
              title: 'CNPJ',
              render: ({ document }) => formatCNPJ(document),
            },
            {
              accessor: 'team_name',
              title: 'Equipe',
            },
            {
              accessor: 'email',
              title: 'Email',
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            },
            {
              accessor: 'created_by',
              title: 'Criado por',
            },
            {
              accessor: 'company-table-menu',
              title: '',
              render: (companyDoc) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => navigation(`edit/${companyDoc.company}`)}
                      icon={<IconEdit size={14} />}
                      disabled={
                        user?.team !== companyDoc.team_id &&
                        user?.type !== UserType.MASTER &&
                        user?.role !== UserRole.FINANCIAL
                      }
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => setCompanyToProtest(companyDoc)}
                      icon={<IconCertificate2 size={14} />}
                    >
                      Protestar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        size={550}
        opened={pageModalVisible}
        onClose={() => setPageModalVisible(false)}
        title="Protestar empresa"
      >
        <Grid gutter="xs" columns={4} mb={8}>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Nome Fantasia
              </Text>
              <Text>{selectedCompany?.nomeFantasia}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Equipe
              </Text>
              <Text>{selectedCompany?.team_name}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={setProtestedCompanyForm.onSubmit(() =>
            handleProtestCompany(Number(selectedCompany?.company)),
          )}
        >
          <Grid gutter="xs" columns={4} mb={8}>
            <Grid.Col span={2}>
              <Switch
                description="ative esta opção para protestar a empresa"
                checked={setProtestedCompanyForm.values.protested}
                color="teal"
                size="sm"
                label="protestado"
                thumbIcon={
                  setProtestedCompanyForm.values.protested ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...setProtestedCompanyForm.getInputProps('protested')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Textarea
                maxLength={255}
                withAsterisk
                required
                label="Motivo"
                placeholder="escreva aqui qualquer observação sobre..."
                name="description"
                {...setProtestedCompanyForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
