import { Flex, Grid, Text } from '@mantine/core';

import { OrderDetail } from '../../models/order-detail';
import { booleanToStringConverter } from '../../utils/helpers';

interface SublimacaoDetailsProps {
  orderDetails: OrderDetail;
}

export function SublimacaoDetails({ orderDetails }: SublimacaoDetailsProps) {
  return (
    <>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Dados variavies</Text>
          <Text color="gray">
            {booleanToStringConverter(!!orderDetails.hasManyData)}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Gravação 360˚</Text>
          <Text color="gray">
            {booleanToStringConverter(!!orderDetails.has360Engraving)}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Local da gravação</Text>
          <Text color="gray">{orderDetails.personalizationSides}</Text>
        </Flex>
      </Grid.Col>
    </>
  );
}
