import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseArtFinalListExportableProps,
  UseInvoiceOrderListExportableProps,
  UseOrderListExportableProps,
  UseOrderPaymentReportListExportableProps,
} from '../../../utils/types/data/hooks/exportables';
import {
  artFinalListExportableRequest,
  invoiceOrderListExportableRequest,
  orderListExportableRequest,
  orderPaymentExportableRequest,
} from '../../services/exportables';

export function useOrderListExportable() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ query, onSuccess }: UseOrderListExportableProps) =>
    fetchData({
      fetcher: () => orderListExportableRequest(query),
      onError: async (error: any) => {
        const convertedError = JSON.parse(await error.response.data.text());
        errorNotification({
          title: 'Erro ao baixar exportavel.',
          message: translateServerHttpErrors(
            { response: { data: convertedError } as any } as any,
            'tente novamente',
          ),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useInvoiceOrderListExportable() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ query, onSuccess }: UseInvoiceOrderListExportableProps) =>
    fetchData({
      fetcher: () => invoiceOrderListExportableRequest(query),
      onError: async (error: any) => {
        const convertedError = JSON.parse(await error.response.data.text());
        errorNotification({
          title: 'Erro ao baixar exportavel.',
          message: translateServerHttpErrors(
            { response: { data: convertedError } as any } as any,
            'tente novamente',
          ),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useOrderPaymentReportListExportable() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<Blob>();

  const fetch = ({
    query,
    onSuccess,
  }: UseOrderPaymentReportListExportableProps) =>
    fetchData({
      fetcher: () => orderPaymentExportableRequest(query),
      onError: async (error: any) => {
        const convertedError = JSON.parse(await error.response.data.text());
        errorNotification({
          title: 'Erro ao baixar exportavel.',
          message: translateServerHttpErrors(
            { response: { data: convertedError } as any } as any,
            'tente novamente',
          ),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useArtFinalListExportable() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ query, onSuccess }: UseArtFinalListExportableProps) =>
    fetchData({
      fetcher: () => artFinalListExportableRequest(query),
      onError: async (error: any) => {
        const convertedError = JSON.parse(await error.response.data.text());
        errorNotification({
          title: 'Erro ao baixar exportavel.',
          message: translateServerHttpErrors(
            { response: { data: convertedError } as any } as any,
            'tente novamente',
          ),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}
