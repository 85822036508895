import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';

export type OrderLayoutVendorApprovalData = {
  isApproved: boolean;
  reason?: string;
};

export async function orderLayoutRequest(orderId: number) {
  const response = await api.post(`order-layouts/${orderId}/request`);

  return response.data;
}

export async function addOrderLayoutRequest(
  orderId: number,
  data: { file: { id: number } },
) {
  const response = await api.post(`order-layouts/${orderId}`, data);

  return response.data;
}

export async function vendorApprovalLayoutRequest(
  orderId: number,
  data: OrderLayoutVendorApprovalData,
) {
  const response = await api.post(
    `order-layouts/${orderId}/vendor-approve`,
    data,
  );

  return response.data;
}

export async function sendClientLayoutRequest(
  orderId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `order-layouts/${orderId}/send-to-client`,
  );

  return response;
}

export async function clientApprovalLayoutRequest(
  orderId: number,
  data: OrderLayoutVendorApprovalData,
) {
  const response = await api.post(
    `order-layouts/${orderId}/client-approve`,
    data,
  );

  return response.data;
}

export async function updateOrderLayoutRequest(
  orderId: number,
  data: { file: { id: number } },
) {
  const response = await api.put(`order-layouts/${orderId}`, data);

  return response.data;
}
