/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Stepper,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useState } from 'react';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigate } from '../../components/CustomRouter';

import { Page } from '../../components/Page';
import { createBillRequest } from '../../data/services/bill';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums';
import { formatBRL, realToUSCash } from '../../utils/helpers';

type FormType = {
  name: string;
  description?: string;
  payDay: string;
  price: string;
  monthRecurrence: string;
};

export function BillCreate() {
  const [loading, setLoading] = useState(false);
  const form = useForm<FormType>({
    initialValues: {
      name: '',
      description: '',
      price: '',
      monthRecurrence: '',
      payDay: '',
    },
  });

  async function handleSubmit(values: typeof form.values) {
    try {
      setLoading(true);
      await createBillRequest({
        ...values,
        price: realToUSCash(values.price),
        monthRecurrence: Number(values.monthRecurrence),
        payDay: Number(values.payDay),
      });
      setLoading(false);
      successNotification({
        title: 'Conta adicionada!',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.BILLS);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao adicionar Conta!',
        message: 'ops! tente novamente',
      });
    }
  }

  return (
    <Page>
      <Container miw={320}>
        <CustomLoader loading={loading} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigate(AppRoutePaths.BILLS)}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Nova Conta
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Conta" description="dados da conta">
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="ex: conta de luz filial "
                mb={16}
                type="text"
                name="name"
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                withAsterisk
                label="Valor"
                placeholder="ex: R$80,40"
                mb={16}
                type="text"
                name="price"
                value={formatBRL(form.values.price ?? '')}
                onChange={(e) =>
                  form.setFieldValue('price', formatBRL(e.target.value))
                }
              />
              <TextInput
                required
                withAsterisk
                label="Recorrencia mensal"
                placeholder="frequência de cobrança da conta"
                mb={16}
                type="number"
                name="monthRecurrence"
                {...form.getInputProps('monthRecurrence')}
              />
              <TextInput
                required
                withAsterisk
                label="Dia da cobrança"
                placeholder="dia de vencimento da conta"
                mb={16}
                type="number"
                name="payDay"
                {...form.getInputProps('payDay')}
              />
              <Textarea
                maxLength={255}
                mt={16}
                label="Observações"
                placeholder="escreva aqui qualquer observação que queira fazer sobre a conta"
                name="description"
                {...form.getInputProps('description')}
              />
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
