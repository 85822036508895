import { User } from './user';

export enum PackingType {
  PACK = 'pack',
  UNPACK = 'unpack',
  PAUSE = 'pause',
}

export type OrderPacking = {
  id: number;
  order: number;
  clockIn: string;
  clockOut: string | null;
  packer: User;
  type: PackingType;
  timeAmount: number;
  description: string | null;
  defectAmount: number | null;
  amount: number | null;
};
