import { Badge, Card, Container, Flex, Grid, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { getOrderRequest } from '../../data/services/orders';
import { getTicketResquest } from '../../data/services/ticket';
import { Order } from '../../models/order';
import { Ticket } from '../../models/ticket';
import {
  ticketStatusColor,
  ticketStatusHumanized,
} from '../../utils/constants';
import { booleanToStringConverter, formatDateTime } from '../../utils/helpers';
import { CustomLoader } from '../CustomLoader';

interface TicketDetailsProps {
  ticketId: number;
}

export function TicketDetails({ ticketId }: TicketDetailsProps) {
  const [pageLoading, setPageLoading] = useState(false);
  const [ticket, setTicket] = useState<Ticket>();
  const [order, setOrder] = useState<Order>();

  async function getTicket(id: number) {
    setPageLoading(true);
    const ticketResponse = await getTicketResquest(Number(id));
    const orderResponse = await getOrderRequest(Number(ticketResponse.order));
    setPageLoading(false);

    setTicket(ticketResponse);
    setOrder(orderResponse);
  }

  useEffect(() => {
    getTicket(ticketId);
  }, [ticketId]);

  return (
    <Container>
      <CustomLoader loading={pageLoading} />
      <Flex align="center" justify="center">
        <Badge color={ticket && ticketStatusColor[ticket.status]} mb={6}>
          {ticket && ticketStatusHumanized[ticket.status]}
        </Badge>
      </Flex>
      <Grid m={8} gutter="xs" columns={2}>
        <Grid.Col mt={8} mb={8} span={2}>
          <Text color="gray">Info do Ticket</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Código</Text>
            <Text color="gray">{ticket?.code}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Responsável</Text>
            <Text color="gray">
              {ticket?.assignedUser ? ticket.assignedUser : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Compra</Text>
            <Text color="gray">
              {booleanToStringConverter(!!ticket?.needBuy)}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Limpeza</Text>
            <Text color="gray">
              {booleanToStringConverter(!!ticket?.needClean)}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Qtde. Compra</Text>
            <Text color="gray">
              {ticket?.buyQuantity ? ticket.buyQuantity : '0'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Criado por</Text>
            <Text color="gray">{`${ticket?.createdBy}` ?? '--'}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Criado em</Text>
            <Text color="gray">
              {ticket?.createdAt ? formatDateTime(ticket?.createdAt) : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Finalizado em</Text>
            <Text color="gray">
              {ticket?.finishedAt ? formatDateTime(ticket?.finishedAt) : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Obs</Text>
            <Text color="gray">{ticket?.obs ? ticket.obs : '--'}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Processo de limpeza</Text>
            <Text color="gray">
              {ticket?.cleanProcess ? ticket.cleanProcess : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Descrição de compra</Text>
            <Text color="gray">
              {ticket?.buyDescription ? ticket.buyDescription : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col mt={8} mb={8} span={2}>
          <Text color="gray">Info do Pedido</Text>
        </Grid.Col>
        <Grid.Col>
          <Card shadow="xs" mb={8}>
            <Flex justify="space-between">
              <Flex direction="column">
                <Text fw="bold" color="gray" size={12}>
                  OS
                </Text>
                <Text>{order?.os}</Text>
              </Flex>
              <Flex direction="column">
                <Text fw="bold" color="gray" size={12}>
                  Produto
                </Text>
                <Text>
                  {`${order?.product}, ${order?.productColor}, ${order?.productMaterial}` ??
                    '--'}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fw="bold" color="gray" size={12}>
                  Serviço
                </Text>
                <Text>{order?.service.name}</Text>
              </Flex>
            </Flex>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
