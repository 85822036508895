import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  ReceiptPaginatedParams,
  ReceiptPaginatedResponse,
  RemoveReceiptRequestParam,
} from '../../utils/types/data/services/receipts';

export async function paginatedReceiptsRequest(
  params: ReceiptPaginatedParams,
): Promise<ReceiptPaginatedResponse> {
  const response = await api.get<ReceiptPaginatedResponse>('order-receipts', {
    params,
  });

  return response.data;
}

export async function removeReceiptRequest(
  params: RemoveReceiptRequestParam,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`order-receipts/${params.id}`);

  return response;
}
