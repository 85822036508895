import {
  Alert,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../components/Page';
import {
  getOrderRequest,
  orderProceedWithProblemRequest,
} from '../../data/services/orders';
import { Order } from '../../models/order';
import { orderProbemHumanized } from '../../utils/constants';

type DefectFormValues = {
  approval: string;
  materialSend: string;
};

export function OrderDefect() {
  const { id } = useParams();
  const [order, setOrder] = useState<Order>();
  const navigation = useNavigate();

  const defectForm = useForm<DefectFormValues>({
    initialValues: {
      approval: '',
      materialSend: '',
    },
  });

  const handleSubmit = async (values: typeof defectForm.values) => {
    await orderProceedWithProblemRequest(Number(id), {
      approval: values.approval === 'true' ? true : false,
      materialSend:
        values.approval !== 'true' ? 'not_send' : values.materialSend,
    });

    navigation('/orders');
  };

  const productDefectProblem = () => {
    return (
      <Flex direction="column">
        <Group mt="xl">
          <Flex direction="column">
            <Text fw="bold">
              Temos {order?.defectAmount} peças com defeito no seu pedido,
              deseja efetuar a troca?
            </Text>

            <Flex gap={12} mt="xs">
              <Button
                color={
                  defectForm.values.materialSend === 'send' ? 'green' : 'gray'
                }
                onClick={() => defectForm.setFieldValue('materialSend', 'send')}
              >
                Sim
              </Button>
              <Button
                color={
                  defectForm.values.materialSend === 'not_send' ? 'red' : 'gray'
                }
                onClick={() =>
                  defectForm.setFieldValue('materialSend', 'not_send')
                }
              >
                Não
              </Button>
            </Flex>
          </Flex>
        </Group>
        <Group mt="xl" w="400">
          <Alert
            icon={<IconAlertCircle size={24} />}
            title="Atenção"
            color="red"
            w="100%"
          >
            Não nos responsabilizamos pelo prazo de troca do fornecedor, em
            virtude disso em caso onde o envio das novas peças seja após o
            término do pedido um novo faturamento minimo será cobrado.
          </Alert>
        </Group>
      </Flex>
    );
  };

  const productIncorrectQuantityProblem = () => {
    return (
      <Flex direction="column">
        <Group mt="xl">
          <Flex direction="column">
            <Text fw="bold">
              Estamos com quantidade a menos do informado no pedido, irá enviar
              mais peças?
            </Text>

            <Flex gap={12} mt="xs">
              <Button
                color={
                  defectForm.values.materialSend === 'send' ? 'green' : 'gray'
                }
                onClick={() => defectForm.setFieldValue('materialSend', 'send')}
              >
                Sim
              </Button>
              <Button
                color={
                  defectForm.values.materialSend === 'not_send' ? 'red' : 'gray'
                }
                onClick={() =>
                  defectForm.setFieldValue('materialSend', 'not_send')
                }
              >
                Não
              </Button>
            </Flex>
          </Flex>
        </Group>
        <Group mt="xl">
          <Alert
            icon={<IconAlertCircle size={24} />}
            title="Atenção"
            color="red"
            w="100%"
          >
            Se for enviado peças a mais o prazo poderá ser alterado.
          </Alert>
        </Group>
      </Flex>
    );
  };

  const renderOptions = () => {
    switch (order?.hasDefect) {
      case 'product_defect':
        return productDefectProblem();
      case 'incorrect_quantity':
        return productIncorrectQuantityProblem();
    }
  };

  async function getOrder() {
    const orderResponse = await getOrderRequest(Number(id));
    setOrder(orderResponse);
  }

  useEffect(() => {
    getOrder();
  }, [id]);

  if (!order) return null;

  return (
    <Page>
      <Flex justify="center" w="100%">
        <Card shadow="md" p={32} maw={640}>
          <Title size={24} mb={6}>
            Peças com defeito
          </Title>
          <Text>
            Encontramos um problema no seu pedido, veja os dados abaixo e
            confirme a continuidade.
          </Text>
          <Grid m={8} gutter="xs" columns={2}>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">OS</Text>
                <Text size={14} color="gray">
                  {order?.os}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Identificação Interna</Text>
                <Text size={14} color="gray">
                  {order?.internalNumber}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Serviço</Text>
                <Text size={14} color="gray">
                  {order?.service.name}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Produto</Text>
                <Text size={14} color="gray">
                  {order?.product}, {order?.productColor},{' '}
                  {order?.productMaterial}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Quantidade</Text>
                <Text size={14} color="gray">
                  {order?.quantity}
                </Text>
              </Flex>
            </Grid.Col>
          </Grid>
          <Grid m={8} gutter="xs" columns={2}>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold" color="red.7" size={16}>
                  Problema reportado:
                </Text>
                <Text size={14}>{orderProbemHumanized[order?.hasDefect]}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold" color="red.7" size={16}>
                  Quantidade:
                </Text>
                <Text size={14}>{order?.defectAmount}</Text>
              </Flex>
            </Grid.Col>
          </Grid>

          <form
            onSubmit={defectForm.onSubmit((values) => handleSubmit(values))}
          >
            <Flex direction="column" mt={12}>
              <Group mt="xl">
                <Flex direction="column">
                  <Text fw="bold">Deseja continuar com a produção?</Text>

                  <Flex gap={12} mt="xs">
                    <Button
                      color={
                        defectForm.values.approval === 'true' ? 'green' : 'gray'
                      }
                      onClick={() =>
                        defectForm.setFieldValue('approval', 'true')
                      }
                    >
                      Sim
                    </Button>
                    <Button
                      color={
                        defectForm.values.approval === 'false' ? 'red' : 'gray'
                      }
                      onClick={() =>
                        defectForm.setFieldValue('approval', 'false')
                      }
                    >
                      Não
                    </Button>
                  </Flex>
                </Flex>
              </Group>

              {defectForm.values.approval === 'true' ? renderOptions() : null}
              <Group mt="xl" position="right">
                <Button
                  color="ltpBlue.9"
                  disabled={defectForm.values.approval === ''}
                  type="submit"
                >
                  Salvar
                </Button>
              </Group>
            </Flex>
          </form>
        </Card>
      </Flex>
    </Page>
  );
}
