import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { rootNavigate } from '../components/CustomRouter';
import { createUserResquest, updateUserResquest } from '../data/services/users';
import { UserPopulated } from '../models/user';
import { errorNotification } from '../providers/mantine-notifications';
import { AppRoutePaths } from '../utils/enums';
import { translateServerHttpErrors } from '../utils/helpers';
import { CreateUserData } from '../utils/types/data/services/users';

interface InitialStateProps {
  loading: boolean;
  items: UserPopulated[];
}

const initialState: InitialStateProps = {
  loading: false,
  items: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    setAllUsers: (state, action: PayloadAction<UserPopulated[]>) => {
      state.items = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;

export const createUserDispatcher =
  (payload: CreateUserData) => async (dispatch: Dispatch) => {
    try {
      dispatch(usersActions.initLoading());
      await createUserResquest(payload);
      dispatch(usersActions.endLoading());
      rootNavigate(AppRoutePaths.USERS);
    } catch (error: any) {
      dispatch(usersActions.endLoading());
      errorNotification({
        title: 'Erro ao criar usuário!',
        message: translateServerHttpErrors(error, 'tente novamente'),
      });
    }
  };

export const updateUserDispatcher =
  (id: number, payload: CreateUserData) => async (dispatch: Dispatch) => {
    try {
      dispatch(usersActions.initLoading());
      await updateUserResquest(id, payload);
      dispatch(usersActions.endLoading());
      rootNavigate(AppRoutePaths.USERS);
    } catch (error: any) {
      dispatch(usersActions.endLoading());
      errorNotification({
        title: 'Erro ao atualizar usuário!',
        message: translateServerHttpErrors(error, 'tente novamente'),
      });
    }
  };
