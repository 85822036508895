import { Service } from '../../models/service';
import { api } from '../../providers/base-api';

export type ServicesResponse = Service[];

export async function getServicesRequest(): Promise<ServicesResponse> {
  const response = await api.get<ServicesResponse>('services');

  return response.data;
}
