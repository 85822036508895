/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Select,
  Stepper,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import { useCreateUser } from '../../data/hooks/users';
import { getTeamsRequest } from '../../data/services/teams';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Team } from '../../models/team';
import { UserRole, UserType } from '../../models/user';
import { successNotification } from '../../providers/mantine-notifications';

export function UserCreate() {
  const form = useForm({
    initialValues: {
      username: '',
      email: '',
      password: '',
      type: 'user',
      role: '',
      company: undefined,
      team: undefined,
    },
  });
  const [currentStep] = useState(0);
  const [teamList, setTeamList] = useState<Team[]>();
  const {
    fetch: listUserTypeOptionsFetcher,
    reponseData: listUserTypeOptionsData,
    loading: listUserTypeOptionsLoader,
  } = useListOptions();
  const { fetch: createUserFetcher, loading: createUserLoader } =
    useCreateUser();

  async function getUserTypeOptions() {
    await listUserTypeOptionsFetcher({
      query: {
        type: OptionTypes.USER_DATA,
        subtype: OptionSubTypes.USER_TYPE,
      },
    });
  }

  async function getTeams() {
    const response = await getTeamsRequest();
    setTeamList(response);
  }

  async function handleSubmit(values: typeof form.values) {
    await createUserFetcher({
      data: {
        ...values,
        ...(values.company ? { company: { id: Number(values.company) } } : {}),
        ...(values.team ? { team: { id: Number(values.team) } } : {}),
        type: values.type as UserType,
        role: values.role as UserRole,
      },
      onSuccess: () => {
        rootNavigateGoBack();
        successNotification({
          title: 'Usuário cadastrado!',
          message: 'tudo certo.',
        });
      },
    });
  }

  const validateTeamSelector =
    form.values.role === UserRole.VENDOR ||
    form.values.role === UserRole.RECEPTIONIST ||
    form.values.role === UserRole.TECHNICAL ||
    form.values.role === UserRole.HANDLER ||
    form.values.role === UserRole.FINAL_ART ||
    form.values.role === UserRole.FINANCIAL ||
    form.values.role === UserRole.PACKER;

  useEffect(() => {
    if (validateTeamSelector) {
      getTeams();
    }
  }, [form.values.role]);

  useEffect(() => {
    getUserTypeOptions();
  }, []);

  const teamOptions = teamList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));

  return (
    <Page>
      <Container>
        <CustomLoader loading={listUserTypeOptionsLoader || createUserLoader} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Novo Usuário
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={currentStep}
          >
            <Stepper.Step label="Usuário" description="dados do usuário">
              <TextInput
                required
                withAsterisk
                label="Usuário"
                placeholder="nome do usuário"
                mb={16}
                type="text"
                name="username"
                {...form.getInputProps('username')}
              />
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email do usuário"
                mb={16}
                type="text"
                name="email"
                {...form.getInputProps('email')}
              />
              <Select
                searchable
                required
                withAsterisk
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={
                  listUserTypeOptionsData?.map((option) => ({
                    label: option.name,
                    value: option.value,
                  })) ?? []
                }
                mb={16}
                {...form.getInputProps('role')}
              />
              {validateTeamSelector && (
                <Select
                  searchable
                  required
                  withAsterisk
                  name="team"
                  label="Time"
                  placeholder="selecione o time do usuário"
                  data={teamOptions ?? []}
                  mb={16}
                  {...form.getInputProps('team')}
                />
              )}
              <TextInput
                required
                withAsterisk
                minLength={6}
                label="Senha"
                placeholder="senha do usuário"
                mb={16}
                type="text"
                name="password"
                {...form.getInputProps('password')}
              />
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
