import { AxiosResponse } from 'axios';
import { SaleGoal } from '../../models/sale-goal';
import { api } from '../../providers/base-api';
import {
  CreateSaleGoal,
  GetSaleGoalsParams,
} from '../../utils/types/data/services/sale-goal';

export async function createSaleGoal(
  teamId: number,
  data: CreateSaleGoal,
): Promise<AxiosResponse<SaleGoal>> {
  const response = await api.post<SaleGoal>(`sale-goals/${teamId}`, data);

  return response;
}

export async function getSaleGoals(
  query: GetSaleGoalsParams,
): Promise<AxiosResponse<SaleGoal[]>> {
  const response = await api.get<SaleGoal[]>(`sale-goals/${query.team}`);

  return response;
}

export async function removeSaleGoal(id: number): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`sale-goals/${id}`);

  return response;
}
