import { ThemeIcon, NavLink } from '@mantine/core';
import { IconMailbox } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface PostalsLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function PostalsLink({ navigate, themeColor }: PostalsLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Correio"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="blue" variant="light">
          <IconMailbox size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Novo"
        onClick={() => navigate(AppRoutePaths.POSTAL_CREATE)}
      />
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.POSTAL_LIST)}
      />
    </NavLink>
  );
}
