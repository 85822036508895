import { Order } from './order';
import { User } from './user';

export enum ActivityType {
  LAYOUT = 'layout',
}

export enum ActivityStatus {
  PENDING = 'pending',
  ON_PROGRESS = 'on_progress',
  PAUSED = 'paused',
  CHANGE = 'change',
  APPROVED = 'approved',
  DONE = 'done',
}

export enum ActivityComplexity {
  DEFAULT = 'default',
  LOW = 'low',
  HIGH = 'high',
}

export type Activity = {
  id: number;
  order: number;
  target: number;
  type: ActivityType;
  status: ActivityStatus;
  complexity: ActivityComplexity;
  isPrioritary: boolean;
  obs?: string;
  createdAt: string;
  createdBy: string;
};

export type PopulatedActivity = Omit<Activity, 'order' | 'target'> & {
  order: Order;
  target: User;
};
