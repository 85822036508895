import { Team, TeamType } from '../../models/team';
import { TeamConfigAlias, TeamConfigType } from '../../models/team-config';
import { api } from '../../providers/base-api';

export type CreateTeamConfigData = {
  alias: TeamConfigAlias;
  value: string;
  type: TeamConfigType;
};

export type CreateTeamData = {
  name: string;
  email: string;
  type: TeamType;
  configs: CreateTeamConfigData[] | [];
};

export async function getTeamsRequest(): Promise<Team[]> {
  const response = await api.get<Team[]>(`teams`);

  return response.data;
}

export async function showTeamRequest(id: number): Promise<Team> {
  const response = await api.get<Team>(`teams/${id}`);

  return response.data;
}

export async function createTeamsRequest(data: CreateTeamData): Promise<Team> {
  const response = await api.post<Team>(`teams`, data);

  return response.data;
}

export async function updateTeamsRequest(
  id: number,
  data: CreateTeamData,
): Promise<Team> {
  const response = await api.put<Team>(`teams/${id}`, data);

  return response.data;
}
