import { Bill } from '../../models/bill';
import { api } from '../../providers/base-api';

export type CreateBillData = {
  name: string;
  description?: string;
  payDay: number;
  price: string;
  monthRecurrence: number;
};

export type PayBillData = {
  file: { id: number };
};

export async function getBillsRequest(): Promise<Bill[]> {
  const response = await api.get<Bill[]>(`bills`);

  return response.data;
}

export async function showBillRequest(id: number): Promise<Bill> {
  const response = await api.get<Bill>(`bills/${id}`);

  return response.data;
}

export async function createBillRequest(data: CreateBillData): Promise<Bill> {
  const response = await api.post<Bill>(`bills`, data);

  return response.data;
}

export async function updateBillRequest(
  id: number,
  data: CreateBillData,
): Promise<Bill> {
  const response = await api.put<Bill>(`bills/${id}`, data);

  return response.data;
}

export async function removeBillRequest(id: number): Promise<void> {
  const response = await api.delete<void>(`bills/${id}`);

  return response.data;
}

export async function payBillRequest(
  id: number,
  data: PayBillData,
): Promise<Bill> {
  const response = await api.post<Bill>(`bills/pay/${id}`, data);

  return response.data;
}
