import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { UseGetUsersFilterProps } from '../../../utils/types/data/hooks/filters';
import {
  GetFilterUsersListResponse,
  GetFilterUsersWithEmailListResponse,
} from '../../../utils/types/data/services/filters';
import {
  getFilterUsersListRequestV2,
  getFilterUsersWithEmailListRequest,
} from '../../services/filters';

export function useGetUsersFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterUsersListResponse[]>();

  const fetch = ({ query }: UseGetUsersFilterProps) =>
    fetchData({
      fetcher: () => getFilterUsersListRequestV2(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetUsersWithEmailFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterUsersWithEmailListResponse[]>();

  const fetch = ({ query }: UseGetUsersFilterProps) =>
    fetchData({
      fetcher: () => getFilterUsersWithEmailListRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
