import { Container, Flex, Grid, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { showOrderMaterialsRequest } from '../../data/services/order-material';
import { OrderMaterial } from '../../models/order-material';
import { errorNotification } from '../../providers/mantine-notifications';
import { formatDateTime } from '../../utils/helpers';
import { useParams } from 'react-router-dom';

interface OrderMaterialDetailsProps {
  orderMaterialIdProps?: number;
}

export function OrderMaterialDetails({
  orderMaterialIdProps,
}: OrderMaterialDetailsProps) {
  const { id } = useParams();

  const materialId = orderMaterialIdProps
    ? Number(orderMaterialIdProps)
    : Number(id);

  const [pageLoading, setPageLoading] = useState(false);
  const [orderMaterial, setOrderMaterial] = useState<OrderMaterial>();

  async function getOrderMaterial() {
    try {
      setPageLoading(true);
      const response = await showOrderMaterialsRequest(materialId);
      setOrderMaterial(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar AR!',
        message: 'ops! tente novamente',
      });
    }
  }

  useEffect(() => {
    getOrderMaterial();
  }, [materialId]);

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Container>
        <Grid m={8} gutter="xl" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text size="lg" fw="bold">
              Info do material
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Código</Text>
              <Text color="gray">{orderMaterial?.code}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Cliente</Text>
              <Text color="gray">{orderMaterial?.company.name}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">{orderMaterial?.product}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Material</Text>
              <Text color="gray">{orderMaterial?.productMaterial}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Cor</Text>
              <Text color="gray">{orderMaterial?.productColor}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{orderMaterial?.quantity}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Volumes</Text>
              <Text color="gray">{orderMaterial?.quantityVolume}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Possui NF</Text>
              <Text color="gray">{orderMaterial?.hasNf ? 'Sim' : 'Não'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Número da NF</Text>
              <Text color="gray">{orderMaterial?.nfNumber}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Recebido em</Text>
              <Text color="gray">
                {formatDateTime(orderMaterial?.receivedAt ?? '')}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Descrição da Embalagem</Text>
              <Text color="gray">{orderMaterial?.packageCondition}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Descrição do Produto</Text>
              <Text color="gray">{orderMaterial?.productCondition}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Observação</Text>
              <Text color="gray">{orderMaterial?.note}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Page>
  );
}
