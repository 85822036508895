/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Center,
  FileInput,
  FileInputProps,
  Flex,
  Image,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconX } from '@tabler/icons';

interface CustomMultipleFileInputProps extends FileInputProps {
  formRef: UseFormReturnType<any>;
  inputName?: string;
}

interface FileItemProps {
  formRef: UseFormReturnType<any>;
  file: File | null;
  inputName: string;
}

function FileItem({ file, formRef, inputName }: FileItemProps) {
  function removeFile() {
    const fileList: File[] = formRef.values?.[inputName];
    const fileIndex = fileList.findIndex(
      (fileItem) => fileItem.name === file?.name,
    );

    if (fileIndex !== -1) {
      fileList.splice(fileIndex, 1);
      formRef.setFieldValue(inputName, fileList);
    }
  }

  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        margin: '4px',
        borderRadius: theme.radius.sm,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      })}
    >
      <Button
        onClick={removeFile}
        style={{
          position: 'absolute',
          right: 10,
          top: 10,
          zIndex: 10,
        }}
        ml={8}
        p={4}
        h={20}
        color="red"
        variant="light"
      >
        <IconX size={12} />
      </Button>
      {file && (
        <Image
          width={100}
          height={100}
          src={URL.createObjectURL(file)}
          fit="contain"
          withPlaceholder
        />
      )}
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 100,
          display: 'inline-block',
        }}
      >
        {file?.name}
      </span>
    </Center>
  );
}

export function CustomMultipleFileInput({
  formRef,
  inputName = 'files',
  ...props
}: CustomMultipleFileInputProps) {
  return (
    <>
      <Flex mt={8} mb={8} wrap="wrap" maw={500}>
        {formRef.values?.[inputName]?.map((file: File, index: number) => (
          <FileItem
            formRef={formRef}
            file={file}
            key={index}
            inputName={inputName}
          />
        ))}
      </Flex>
      <FileInput
        onChange={(file) =>
          file &&
          formRef.values?.[inputName] &&
          formRef.setFieldValue(inputName, [...formRef.values[inputName], file])
        }
        {...props}
      />
    </>
  );
}
