import { User } from './user';

export enum ProductionType {
  PRODUCTION = 'production',
  PAUSE = 'pause',
  SETUP = 'setup',
}

export type OrderProduction = {
  id: number;
  order: number;
  clockIn: string;
  clockOut: string | null;
  technical: User;
  type: ProductionType;
  timeAmount: number;
  description: null | string;
  amount: number | null;
  supervisor: string | null;
};

export type OrderProductionReport = {
  id: number;
  order: number;
  technical: string;
  clockIn: string;
  clockOut: string;
  type: string;
  timeAmount: number;
  description: string;
  amount: number;
  supervisor: string;
  machine: string;
  os: string;
  price: string;
  sample_price: string;
  quantity: number;
  company: string;
  service: string;
  service_id: number;
};
