import {
  Menu,
  Button,
  Flex,
  Badge,
  TextInput,
  Paper,
  Select,
  Group,
  Checkbox,
} from '@mantine/core';
import { IconDotsVertical, IconEye } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { OrderMaterial } from '../../../models/order-material';
import {
  orderMaterialStatusColor,
  orderMaterialStatusHumanized,
} from '../../../utils/constants/order-material';
import { formatDateTime } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';

interface VendorOrderMaterialListProps {
  formFilter: any;
  handleFilter(): Promise<void>;
  handleClearFilter(): Promise<void>;
  companyList: GetFilterCompaniesListResponse[];
  loading: boolean;
  page: React.MutableRefObject<number>;
  pageMeta: PageMeta | undefined;
  handlePaginate(newPage: number): void;
  orderMaterialList: OrderMaterial[];
  handleChangePageLimit(limit: number): void;
}

export function VendorOrderMaterialListList({
  companyList,
  formFilter,
  handleClearFilter,
  handleFilter,
  handlePaginate,
  loading,
  orderMaterialList,
  page,
  pageMeta,
  handleChangePageLimit,
}: VendorOrderMaterialListProps) {
  const navigation = useNavigate();

  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit(() => handleFilter())}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="Código"
              placeholder="código da AR"
              mb={16}
              mr={8}
              type="text"
              name="code"
              {...formFilter.getInputProps('code')}
            />
            <TextInput
              label="Produto"
              placeholder="nome do produto"
              mb={16}
              mr={8}
              type="text"
              name="product"
              {...formFilter.getInputProps('product')}
            />
            <TextInput
              label="Cor"
              placeholder="cor do produto"
              mb={16}
              mr={8}
              type="text"
              name="productColor"
              {...formFilter.getInputProps('productColor')}
            />
            <TextInput
              label="Material"
              placeholder="material do produto"
              mb={16}
              mr={8}
              type="text"
              name="productMaterial"
              {...formFilter.getInputProps('productMaterial')}
            />
            <Select
              searchable
              name="company"
              label="Clientes"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de recebimento"
              lang="br"
              name="receivedAt"
              mb={16}
              mr={8}
              {...formFilter.getInputProps('receivedAt')}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
            <Checkbox
              mt={40}
              label="Mostrar associados"
              {...formFilter.getInputProps('showAssociates')}
            />
          </Group>
        </Paper>
      </form>
      <DataTable
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 300, 500]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        fetching={loading}
        minHeight={500}
        noRecordsText="Sem usuários"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={page.current}
        totalRecords={pageMeta?.totalItems}
        recordsPerPage={pageMeta?.itemsPerPage ?? 10}
        onPageChange={handlePaginate}
        records={orderMaterialList}
        columns={[
          {
            accessor: 'code',
            title: 'Código',
          },
          {
            accessor: '',
            title: 'Produto',
            render: ({ product, productColor, productMaterial }) =>
              `${product}, ${productColor}, ${productMaterial}`,
          },
          {
            accessor: 'quantity',
            title: 'Quantidade',
          },
          {
            accessor: 'quantityVolume',
            title: 'Volumes',
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'receivedAt',
            title: 'Recebido em',
            render: ({ receivedAt }) => formatDateTime(receivedAt ?? ''),
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderMaterialStatusColor[status]}>
                {orderMaterialStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'menu',
            title: '',
            render: (orderMaterialItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() =>
                      navigation(`details/${orderMaterialItem.id}`)
                    }
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
