import { ThemeIcon, NavLink } from '@mantine/core';
import { IconShoppingCart } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface PurchasesLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function PurchasesLink({ navigate, themeColor }: PurchasesLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Compras"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="green.9" variant="light">
          <IconShoppingCart size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Nova"
        onClick={() => navigate(AppRoutePaths.PURCHASES_CREATE)}
      />
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.PURCHASES)}
      />
    </NavLink>
  );
}
