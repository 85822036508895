import { Flex, Grid, Text } from '@mantine/core';

import { OrderDetail } from '../../models/order-detail';

interface DecalqueDetailsProps {
  orderDetails: OrderDetail;
}

export function DecalqueDetails({ orderDetails }: DecalqueDetailsProps) {
  return (
    <>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Detalhe o local de aplicação</Text>
          <Text color="gray">{orderDetails.personalizationArea}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Cores</Text>
          <Text color="gray">{orderDetails.colorsAmount}</Text>
        </Flex>
      </Grid.Col>
    </>
  );
}
