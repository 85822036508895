import { Badge, Card, Flex, Text, Image, Button } from '@mantine/core';
import { IconFile } from '@tabler/icons';
import axios from 'axios';
import { ActivityLayout } from '../../models/activity-layout';
import { ActivityLayoutFile } from '../../models/activity-layout-file';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  activityLayoutStatusColor,
  activityLayoutStatusHumanized,
} from '../../utils/constants/activity-layout';

interface ActivityLayoutProps {
  activityLayout: ActivityLayout;
  files: ActivityLayoutFile[] | undefined;
}

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function ActivityLayoutItem({
  activityLayout,
  files,
}: ActivityLayoutProps) {
  return (
    <Card mt={16} maw={500} withBorder>
      <Flex justify="space-between">
        <Text fw="bold">Layout</Text>
        <Badge
          maw={160}
          color={activityLayoutStatusColor[activityLayout?.status]}
        >
          {activityLayoutStatusHumanized[activityLayout?.status]}
        </Badge>
      </Flex>
      <Flex justify="space-between">
        <Text>
          Avaliado por:{' '}
          {activityLayout?.clientName || activityLayout?.vendorName}
        </Text>
        <Text>
          {activityLayout.approval &&
            formatLocale(activityLayout.approval, 'DD/MM/YY HH:mm')}
        </Text>
      </Flex>
      <Text>
        Obs: {activityLayout?.clientNote || activityLayout?.vendorNote}
      </Text>
      <Text fw="bold" mt={16}>
        Arquivos
      </Text>
      <Flex wrap="wrap">
        {files?.map((item) => (
          <Card key={item.file} bg="gray.1" withBorder w={210} h={250}>
            <Image
              height={100}
              src={item.fileUrl}
              fit="cover"
              withPlaceholder
            />
            <Flex mt={10} align="center">
              <IconFile size={14} style={{ marginRight: 5 }} />
              <Button
                variant="subtle"
                onClick={() => downloadFile(item.fileUrl, item.fileName)}
              >
                <Text
                  variant="link"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: 100,
                    display: 'inline-block',
                  }}
                >
                  {item.fileName}
                </Text>
              </Button>
            </Flex>
            <Text>por: {item.ownerName}</Text>
            <Text>{formatLocale(item.createdAt, 'DD/MM/YY HH:mm')}</Text>
          </Card>
        ))}
      </Flex>
    </Card>
  );
}
