import { AxiosResponse } from 'axios';
import { CompanyDocument } from '../../models/company-document';

import { api } from '../../providers/base-api';
import {
  AddCompanyDocumentsRequestData,
  GetCompanyDocumentsPaginatedQuery,
  GetCompanyDocumentsPaginatedResponse,
} from '../../utils/types/data/services/company-documents';

export async function getCompanyDocumentsRequest(
  companyId: number,
): Promise<AxiosResponse<CompanyDocument[]>> {
  const response = await api.get<CompanyDocument[]>(
    `company-documents/${companyId}`,
  );

  return response;
}

export async function addCompanyDocumentsRequest(
  data: AddCompanyDocumentsRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`company-documents`, data);

  return response;
}

export async function removeCompanyDocumentsRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`company-documents/${id}`);

  return response;
}

export async function getCompanyDocumentsPaginatedRequest(
  query: GetCompanyDocumentsPaginatedQuery,
): Promise<AxiosResponse<GetCompanyDocumentsPaginatedResponse>> {
  const response = await api.get<GetCompanyDocumentsPaginatedResponse>(
    `company-documents`,
    { params: query },
  );

  return response;
}
