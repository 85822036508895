import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { useRef, useState } from 'react';

type TagInputPros = MultiSelectProps & {
  handleChange(value: string[]): void;
};

export function TagInput({ handleChange, ...props }: TagInputPros) {
  const [text, setText] = useState('');
  const catchNext = useRef(false);

  function handleSearchChange(value: string) {
    if (catchNext.current) {
      let accumulator = props.data as string[];
      accumulator = [...accumulator, ...value.replaceAll(' ', '').split(',')];
      handleChange(accumulator);
      catchNext.current = false;
    }
  }

  function handleChangeList(value: string[]) {
    handleChange(value);
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.code === 'Comma') {
      if (text === '') {
        return;
      }
      const accumulator = props.data as string[];
      accumulator.push(text);
      setText('');
      props.searchValue = '';
      handleChange(accumulator);
    } else if ((event.ctrlKey || event.metaKey) && event.code === 'KeyV') {
      catchNext.current = true;
    } else if (event.code === 'Backspace') {
      const textClone = text;
      setText(textClone.slice(0, textClone.length - 1));
    } else if (event.code === 'Space') {
      // do nothing
    } else if (event.key.length === 1) {
      const concat = `${text}${event.key}`;
      setText(concat);
    }
  }

  return (
    <MultiSelect
      {...props}
      searchValue={text}
      onChange={handleChangeList}
      searchable
      onSearchChange={handleSearchChange}
      onKeyDown={handleKeyPress}
      styles={{
        value: { fontWeight: 'bold' },
      }}
    />
  );
}
