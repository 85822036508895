import { Flex, Grid, Text } from '@mantine/core';

import { OrderDetail } from '../../models/order-detail';
import { booleanToStringConverter } from '../../utils/helpers';

interface DigitalUvDetailsProps {
  orderDetails: OrderDetail;
}

export function DigitalUvDetails({ orderDetails }: DigitalUvDetailsProps) {
  return (
    <>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Altura</Text>
          <Text color="gray">{orderDetails.height}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Largura</Text>
          <Text color="gray">{orderDetails.width}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Tem aplicação de verniz</Text>
          <Text color="gray">
            {booleanToStringConverter(!!orderDetails.hasVarnish)}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Tem dados variados</Text>
          <Text color="gray">
            {booleanToStringConverter(!!orderDetails.hasManyData)}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Local da gravação</Text>
          <Text color="gray">{orderDetails.personalizationSides}</Text>
        </Flex>
      </Grid.Col>
    </>
  );
}
