import { MaterialStatus } from '../../models/order-material';

export const orderMaterialStatusHumanized: Record<MaterialStatus, string> = {
  approved: 'aprovado',
  associated: 'associado',
  initial: 'pendente',
  reproved: 'reprovado',
};

export const orderMaterialStatusColor: Record<MaterialStatus, string> = {
  approved: 'green',
  associated: 'blue',
  initial: 'orange',
  reproved: 'red',
};
