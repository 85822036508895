import {
  Accordion,
  Badge,
  Container,
  Flex,
  Grid,
  Text,
  Timeline,
} from '@mantine/core';
import { IconGitBranch, IconHistory } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useListOptions } from '../../data/hooks/options';
import { getOrderLogRequest } from '../../data/services/order-log';
import {
  getOrderDetailsRequest,
  getOrderRequest,
} from '../../data/services/orders';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Order } from '../../models/order';
import { OrderDetail } from '../../models/order-detail';
import { FileType } from '../../models/order-file';
import { OrderLog } from '../../models/order-log';
import {
  orderProbemHumanized,
  orderProblemApprovalTypeHumanized,
  orderStatusColor,
  orderStatusHumanized,
} from '../../utils/constants';
import {
  booleanToStringConverter,
  booleanToStringConverterOrderProbem,
  formatBRL,
  formatDateTime,
} from '../../utils/helpers';
import { CustomLoader } from '../CustomLoader';
import { OrderFile } from '../OrderFile';
import { AdesivoDetails } from './adesivo';
import { CorteLaserDetails } from './corte-laser';
import { DecalqueDetails } from './decalque';
import { DigitalUvDetails } from './digital-uv';
import { EnvelopamentoDetails } from './envelopamento';
import { EtiquetaResinadaDetails } from './etiqueta-resinada';
import { GravacaoLaserDetails } from './gravacao-laser';
import { HotStampingDetails } from './hot-stamping';
import { SilkscreenDetails } from './silkscreen';
import { SublimacaoDetails } from './sublimacao';
import { TampografiaDetails } from './tampografia';
import { TransferDetails } from './transfer';
import { TransferCilindricoDetails } from './transfer-cilindrico';
import { useSelector } from 'react-redux';
import { RootState } from '../../providers/store';
import { UserRole } from '../../models/user';

type OrderDetailsSections =
  | 'basic'
  | 'prices'
  | 'dates'
  | 'service-details'
  | 'order-files'
  | 'order-receipt-files'
  | 'historic'
  | 'payment'
  | 'problem-reported';

interface OrderDetailsProps {
  orderId: number;
  sections: OrderDetailsSections[];
}

export function OrderDetails({ orderId, sections }: OrderDetailsProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  const [pageLoading, setPageLoading] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [orderDetails, setOrderDetails] = useState<OrderDetail>();
  const [orderLogs, setOrderLogs] = useState<OrderLog[]>();

  const servicerFieldRender: any = orderDetails && {
    adesivo: () => <AdesivoDetails orderDetails={orderDetails} />,
    corte_a_laser: () => <CorteLaserDetails orderDetails={orderDetails} />,
    decalque: () => <DecalqueDetails orderDetails={orderDetails} />,
    digital_uv: () => <DigitalUvDetails orderDetails={orderDetails} />,
    envelopamento: () => <EnvelopamentoDetails orderDetails={orderDetails} />,
    etiqueta_resinada: () => (
      <EtiquetaResinadaDetails orderDetails={orderDetails} />
    ),
    gravacao_a_laser: () => (
      <GravacaoLaserDetails orderDetails={orderDetails} />
    ),
    hot_stamping: () => <HotStampingDetails orderDetails={orderDetails} />,
    silkscreen: () => <SilkscreenDetails orderDetails={orderDetails} />,
    sublimacao: () => <SublimacaoDetails orderDetails={orderDetails} />,
    tampografia: () => <TampografiaDetails orderDetails={orderDetails} />,
    transfer_dtf: () => <TransferDetails orderDetails={orderDetails} />,
    transfer_cilindrico: () => (
      <TransferCilindricoDetails orderDetails={orderDetails} />
    ),
  };

  const price = parseFloat(order?.price || '0');
  const samplePrice = parseFloat(order?.samplePrice || '0');
  const photolitoPrice = parseFloat(order?.pholitoPrice || '0');
  const manipulationPrice = parseFloat(order?.manipulationPrice || '0');
  const vectorizationPrice = parseFloat(order?.vectorizationPrice || '0');
  const clichePrice = parseFloat(order?.clichePrice || '0');
  const quantity = Number(order?.quantity || '0');
  const unitPrice = Math.fround(price / quantity).toFixed(2);
  const orderUnitPrice = formatBRL(unitPrice);
  const orderTotalPrice =
    price +
    samplePrice +
    photolitoPrice +
    manipulationPrice +
    vectorizationPrice +
    clichePrice;

  const {
    fetch: listPaymentOptionsFetcher,
    reponseData: listPaymentOptionsData,
    loading: listPaymentOptionsLoader,
  } = useListOptions();

  async function getPaymentOptions() {
    await listPaymentOptionsFetcher({
      query: {
        type: OptionTypes.BANK_ACCOUNT,
      },
    });
  }

  async function getOrder(id: number) {
    setPageLoading(true);
    const orderResponse = await getOrderRequest(Number(id));
    const orderDetailResponse = await getOrderDetailsRequest(Number(id));
    const orderLogsResponse = await getOrderLogRequest(Number(id));
    setPageLoading(false);

    setOrder(orderResponse);
    setOrderDetails(orderDetailResponse);
    setOrderLogs(orderLogsResponse);
  }

  useEffect(() => {
    getOrder(orderId);
    getPaymentOptions();
  }, [orderId]);

  return (
    <Container>
      <CustomLoader loading={pageLoading || listPaymentOptionsLoader} />
      <Flex align="center" justify="center">
        <Badge color={order && orderStatusColor[order.status]} mb={6}>
          {order && orderStatusHumanized[order.status]}
        </Badge>
      </Flex>
      <Grid m={8} gutter="xs" columns={2}>
        {sections.includes('problem-reported') && (
          <>
            {order?.hasDefect ? (
              <>
                <Grid.Col mt={8} mb={8} span={2}>
                  <Text color="gray">Problema Reportado</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Ocorrência</Text>
                    <Text color="gray">
                      {orderProbemHumanized[order?.hasDefect]}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Quantidade</Text>
                    <Text color="gray">{order?.defectAmount}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Aprovação do Cliente</Text>
                    <Text color="gray">
                      {booleanToStringConverterOrderProbem(!!order?.approval)}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Aprovado por</Text>
                    <Text color="gray">{order?.approvalBy}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Peças a Enviar</Text>
                    <Text color="gray">
                      {order?.approvalType &&
                        orderProblemApprovalTypeHumanized[order?.approvalType]}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Aprovado em</Text>
                    <Text color="gray">
                      {order?.approvalOn
                        ? formatDateTime(order?.approvalOn)
                        : '--'}
                    </Text>
                  </Flex>
                </Grid.Col>
              </>
            ) : (
              ''
            )}
          </>
        )}
        {sections.includes('basic') && (
          <>
            {order?.rework ? (
              <>
                <Grid.Col mt={8} mb={8} span={2}>
                  <Text color="gray">Info Interno</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Retrabalho</Text>
                    <Text color="gray">
                      {booleanToStringConverter(!!order?.rework)}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Responsável pelo retrabalho</Text>
                    <Text color="gray">{order?.reworkUser ?? '--'}</Text>
                  </Flex>
                </Grid.Col>
              </>
            ) : (
              ''
            )}
            <Grid.Col mt={8} mb={8} span={2}>
              <Text color="gray">Info do Pedido</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">OS</Text>
                <Text color="gray">{order?.os ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Identificador</Text>
                <Text color="gray">{order?.internalNumber ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Serviço</Text>
                <Text color="gray">{order?.service.name ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Quantidade</Text>
                <Text color="gray">{order?.quantity ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Produto</Text>
                <Text color="gray">
                  {`${order?.product}, ${order?.productColor}, ${order?.productMaterial}` ??
                    '--'}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Nome do Logotipo</Text>
                <Text color="gray">{order?.engravingText}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Volumes</Text>
                <Text color="gray">{order?.quantityVolume ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Necessita de retirada</Text>
                <Text color="gray">
                  {booleanToStringConverter(!!order?.needsPickUp)}
                </Text>
              </Flex>
            </Grid.Col>
            {order?.needsPickUp ? (
              <>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Local da retirada</Text>
                    <Text color="gray">{order?.pickUpLocation ?? '--'}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Resposável pela retirada</Text>
                    <Text color="gray">{order.pickUpName ?? '--'}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">
                      Codigo para identificação no fornecedor
                    </Text>
                    <Text color="gray">{order.pickUpNumber ?? '--'}</Text>
                  </Flex>
                </Grid.Col>
              </>
            ) : (
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Portador</Text>
                  <Text color="gray">{order?.carrierName ?? '--'}</Text>
                </Flex>
              </Grid.Col>
            )}
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Portador (retirada)</Text>
                <Text color="gray">{order?.carrierName ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col mt={8} mb={8} span={2}>
              <Text color="gray">Info Adicional</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Fornecedor</Text>
                <Text color="gray">{order?.supplier ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Código do Produto</Text>
                <Text color="gray">{order?.productCodeSupplier ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Correio</Text>
                <Text color="gray">
                  {booleanToStringConverter(!!order?.needsPostal)}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Peças com Defeito</Text>
                <Text color="gray">
                  {booleanToStringConverter(!!order?.hasDefect)}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Unidade de Retirada</Text>
                <Text color="gray">{order?.dispatchLocation ?? '--'}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex direction="column">
                <Text fw="bold">Observação</Text>
                <Text color="gray">{order?.note || '--'}</Text>
              </Flex>
            </Grid.Col>
          </>
        )}
        {sections.includes('prices') && (
          <>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Clichê</Text>
                <Text color="gray">{formatBRL(order?.clichePrice || '0')}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Fotolito</Text>
                <Text color="gray">
                  {formatBRL(order?.pholitoPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Manuseio</Text>
                <Text color="gray">
                  {formatBRL(order?.manipulationPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Vetorização</Text>
                <Text color="gray">
                  {formatBRL(order?.vectorizationPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Amostra</Text>
                <Text color="gray">{formatBRL(order?.samplePrice || '0')}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço (total gravação)</Text>
                <Text color="gray">{formatBRL(order?.price || '0')}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço unitário</Text>
                <Text color="gray">{orderUnitPrice}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço final</Text>
                <Text color="gray">
                  {formatBRL(orderTotalPrice.toFixed(2))}
                </Text>
              </Flex>
            </Grid.Col>
          </>
        )}
        {sections.includes('payment') && (
          <>
            {order?.paymentType === 'cash_payment' && (
              <>
                <Grid.Col mt={8} mb={8} span={2}>
                  <Text color="gray">Info. Pagamento</Text>
                </Grid.Col>
                {listPaymentOptionsData?.map((option) => (
                  <Grid.Col span={1}>
                    <Flex direction="column">
                      {option.subtype === OptionSubTypes.BANK_ACCOUNT_PIX && (
                        <>
                          <Text fw="bold">Pix</Text>
                          <Text color="gray">{option.name}</Text>
                          <Text color="gray">{option.value}</Text>
                        </>
                      )}
                      {option.subtype ===
                        OptionSubTypes.BANK_ACCOUNT_DEPOSIT_NUMBER && (
                        <>
                          <Text fw="bold">Conta Bancária</Text>
                          <Text color="gray">{option.name}</Text>
                          <Text color="gray">{option.value}</Text>
                        </>
                      )}
                    </Flex>
                  </Grid.Col>
                ))}
              </>
            )}
          </>
        )}
        {sections.includes('dates') && (
          <>
            <Grid.Col mt={8} mb={8} span={2}>
              <Text color="gray">Datas</Text>
            </Grid.Col>
            {order?.needsPickUp ? (
              <>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Previsão de coleta do material</Text>
                    <Text color="gray">
                      {order?.needsPickUp && order?.pickUpForecast
                        ? formatDateTime(order?.pickUpForecast)
                        : '--'}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Material coletado em</Text>
                    <Text color="gray">
                      {order?.receiptedAt
                        ? formatDateTime(order?.receiptedAt)
                        : '--'}
                    </Text>
                  </Flex>
                </Grid.Col>
              </>
            ) : (
              <>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Previsão de Entrega do Material</Text>
                    <Text color="gray">
                      {!order?.needsPickUp && order?.receiptForecast
                        ? formatDateTime(order?.receiptForecast)
                        : '--'}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Flex direction="column">
                    <Text fw="bold">Material Entregue em</Text>
                    <Text color="gray">
                      {order?.receiptedAt
                        ? formatDateTime(order?.receiptedAt)
                        : '--'}
                    </Text>
                  </Flex>
                </Grid.Col>
              </>
            )}
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Prazo de finalização</Text>
                <Text color="gray">
                  {order?.finishForecast
                    ? formatDateTime(order?.finishForecast)
                    : '--'}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Finalizado em</Text>
                <Text color="gray">
                  {order?.finishedAt ? formatDateTime(order?.finishedAt) : '--'}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Previsão de Retirada</Text>
                <Text color="gray">
                  {order?.dispatchForecast
                    ? formatDateTime(order?.dispatchForecast)
                    : '--'}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Retirado em</Text>
                <Text color="gray">
                  {order?.dispatchedAt
                    ? formatDateTime(order?.dispatchedAt)
                    : '--'}
                </Text>
              </Flex>
            </Grid.Col>
          </>
        )}
        {sections.includes('service-details') && (
          <>
            <Grid.Col mt={8} mb={8} span={2}>
              <Text color="gray">Personalização</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Inclui Amostra</Text>
                <Text color="gray">
                  {booleanToStringConverter(!!orderDetails?.hasSample)}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Tem Manuseio</Text>
                <Text color="gray">
                  {booleanToStringConverter(!!orderDetails?.hasManipulation)}
                </Text>
              </Flex>
            </Grid.Col>
            {order &&
              servicerFieldRender?.[order.service.alias] &&
              servicerFieldRender?.[order.service.alias]()}
          </>
        )}
      </Grid>
      {sections.includes('order-files') && (
        <OrderFile files={order?.files ?? []} types={[FileType.FILE]} />
      )}
      {sections.includes('order-receipt-files') && (
        <OrderFile files={order?.files ?? []} types={[FileType.RECEIPT]} />
      )}
      {sections.includes('historic') && (
        <Accordion variant="contained" mt={16}>
          <Accordion.Item value="photos">
            <Accordion.Control icon={<IconHistory size={30} color="orange" />}>
              Histórico
            </Accordion.Control>
            <Accordion.Panel>
              <Timeline
                color="green"
                active={orderLogs?.length}
                bulletSize={24}
                lineWidth={2}
              >
                {orderLogs?.map((item) => (
                  <Timeline.Item
                    key={item.id}
                    bullet={<IconGitBranch size={12} />}
                    title={
                      <Badge color={orderStatusColor[item.status]} mb={6}>
                        {orderStatusHumanized[item.status]}
                      </Badge>
                    }
                  >
                    <Text color="dimmed" size="sm">{`${
                      user?.role !== UserRole.COMPANY ? item.title : ''
                    } ${item.message}`}</Text>
                    <Text size="xs" mt={4}>
                      {formatDateTime(item.createdAt)}
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}
    </Container>
  );
}
