import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { useUserConditionalRender } from '../hooks/use-user-conditonal-render';
import { UserRole } from '../models/user';
import { ActivityList } from '../pages/ActivityList';
import { ArtProduction } from '../pages/ArtProduction';
import { ArtReport } from '../pages/ArtReport';
import { BillCreate } from '../pages/BillCreate';
import { BillEdit } from '../pages/BillEdit';
import { BillList } from '../pages/BillList';
import { CompanyCreate } from '../pages/CompanyCreate';
import { CompanyEdit } from '../pages/CompanyEdit';
import { CompanyList } from '../pages/CompanyList';
import { ConfirmBudget } from '../pages/ConfirmBudget';
import { FinancialInvoice } from '../pages/FinancialInvoice';
import { FinancialPayment } from '../pages/FinancialPayment';
import { Home } from '../pages/Home';
import { LayoutList } from '../pages/LayoutList';
import { Login } from '../pages/Login';
import { CreateOrder } from '../pages/OrderCreate';
import { EditOrder } from '../pages/OrderEdit';
import { OrderHandling } from '../pages/OrderHandling';
import { OrderHistoric } from '../pages/OrderHistoric';
import { OrderList } from '../pages/OrderList';
import { OrderMaterialCreate } from '../pages/OrderMaterialCreate';
import { OrderMaterialDetails } from '../pages/OrderMaterialDetails';
import { OrderMaterialEdit } from '../pages/OrderMaterialEdit';
import { OrderMaterialList } from '../pages/OrderMaterialList';
import { OrderPacking } from '../pages/OrderPacking';
import { OrderProduction } from '../pages/OrderProduction';
import { PageNotFound } from '../pages/PageNotFound';
import { PaymentReport } from '../pages/PaymentReport';
import { PostalCreate } from '../pages/PostalCreate';
import { PostalEdit } from '../pages/PostalEdit';
import { PostalList } from '../pages/PostalList';
import { ProductionReport } from '../pages/ProductionReport';
import { PurchaseCreate } from '../pages/PurchaseCreate';
import { PurchaseEdit } from '../pages/PurchaseEdit';
import { PurchaseList } from '../pages/PurchaseList';
import { ResetPassword } from '../pages/ResetPassword';
import { TeamCreate } from '../pages/TeamCreate';
import { TeamEdit } from '../pages/TeamEdit';
import { TeamList } from '../pages/TeamList';
import { UserCreate } from '../pages/UserCreate';
import { UserEdit } from '../pages/UserEdit';
import { UserList } from '../pages/UserList';
import { RootState } from '../providers/store';
import { AppRoutePaths } from '../utils/enums';
import { ReceiptList } from '../pages/ReceiptList';
import { PCPList } from '../pages/PCPList';
import { SacList } from '../pages/SacList';
import { OptionList } from '../pages/OptionList';
import { OrderDispatch } from '../pages/OrderDispatch';
import { OrderLateList } from '../pages/OrderLateList';
import { CreateOption } from '../pages/OptionCreate';
import { EditOption } from '../pages/OptionEdit';
import { OrderDefect } from '../pages/OrderDefect';
import { OrderFileReceiptsApproval } from '../pages/OrderFileReceiptsApproval';

export function Router() {
  const { signed } = useSelector((state: RootState) => state.auth);
  const {
    isAdminType,
    isUserType,
    isUserTypeWithRole,
    isUserTypeWithRoles,
    isMainUser,
  } = useUserConditionalRender();

  return (
    <Routes>
      {signed && (
        <>
          {isUserTypeWithRoles(
            [
              UserRole.COMPANY,
              UserRole.FINAL_ART,
              UserRole.MANAGER,
              UserRole.OTHER,
              UserRole.PACKER,
              UserRole.RECEPTIONIST,
              UserRole.TECHNICAL,
              UserRole.VENDOR,
              UserRole.FINANCIAL,
              UserRole.HANDLER,
            ],
            <>
              <Route path={AppRoutePaths.ORDERS} element={<OrderList />} />
              <Route
                path={AppRoutePaths.ORDER_HISTORIC}
                element={<OrderHistoric />}
              />
              <Route
                path={AppRoutePaths.REPORTS_PRODUCTION}
                element={<ProductionReport />}
              />
            </>,
          )}
          <Route path={AppRoutePaths.HOME} element={<Home />} />
          {isAdminType(
            <>
              <Route path={AppRoutePaths.ORDERS} element={<OrderList />} />
              <Route path={AppRoutePaths.EDIT_ORDER} element={<EditOrder />} />
              <Route
                path={AppRoutePaths.ORDER_HISTORIC}
                element={<OrderHistoric />}
              />
              <Route path={AppRoutePaths.USERS} element={<UserList />} />
              <Route
                path={AppRoutePaths.USERS_CREATE}
                element={<UserCreate />}
              />
              <Route path={AppRoutePaths.USERS_EDIT} element={<UserEdit />} />
              <Route path={AppRoutePaths.COMPANY} element={<CompanyList />} />
              <Route
                path={AppRoutePaths.COMPANY_CREATE}
                element={<CompanyCreate />}
              />
              <Route
                path={AppRoutePaths.COMPANY_EDIT}
                element={<CompanyEdit />}
              />
              <Route path={AppRoutePaths.TEAMS} element={<TeamList />} />
              <Route
                path={AppRoutePaths.TEAMS_CREATE}
                element={<TeamCreate />}
              />
              <Route path={AppRoutePaths.TEAMS_EDIT} element={<TeamEdit />} />
              <Route
                path={AppRoutePaths.REPORTS_PRODUCTION}
                element={<ProductionReport />}
              />
              <Route
                path={AppRoutePaths.REPORTS_PAYMENTS}
                element={<PaymentReport />}
              />
              <Route path={AppRoutePaths.REPORTS_ART} element={<ArtReport />} />
              <Route
                path={AppRoutePaths.ORDER_MATERIALS}
                element={<OrderMaterialList />}
              />
              <Route
                path={AppRoutePaths.ORDER_MATERIALS_EDIT}
                element={<OrderMaterialEdit />}
              />
              <Route path={AppRoutePaths.ORDER_PCP} element={<PCPList />} />
              <Route path={AppRoutePaths.SAC_LIST} element={<SacList />} />
              <Route
                path={AppRoutePaths.ORDERS_LATE}
                element={<OrderLateList />}
              />
              <Route
                path={AppRoutePaths.OPTION_LIST}
                element={<OptionList />}
              />
              <Route
                path={AppRoutePaths.OPTION_CREATE}
                element={<CreateOption />}
              />
              <Route
                path={AppRoutePaths.OPTION_EDIT}
                element={<EditOption />}
              />
            </>,
          )}
          {isUserType(
            <>
              <Route
                path={AppRoutePaths.CONFIRM_BUDGET}
                element={<ConfirmBudget />}
              />
              <Route
                path={AppRoutePaths.CREATE_ORDER}
                element={<CreateOrder />}
              />
              <Route path={AppRoutePaths.EDIT_ORDER} element={<EditOrder />} />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.VENDOR,
            <>
              <Route path={AppRoutePaths.COMPANY} element={<CompanyList />} />
              <Route
                path={AppRoutePaths.COMPANY_CREATE}
                element={<CompanyCreate />}
              />
              <Route
                path={AppRoutePaths.COMPANY_EDIT}
                element={<CompanyEdit />}
              />
              <Route
                path={AppRoutePaths.ACTIVITIES}
                element={<ActivityList />}
              />
              <Route
                path={AppRoutePaths.ORDER_MATERIALS}
                element={<OrderMaterialList />}
              />
              <Route
                path={AppRoutePaths.ACTIVITY_LAYOUTS}
                element={<LayoutList />}
              />
              <Route
                path={AppRoutePaths.POSTAL_LIST}
                element={<PostalList />}
              />
              <Route
                path={AppRoutePaths.POSTAL_CREATE}
                element={<PostalCreate />}
              />
              <Route
                path={AppRoutePaths.POSTAL_EDIT}
                element={<PostalEdit />}
              />
              <Route
                path={AppRoutePaths.ART_ACTIVITY}
                element={<ArtProduction />}
              />
              <Route
                path={AppRoutePaths.REPORTS_PAYMENTS}
                element={<PaymentReport />}
              />
              <Route path={AppRoutePaths.ORDER_PCP} element={<PCPList />} />
              <Route path={AppRoutePaths.SAC_LIST} element={<SacList />} />
              <Route
                path={AppRoutePaths.ORDERS_LATE}
                element={<OrderLateList />}
              />
              {isMainUser(
                <>
                  <Route path={AppRoutePaths.USERS} element={<UserList />} />
                  <Route
                    path={AppRoutePaths.USERS_CREATE}
                    element={<UserCreate />}
                  />
                  <Route
                    path={AppRoutePaths.USERS_EDIT}
                    element={<UserEdit />}
                  />
                </>,
              )}
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.TECHNICAL,
            <>
              <Route
                path={AppRoutePaths.ORDER_PRODUCTION}
                element={<OrderProduction />}
              />
              <Route path={AppRoutePaths.ORDER_PCP} element={<PCPList />} />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.COMPANY,
            <>
              <Route
                path={AppRoutePaths.ACTIVITY_LAYOUTS}
                element={<LayoutList />}
              />
              <Route path={AppRoutePaths.SAC_LIST} element={<SacList />} />
              <Route
                path={AppRoutePaths.ORDER_DEFECT}
                element={<OrderDefect />}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.FINAL_ART,
            <>
              <Route
                path={AppRoutePaths.ACTIVITIES}
                element={<ActivityList />}
              />
              <Route
                path={AppRoutePaths.ART_ACTIVITY}
                element={<ArtProduction />}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.PACKER,
            <>
              <Route
                path={AppRoutePaths.ORDER_PACKING}
                element={<OrderPacking />}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.HANDLER,
            <>
              <Route
                path={AppRoutePaths.ORDER_HANDLING}
                element={<OrderHandling />}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.RECEPTIONIST,
            <>
              <Route
                path={AppRoutePaths.ORDER_MATERIALS}
                element={<OrderMaterialList />}
              />
              <Route
                path={AppRoutePaths.ORDER_MATERIALS_CREATE}
                element={<OrderMaterialCreate />}
              />
              <Route
                path={AppRoutePaths.ORDER_MATERIALS_EDIT}
                element={<OrderMaterialEdit />}
              />
              <Route
                path={AppRoutePaths.POSTAL_LIST}
                element={<PostalList />}
              />
              <Route
                path={AppRoutePaths.POSTAL_CREATE}
                element={<PostalCreate />}
              />
              <Route
                path={AppRoutePaths.POSTAL_EDIT}
                element={<PostalEdit />}
              />
              <Route path={AppRoutePaths.COMPANY} element={<CompanyList />} />
              <Route
                path={AppRoutePaths.RECEIPT_LIST}
                element={<ReceiptList />}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.FINANCIAL,
            <>
              <Route
                path={AppRoutePaths.FINANCIAL_INVOICE}
                element={<FinancialInvoice />}
              />
              <Route
                path={AppRoutePaths.FINANCIAL_PAYMENT}
                element={<FinancialPayment />}
              />
              <Route
                path={AppRoutePaths.PURCHASES}
                element={<PurchaseList />}
              />
              <Route
                path={AppRoutePaths.PURCHASES_CREATE}
                element={<PurchaseCreate />}
              />
              <Route
                path={AppRoutePaths.PURCHASES_EDIT}
                element={<PurchaseEdit />}
              />
              <Route path={AppRoutePaths.BILLS} element={<BillList />} />
              <Route
                path={AppRoutePaths.BILLS_CREATE}
                element={<BillCreate />}
              />
              <Route path={AppRoutePaths.COMPANY} element={<CompanyList />} />
              <Route
                path={AppRoutePaths.COMPANY_CREATE}
                element={<CompanyCreate />}
              />
              <Route
                path={AppRoutePaths.COMPANY_EDIT}
                element={<CompanyEdit />}
              />
              <Route path={AppRoutePaths.BILLS_EDIT} element={<BillEdit />} />
              <Route path={AppRoutePaths.REPORTS_ART} element={<ArtReport />} />
              <Route
                path={AppRoutePaths.REPORTS_PAYMENTS}
                element={<PaymentReport />}
              />
              <Route
                path={AppRoutePaths.POSTAL_LIST}
                element={<PostalList />}
              />
              <Route
                path={AppRoutePaths.POSTAL_CREATE}
                element={<PostalCreate />}
              />
              <Route
                path={AppRoutePaths.POSTAL_EDIT}
                element={<PostalEdit />}
              />
              <Route
                path={AppRoutePaths.RECEIPT_LIST}
                element={<ReceiptList />}
              />
              <Route
                path={AppRoutePaths.ORDER_FILE_RECEIPTS_APPROVAL}
                element={<OrderFileReceiptsApproval />}
              />
            </>,
          )}
          <Route
            path={AppRoutePaths.ORDER_MATERIALS_DETAILS}
            element={<OrderMaterialDetails />}
          />
        </>
      )}
      <Route path={AppRoutePaths.LOGIN} element={<Login />} />
      <Route path={AppRoutePaths.NOT_FOUND} element={<PageNotFound />} />
      <Route path={AppRoutePaths.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={AppRoutePaths.ORDER_DISPATCH} element={<OrderDispatch />} />
    </Routes>
  );
}
