import { TeamConfig } from './team-config';

export enum TeamType {
  VENDOR = 'vendor',
  TECHNICAL = 'technical',
  ART = 'art',
  PACK = 'pack',
  RECEPTION = 'reception',
  FINANCIAL = 'financial',
  HANDLING = 'handling',
}

export type Team = {
  id: number;
  name: string;
  email: string;
  type: TeamType;
  configs: TeamConfig[];
};
