export enum FileType {
  FILE = 'file',
  RECEIPT = 'receipt',
  LAYOUT = 'layout',
}

export enum FileFinancialApprove {
  PENDING = 'pending',
  REPROVED = 'reproved',
  APPROVED = 'approved',
}

export type OrderFile = {
  order: number;
  file: {
    id: number;
    url: string;
    name: string;
    ext: string;
  };
  type: FileType;
  financialApprove?: FileFinancialApprove;
  approvedBy?: string;
};
