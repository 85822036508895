/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Checkbox, Group, NumberInput, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface DigitalUVFormItemProps {
  form: UseFormReturnType<any>;
}

export function DigitalUVFormItem({ form }: DigitalUVFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        hasVarnish: false,
        hasManyData: false,
        personalizationSides: [],
        hasSample: false,
      },
    });
  }, []);

  return (
    <>
      <Text size="sm" fw="normal">
        Tamanho da gravação
      </Text>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem aplicação de verniz ?"
        color="green"
        name="hasVarnish"
        mb={16}
        {...form.getInputProps('details.hasVarnish')}
      />
      <Checkbox
        label="Tem dados variados ?"
        value="dados variados"
        color="green"
        name="hasManyData"
        mb={16}
        {...form.getInputProps('details.hasManyData')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}
