/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  MultiSelect,
  Select,
  Stepper,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';

import { Page } from '../../components/Page';
import { createTeamsRequest } from '../../data/services/teams';
import { TeamType } from '../../models/team';
import { TeamConfigAlias, TeamConfigType } from '../../models/team-config';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { getServicesDispatcher } from '../../store/services';
import { teamTypeList } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';

type FormType = {
  name: string;
  email: string;
  type: string;
  services: number[];
  hasMultipleServices?: boolean;
  multipleServiceLastStep?: number[];
};

export function TeamCreate() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<any>();
  const { items: serviceItems, loading: serviceLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const form = useForm<FormType>({
    initialValues: {
      name: '',
      email: '',
      type: '',
      services: [],
      hasMultipleServices: false,
      multipleServiceLastStep: [],
    },
  });

  async function handleSubmit(values: typeof form.values) {
    const configs = [];

    if (values?.services?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_FILTER,
        type: TeamConfigType.LIST,
        value: values.services.join(','),
      });
    }

    if (values.hasMultipleServices) {
      configs.push({
        alias: TeamConfigAlias.HAS_MULTIPLE_SERVICES,
        type: TeamConfigType.BOOLEAN,
        value: String(values.hasMultipleServices),
      });
    }

    if (values.hasMultipleServices && values.multipleServiceLastStep?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
        type: TeamConfigType.LIST,
        value: values.multipleServiceLastStep.join(','),
      });
    }

    try {
      setLoading(true);
      await createTeamsRequest({
        email: values.email,
        type: values.type as TeamType,
        name: values.name,
        configs,
      });
      setLoading(false);
      successNotification({
        title: 'Novo time criado!',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.TEAMS);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao criar time!',
        message: 'ops! tente novamente',
      });
    }
  }

  useEffect(() => {
    dispatch(getServicesDispatcher());
  }, []);

  return (
    <Page>
      <Container miw={320}>
        <CustomLoader loading={loading || serviceLoading} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Criar Time
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Time" description="dados do time">
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome do time"
                mb={16}
                type="text"
                name="name"
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email para notificações"
                mb={16}
                type="text"
                name="email"
                {...form.getInputProps('email')}
              />
              <Select
                required
                withAsterisk
                name="type"
                label="Tipo de time"
                placeholder="selecione o tipo de time"
                data={teamTypeList}
                mb={16}
                {...form.getInputProps('type')}
              />
              {form.values.type === TeamType.TECHNICAL && (
                <>
                  <Divider mt={0} mb={14} />
                  <Text size={16} color="dark.4">
                    Configurações
                  </Text>
                  <MultiSelect
                    dropdownPosition="top"
                    maw={320}
                    data={serviceItems.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    label="Serviços relacionados"
                    description="serviços que este time pode ver/produzir"
                    placeholder="selecione os serviços"
                    {...form.getInputProps('services')}
                  />
                  <Switch
                    checked={form.values.hasMultipleServices}
                    color="teal"
                    size="sm"
                    label="atua em multiplos serviços?"
                    mb={8}
                    thumbIcon={
                      form.values.hasMultipleServices ? (
                        <IconCheck size="0.8rem" color="teal" stroke={3} />
                      ) : (
                        <IconX size="0.8rem" color="red" stroke={3} />
                      )
                    }
                    {...form.getInputProps('hasMultipleServices')}
                  />
                  {form.values.hasMultipleServices && (
                    <MultiSelect
                      dropdownPosition="top"
                      maw={320}
                      data={serviceItems.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      label="Serviços de ultima etapa"
                      description="serviços onde este time atua por ultimo"
                      placeholder="selecione os serviços"
                      {...form.getInputProps('multipleServiceLastStep')}
                    />
                  )}
                </>
              )}
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button loading={loading} color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
