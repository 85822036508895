import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  CreateReceiptsData,
  GetReceiptsByOrderResponse,
  GetTotalByOrder,
} from '../../utils/types/data/services/order-receipts';

export async function generateOrderReceiptRequest(
  data: CreateReceiptsData,
): Promise<Blob> {
  const response = await api.post<Blob>(`order-receipts`, data, {
    responseType: 'blob',
  });

  return response.data;
}

export async function getTotalReceiptRequest(
  orderId: number,
): Promise<GetTotalByOrder> {
  const response = await api.get<GetTotalByOrder>(
    `order-receipts/total/${orderId}`,
  );

  return response.data;
}

export async function getReceiptsByOrderRequest(
  orderIds: string,
): Promise<AxiosResponse<GetReceiptsByOrderResponse[]>> {
  const response = await api.get<GetReceiptsByOrderResponse[]>(
    `order-receipts/by-order`,
    {
      params: { orderIds },
    },
  );

  return response;
}
