import { ThemeIcon, NavLink } from '@mantine/core';
import { IconList } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface ActivitiesLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function ActivitiesLink({ navigate, themeColor }: ActivitiesLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Atividades"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="blue" variant="light">
          <IconList size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.ACTIVITIES)}
      />
    </NavLink>
  );
}
