import { AxiosResponse } from 'axios';
import { Postal } from '../../models/postal';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  CreatePostalsRequestData,
  GetPostalOrdersResponse,
  ListPostalsRequestQuery,
  ListPostalsResponse,
  OrderPostal,
  UpdatePostalsRequestData,
} from '../../utils/types/data/services/postals';

export async function createPostalRequest(
  data: CreatePostalsRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>('postals', data);

  return response;
}

export async function showPostalRequest(
  id: number,
): Promise<AxiosResponse<Postal>> {
  const response = await api.get<Postal>(`postals/${id}`);

  return response;
}

export async function getPostalsByOrderRequest(
  orderIds: number[],
): Promise<AxiosResponse<OrderPostal[]>> {
  const response = await api.get<OrderPostal[]>(
    `postals/list/orders?orderIds=${orderIds}`,
  );
  return response;
}

export async function getPostalOrdersRequest(
  postalId: number,
): Promise<AxiosResponse<GetPostalOrdersResponse[]>> {
  const response = await api.get<GetPostalOrdersResponse[]>(
    `postals/orders/${postalId}`,
  );

  return response;
}

export async function listPostalsRequest(
  query: ListPostalsRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<ListPostalsResponse>>> {
  const response = await api.get<PaginatedResponse<ListPostalsResponse>>(
    `postals`,
    { params: query },
  );

  return response;
}

export async function updatePostalRequest(
  id: number,
  data: UpdatePostalsRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`postals/${id}`, data);

  return response;
}

export async function setPostalInvoicedRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`postals/invoice/${id}`);

  return response;
}

export async function validateAddOrderRequest(
  orderId: number,
): Promise<AxiosResponse<any>> {
  const response = await api.get<any>(`postals/validate/${orderId}`);

  return response.data;
}
