import { Button, Card, Flex, Grid, Group, Modal, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { PostalOrderItem } from '../../components/PostalOrderItem';
import {
  useGetPostalOrders,
  useListPostals,
  useSetPostalInvoiced,
} from '../../data/hooks/postals';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { UserRole } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';

import { formatBRL } from '../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import { ListPostalsResponse } from '../../utils/types/data/services/postals';
import { FinancialPostalList } from './financial';
import { VendorPostalList } from './vendor';

export type PageModalState = 'postal-detail' | 'postal-invoice' | null;

export function PostalList() {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigation = useNavigate();
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [selectedPostal, setSelectedPostal] = useState<ListPostalsResponse>();
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const {
    fetch: listPostalsFetcher,
    loading: listPostalsLoader,
    response: listPostalsResponse,
  } = useListPostals();
  const {
    fetch: getPostalOrdersFetcher,
    loading: getPostalOrdersLoader,
    response: getPostalOrdersResponse,
  } = useGetPostalOrders();

  const { fetch: setPostalInvoicedFetcher, loading: setPostalInvoicedLoader } =
    useSetPostalInvoiced();

  const formFilter = useForm({
    initialValues: {
      limit: '',
      page: '',
      addressZipCode: '',
      company: '',
      os: '',
      code: '',
    },
  });

  function handleChangePage(pageNumber: number) {
    currentPage.current = pageNumber;
    getPaginatedPostals();
  }

  function handleChangePageLimit(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getPaginatedPostals();
  }

  async function handleGetPostalDetails(postal: ListPostalsResponse) {
    await getPostalOrdersFetcher({ postalId: postal.id });
    setPageModalVisible('postal-detail');
    setSelectedPostal(postal);
  }

  async function handleSubmit() {
    getPaginatedPostals();
  }

  async function handleClear() {
    formFilter.reset();
    currentPage.current = 1;
    getPaginatedPostals(true);
    currentPageLimit.current = 10;
  }

  async function getPaginatedPostals(isReset = false) {
    let dynamicFields: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        dynamicFields[key] = val;
      }
    });

    if (isReset) {
      dynamicFields = {};
    }

    await listPostalsFetcher({
      query: {
        ...dynamicFields,
        page: currentPage.current,
        limit: currentPageLimit.current,
      },
    });
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function handleSetInvoiced() {
    if (selectedPostal) {
      await setPostalInvoicedFetcher({
        id: selectedPostal.id,
        onSuccess: () => {
          setSelectedPostal(undefined);
          getPaginatedPostals();
          setPageModalVisible(null);
        },
      });
    }
  }

  useEffect(() => {
    getCompanies();
    getPaginatedPostals();
  }, []);

  return (
    <Page title="Listagem de Correio">
      <CustomLoader loading={getPostalOrdersLoader || listPostalsLoader} />
      <Flex direction="column" w="99%">
        {user?.role === UserRole.VENDOR && (
          <VendorPostalList
            companyList={companyList}
            currentPage={currentPage}
            formFilter={formFilter}
            handleChangePage={handleChangePage}
            handleChangePageLimit={handleChangePageLimit}
            handleClear={handleClear}
            handleGetPostalDetails={handleGetPostalDetails}
            handleSubmit={handleSubmit}
            listPostalsResponse={listPostalsResponse}
            navigation={navigation}
          />
        )}
        {user?.role === UserRole.FINANCIAL && (
          <FinancialPostalList
            companyList={companyList}
            currentPage={currentPage}
            formFilter={formFilter}
            handleChangePage={handleChangePage}
            handleChangePageLimit={handleChangePageLimit}
            handleClear={handleClear}
            handleGetPostalDetails={handleGetPostalDetails}
            handleSubmit={handleSubmit}
            listPostalsResponse={listPostalsResponse}
            navigation={navigation}
            setSelectedPostal={setSelectedPostal}
            setPostalInvoicedLoader={setPostalInvoicedLoader}
            setPageModalVisible={setPageModalVisible}
          />
        )}
      </Flex>
      <Modal
        size={660}
        opened={pageModalVisible === 'postal-detail'}
        onClose={() => setPageModalVisible(null)}
        title="Detalhes do Correio"
      >
        <Text fw="bold" color="gray" mb={8} size={12}>
          OSs relacionadas
        </Text>
        {getPostalOrdersResponse?.map((item) => (
          <PostalOrderItem
            item={{
              id: item.orderId,
              os: item.os,
              product: `${item.product}, ${item.productMaterial}, ${item.productColor}`,
              sendQuantity: item.quantity,
              totalQuantity: item.orderQuantity,
              companyId: item.companyId,
            }}
            key={item.orderId}
          />
        ))}
        {!getPostalOrdersResponse?.length && (
          <Card shadow="xs" mb={8}>
            <Text fw="bold" color="gray" size={12}>
              Sem OS relacionada
            </Text>
          </Card>
        )}
        <Grid gutter="xs" columns={4} mb={8}>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Destinatário
              </Text>
              <Text>{selectedPostal?.receiver}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Tipo de envio
              </Text>
              <Text>{selectedPostal?.postal_type}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={4}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Endereço
              </Text>
              <Text>{`${selectedPostal?.address_street} ${selectedPostal?.address_number}, ${selectedPostal?.address_complement} - ${selectedPostal?.address_city}/${selectedPostal?.address_state} (${selectedPostal?.address_zip_code})`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Preço
              </Text>
              <Text>{formatBRL(selectedPostal?.price)}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Preço + Taxa
              </Text>
              <Text>{formatBRL(selectedPostal?.tax_price)}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Preço final
              </Text>
              <Text>{formatBRL(selectedPostal?.total_price)}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Enviado em
              </Text>
              <Text>
                {selectedPostal &&
                  formatLocale(selectedPostal?.created_at, 'DD/MM/YY HH:mm')}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Descrição
              </Text>
              <Text>{selectedPostal?.description}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Criado por
              </Text>
              <Text>{selectedPostal?.user}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'postal-invoice'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Faturar Correio</Text>}
      >
        <Text>
          Deseja faturar a saida de correio N˚: {selectedPostal?.code} ?
        </Text>
        <Group mt={16}>
          <Button
            onClick={() => {
              handleSetInvoiced();
            }}
            color="green"
            type="submit"
          >
            Sim
          </Button>
          <Button
            onClick={() => {
              setSelectedPostal(undefined);
              setPageModalVisible(null);
            }}
            color="red"
            type="submit"
          >
            Não
          </Button>
        </Group>
      </Modal>
    </Page>
  );
}
