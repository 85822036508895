import { Checkbox, Group, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface SilkscreenFormItemProps {
  form: UseFormReturnType<any>;
}

export function SilkscreenEditItem({ form }: SilkscreenFormItemProps) {
  return (
    <>
      <Group grow>
        <NumberInput
          required
          withAsterisk
          label="Cores"
          placeholder="quantidade de cores"
          mb={16}
          min={0}
          name="colorsAmount"
          {...form.getInputProps('details.colorsAmount')}
        />
      </Group>
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        value={form.values.details.personalizationSides}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}
