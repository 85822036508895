import { AxiosResponse } from 'axios';
import { OrderHandling } from '../../models/order-handling';

import { api } from '../../providers/base-api';
import {
  CreateOrderHandlingRequestData,
  FindAllOrderHandlingResponse,
  OrderHandlingClockoutRequestData,
} from '../../utils/types/data/services/order-handling';

export async function createOrderHandlingsRequest(
  orderId: number,
  data: CreateOrderHandlingRequestData,
): Promise<AxiosResponse<OrderHandling>> {
  const response = await api.post<OrderHandling>(
    `order-handlings/${orderId}`,
    data,
  );

  return response;
}

export async function orderHandlingsClockoutRequest(
  orderId: number,
  data: OrderHandlingClockoutRequestData,
): Promise<AxiosResponse<OrderHandling>> {
  const response = await api.put<OrderHandling>(
    `order-handlings/${orderId}`,
    data,
  );

  return response;
}

export async function getLastOrderHandlingsRequest(
  orderId: number,
): Promise<AxiosResponse<OrderHandling>> {
  const response = await api.get<OrderHandling>(
    `order-handlings/last/${orderId}`,
  );

  return response;
}

export async function getOrderHandlingsRequest(
  orderId: number,
): Promise<AxiosResponse<FindAllOrderHandlingResponse[]>> {
  const response = await api.get<FindAllOrderHandlingResponse[]>(
    `order-handlings/${orderId}`,
  );

  return response;
}
