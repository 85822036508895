import {
  Button,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Paper,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconMail } from '@tabler/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { validateResetCodeRequest } from '../../data/services/auth';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  newPasswordDispatcher,
  requestResetCodeDispatcher,
} from '../../store/auth';
import { AppRoutePaths } from '../../utils/enums';

export function ResetPassword() {
  const navigation = useNavigate();
  const dispatch = useDispatch<any>();
  const { loading } = useSelector((state: RootState) => state.auth);
  const [isConfirmCodeVisible, setIsConfirmCodeVisible] = useState<
    'request' | 'confirm' | 'set'
  >('request');
  const [validateLoading, setValidateLoading] = useState(false);
  const [resetCode, setResetCode] = useState('');

  const resetCodeForm = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'e-mail inválido'),
    },
  });

  const confirmForm = useForm({
    initialValues: {
      code: '',
    },
  });

  const newPasswordForm = useForm({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  function toLogin() {
    navigation(AppRoutePaths.LOGIN);
  }

  const handleResetCodeSubmit = ({ email }: typeof resetCodeForm.values) => {
    dispatch(requestResetCodeDispatcher({ email }));
    setIsConfirmCodeVisible('confirm');
    resetCodeForm.reset();
  };

  const handleConfirmCodeSubmit = async ({
    code,
  }: typeof confirmForm.values) => {
    try {
      setValidateLoading(true);
      const confirmResponse = await validateResetCodeRequest(code);
      setValidateLoading(false);
      if (confirmResponse.id) {
        setResetCode(code);
        setIsConfirmCodeVisible('set');
      }
    } catch (error) {
      setValidateLoading(false);
      errorNotification({
        title: 'Código incorreto!',
        message: 'solicite um novo.',
      });
    }
  };

  const handleSetNewPasswordSubmit = async (
    values: typeof newPasswordForm.values,
  ) => {
    dispatch(newPasswordDispatcher(resetCode, values));
    confirmForm.reset();
    setResetCode('');
  };

  return (
    <Page>
      <CustomLoader loading={validateLoading} />
      <CustomLoader loading={loading} />
      <Container p={0}>
        <Paper maw={500} mt={75} radius={8} p={32} shadow="md">
          {isConfirmCodeVisible === 'request' && (
            <>
              <Title size={24}>Alterar Senha</Title>
              <Text mb={16}>
                caso tenha esquecido sua senha, você pode soliciar a recuperação
                por aqui
              </Text>
              <form
                onSubmit={resetCodeForm.onSubmit((values) =>
                  handleResetCodeSubmit(values),
                )}
              >
                <LoadingOverlay
                  visible={false}
                  overlayBlur={1}
                  loaderProps={{ color: 'orange' }}
                />
                <TextInput
                  withAsterisk
                  required
                  label="Email"
                  placeholder="seu@email.com.br"
                  {...resetCodeForm.getInputProps('email')}
                  rightSection={
                    <ThemeIcon color="orange" variant="light">
                      <IconMail size={16} />
                    </ThemeIcon>
                  }
                  mb={16}
                />
                <Group position="right" mt="md">
                  <Button color="ltpBlue.9" type="submit">
                    Solicitar
                  </Button>
                </Group>
              </form>
            </>
          )}
          {isConfirmCodeVisible === 'confirm' && (
            <>
              <Title size={24}>Código de Confirmação</Title>
              <Text mb={16}>
                digite o código para confirmar a alteração de senha
              </Text>
              <form
                onSubmit={confirmForm.onSubmit((values) =>
                  handleConfirmCodeSubmit(values),
                )}
              >
                <LoadingOverlay
                  visible={false}
                  overlayBlur={1}
                  loaderProps={{ color: 'orange' }}
                />
                <Flex w={240}>
                  <TextInput
                    withAsterisk
                    required
                    label="Código"
                    placeholder="Ex: 123654"
                    type="number"
                    mb={16}
                    {...confirmForm.getInputProps('code')}
                  />
                </Flex>
                <Group position="right" mt="md">
                  <Button color="ltpBlue.9" type="submit">
                    Confirmar
                  </Button>
                </Group>
              </form>
            </>
          )}
          {isConfirmCodeVisible === 'set' && (
            <>
              <Title size={24}>Nova Senha</Title>
              <Text mb={16}>agora você pode definir sua nova senha</Text>
              <form
                onSubmit={newPasswordForm.onSubmit((values) =>
                  handleSetNewPasswordSubmit(values),
                )}
              >
                <LoadingOverlay
                  visible={false}
                  overlayBlur={1}
                  loaderProps={{ color: 'orange' }}
                />
                <TextInput
                  withAsterisk
                  required
                  label="Senha"
                  placeholder="nova senha"
                  type="password"
                  mb={16}
                  {...newPasswordForm.getInputProps('password')}
                />
                <TextInput
                  withAsterisk
                  required
                  label="Confirmar Senha"
                  placeholder="confirmar nova senha"
                  type="password"
                  mb={16}
                  {...newPasswordForm.getInputProps('passwordConfirmation')}
                />
                <Group position="right" mt="md">
                  <Button color="ltpBlue.9" type="submit">
                    Confirmar
                  </Button>
                </Group>
              </form>
            </>
          )}
        </Paper>
        <Group mt={16} grow>
          <Text
            style={{ cursor: 'pointer' }}
            align="center"
            color="dark.4"
            variant="link"
            onClick={toLogin}
          >
            voltar para login
          </Text>
        </Group>
      </Container>
    </Page>
  );
}
