import { api } from '../../providers/base-api';
import { normalizeText } from '../../utils/helpers';

export type UploadFileData = {
  files: File[];
};

export async function uploadMultipleFilesRequest(
  data: UploadFileData,
): Promise<number[]> {
  const formData = new FormData();

  data.files.forEach((item) => {
    formData.append(`files`, item, normalizeText(item.name));
  });

  const response = await api.post<number[]>('files/multiple', formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });

  return response.data;
}
