import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseCancelPaymentProps,
  UseGenerateNfToPaymentProps,
  UseGetOrderFilesReceiptToApproveProps,
  UseGetOrdersByPaymentProps,
  UseOrderFileReceiptApprovalProps,
  UseSetOrderPaymentPaidProps,
} from '../../../utils/types/data/hooks/financial';
import {
  GetFincialNFProviderResponse,
  GetOrderFilesToApproveResponse,
  GetOrdersByPaymentResponseData,
} from '../../../utils/types/data/services/financial';
import {
  cancelPaymentRequest,
  generateNFSeToPaymentRequest,
  getNfProvidersRequest,
  getOrderFilesReceiptToApproveRequest,
  getOrdersByPaymentRequest,
  orderFilesReceiptApprovalRequest,
  setOrderPaymentPaidRequest,
} from '../../services/financial';

export function useCancelPayment() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ paymentId, onSuccess }: UseCancelPaymentProps) =>
    fetchData({
      fetcher: () => cancelPaymentRequest(paymentId),
      onError: () => {
        errorNotification({
          title: 'Erro ao cancelar faturamento.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useGenerateNfToPayment() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ paymentId, data, onSuccess }: UseGenerateNfToPaymentProps) =>
    fetchData({
      fetcher: () => generateNFSeToPaymentRequest(paymentId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao gerar nota fiscal.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useGetOrdersByPayment() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<GetOrdersByPaymentResponseData[]>();

  const fetch = ({ paymentId, onSuccess }: UseGetOrdersByPaymentProps) =>
    fetchData({
      fetcher: () => getOrdersByPaymentRequest(paymentId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar pedidos relacionados.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useGetNfProviders() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<GetFincialNFProviderResponse[]>();

  const fetch = () =>
    fetchData({
      fetcher: () => getNfProvidersRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar prestadores.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useSetOrderPaymentPaid() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch();

  const fetch = ({ id, onSuccess }: UseSetOrderPaymentPaidProps) =>
    fetchData({
      fetcher: () => setOrderPaymentPaidRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro setar como pago.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useGetOrderFileReceiptsToApproval() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<PaginatedResponse<GetOrderFilesToApproveResponse>>();

  const fetch = ({ query }: UseGetOrderFilesReceiptToApproveProps) =>
    fetchData({
      fetcher: () => getOrderFilesReceiptToApproveRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro buscar comprovantes.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useSetOrderFileReceiptApproval() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, fileId }: UseOrderFileReceiptApprovalProps) =>
    fetchData({
      fetcher: () => orderFilesReceiptApprovalRequest(fileId, data),
      onError: () => {
        errorNotification({
          title: 'Erro de aprovação do comprovante.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}
