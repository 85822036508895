import { Button, Card, Flex, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons';

type PostalOrderItem = {
  id: number;
  os: string;
  product: string;
  totalQuantity: number;
  sendQuantity: number;
  companyId: number;
};

type PostalOrderItemProps = {
  item: PostalOrderItem;
  onDelete?: (id: number) => void;
};

export function PostalOrderItem({ item, onDelete }: PostalOrderItemProps) {
  return (
    <Card shadow="xs" mb={8}>
      <Flex justify="space-between">
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            OS
          </Text>
          <Text>{item.os}</Text>
        </Flex>
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            Produto
          </Text>
          <Text>{item.product}</Text>
        </Flex>
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            Quantidade total
          </Text>
          <Text>{item.totalQuantity}</Text>
        </Flex>
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            Quantidade enviada
          </Text>
          <Text>{item.sendQuantity}</Text>
        </Flex>
        {onDelete && (
          <Flex direction="column">
            <Button
              color="red"
              variant="light"
              type="button"
              onClick={() => onDelete(item.id)}
            >
              <IconTrash size={20} />
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
