import { OrderLog } from '../../models/order-log';
import { api } from '../../providers/base-api';

export type OrderLogResponse = OrderLog[];

export async function getOrderLogRequest(
  orderId: number,
): Promise<OrderLogResponse> {
  const response = await api.get<OrderLog[]>(`order-logs/${orderId}`);

  return response.data;
}
