/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Menu,
  Button,
  Flex,
  Badge,
  Paper,
  TextInput,
  Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCirclePlus, IconDotsVertical, IconEdit } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';

import { rootNavigate } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import {
  getFilterCompaniesListRequest,
  getFilterTeamsListRequest,
} from '../../data/services/filters';
import { getUsersListResquest } from '../../data/services/users';
import { UserPopulated } from '../../models/user';
import { errorNotification } from '../../providers/mantine-notifications';
import { userRoleHumanized, userTypeList } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';
import { PageMeta } from '../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
} from '../../utils/types/data/services/filters';

const formFilterInitialState = {
  role: '',
  username: '',
  company: '',
  team: '',
};

export function UserList() {
  const [pageLoading, setPageLoading] = useState(false);
  const [userList, setUserList] = useState<UserPopulated[]>([]);
  const [userListMeta, setUserListMeta] = useState<PageMeta>();
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const currentPage = useRef(1);

  const formFilter = useForm({
    initialValues: formFilterInitialState,
  });

  function handleClearFilter() {
    currentPage.current = 1;
    formFilter.reset();
    getUsersPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getUsersPaginated();
  }

  async function handleSubmit() {
    currentPage.current = 1;
    getUsersPaginated();
  }

  async function getUsersPaginated(reset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    try {
      setPageLoading(true);
      const response = await getUsersListResquest({
        page: currentPage.current,
        limit: 10,
        ...(reset ? {} : validatedValues),
      });
      setUserList(response.items);
      setUserListMeta(response.meta);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar empresas',
        message: 'tente novamente',
      });
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }
  async function getVendorTeam() {
    try {
      setPageLoading(true);
      const response = await getFilterTeamsListRequest({});
      setTeamList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  useEffect(() => {
    getUsersPaginated();
    getCompanies();
    getVendorTeam();
  }, []);

  return (
    <Page title="Usuários">
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => rootNavigate(AppRoutePaths.USERS_CREATE)}
          >
            Novo
          </Button>
        </Flex>
        <form onSubmit={formFilter.onSubmit(handleSubmit)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex>
              <TextInput
                label="Nome"
                placeholder="nome de usuário"
                mb={16}
                mr={8}
                type="text"
                name="username"
                {...formFilter.getInputProps('username')}
              />
              <Select
                clearable
                searchable
                name="role"
                label="Tipo"
                placeholder="selecione o tipo"
                data={userTypeList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('role')}
              />
              <Select
                clearable
                searchable
                name="team"
                label="Time"
                placeholder="selecione um time"
                data={teamList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('team')}
              />
              <Select
                clearable
                searchable
                name="company"
                label="Cliente"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('company')}
              />
            </Flex>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Paper>
        </form>
        <DataTable
          recordsPerPage={userListMeta?.itemsPerPage ?? 10}
          totalRecords={userListMeta?.totalItems}
          page={currentPage.current}
          onPageChange={handlePaginate}
          fetching={pageLoading}
          minHeight={500}
          noRecordsText="Sem usuários"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={userList}
          columns={[
            {
              accessor: 'username',
              title: 'Nome',
            },
            {
              accessor: 'email',
              title: 'Email',
            },
            {
              accessor: 'role',
              title: 'Tipo',
              render: ({ role }) => <Badge>{userRoleHumanized[role]}</Badge>,
            },
            {
              accessor: 'company.name',
              title: 'Empresa',
            },
            {
              accessor: 'team.name',
              title: 'Time',
            },
            {
              accessor: '',
              title: '',
              render: ({ id }) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => rootNavigate(`users/edit/${id}`)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
    </Page>
  );
}
