import {
  Accordion,
  Badge,
  Flex,
  Grid,
  Indicator,
  Table,
  Text,
} from '@mantine/core';
import { IconRefreshDot, IconTruckOff } from '@tabler/icons';
import { Order } from '../../models/order';
import { priceTypeHumanized } from '../../utils/constants/order';
import { OrderPricesData, getOrderPrices } from '../../utils/helpers';

interface NfOrderItemProps {
  order: Order[];
  postalOrderIds?: number[];
}

function PriceList({ prices }: { prices: OrderPricesData }) {
  return (
    <Flex direction="column">
      <Text fw="bold">Valores do Pedido</Text>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          {prices &&
            Object.entries(prices).map(
              ([key, value]) =>
                priceTypeHumanized[key] && (
                  <tr key={`${key}-${value}`}>
                    <td>{priceTypeHumanized[key]}</td>
                    <td>{value ?? 'R$0,00'}</td>
                  </tr>
                ),
            )}
          <tr>
            <td>Total</td>
            <td>{prices.totalPrice ?? '--'}</td>
          </tr>
        </tbody>
      </Table>
    </Flex>
  );
}

const Item = ({ order, postalOrderIds = [] }: NfOrderItemProps) => {
  return (
    <>
      {order?.map((item) => (
        <Accordion.Item
          style={{
            borderColor:
              item.needsPostal && !postalOrderIds.includes(item.id)
                ? '#FF000070'
                : '',
          }}
          value={`acc-${item.id}`}
          key={`acc-${item.id}`}
        >
          <Accordion.Control>
            <Indicator
              mt={8}
              disabled={!item.financialNote}
              inline
              size={12}
              color="red"
              withBorder
            >
              <Flex align="center" justify="center">
                {item.rework && (
                  <Flex mr={10}>
                    <IconRefreshDot />
                  </Flex>
                )}
                <Text fw="bold">Pedido OS: </Text>
                <Text ml={4} mr={4}>
                  {item.os}
                </Text>
                {item.needsPostal && !postalOrderIds.includes(item.id) && (
                  <Badge color="red" variant="light" display="flex">
                    <Flex align="center" justify="center">
                      <Text fw="bold">correio pendente</Text>
                      <IconTruckOff />
                    </Flex>
                  </Badge>
                )}
              </Flex>
            </Indicator>
          </Accordion.Control>
          <Accordion.Panel>
            <Grid gutter="xs" columns={2}>
              <Grid.Col mt={8} mb={8} span={2}>
                <Text fw="bold" color="gray">
                  Info do pedido
                </Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">OS</Text>
                  <Text color="gray">{item?.os ?? '--'}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Serviço</Text>
                  <Text color="gray">{item?.service.name ?? '--'}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Quantidade</Text>
                  <Text color="gray">{item?.quantity ?? '--'}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex direction="column">
                  <Text fw="bold">Produto</Text>
                  <Text color="gray">
                    {`${item?.product}, ${item?.productColor}, ${item?.productMaterial}` ??
                      '--'}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex direction="column">
                  <Text fw="bold">Observação fiscal</Text>
                  <Text color="gray">{item.financialNote ?? '--'}</Text>
                </Flex>
              </Grid.Col>
            </Grid>
            <PriceList prices={getOrderPrices(item)} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </>
  );
};

Item.PriceList = PriceList;

export const NfOrderItem = Item;
