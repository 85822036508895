/* eslint-disable unused-imports/no-unused-vars */
import { useForm } from '@mantine/form';

import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  TextInput,
  Title,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { useShowOption, useUpdateOption } from '../../data/hooks/options';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { successNotification } from '../../providers/mantine-notifications';

export type UpdateOptionFormType = {
  name: string;
  value: string;
  type: OptionTypes;
  subtype: OptionSubTypes;
};

export function EditOption() {
  const { id } = useParams();

  const { fetch: updateOptionFetcher, loading: updateOptionLoad } =
    useUpdateOption();
  const {
    fetch: showOptionFetcher,
    reponseData: showEditResponse,
    loading: showOptionLoad,
  } = useShowOption();

  const form = useForm<UpdateOptionFormType>({
    initialValues: {
      name: '',
      value: '',
      type: OptionTypes.PRODUCTION,
      subtype: OptionSubTypes.PRODUCTION_MACHINE,
    },
  });

  async function handleSubmit() {
    updateOptionFetcher({
      id: Number(id),
      data: {
        ...form.values,
      },
      onSuccess: () => {
        rootNavigateGoBack();
        successNotification({
          title: 'Tudo certo!',
          message: 'máquina editada com sucesso ✅',
        });
      },
    });
  }

  useEffect(() => {
    showOptionFetcher({ id: Number(id) });
  }, [id]);

  useEffect(() => {
    if (showEditResponse) {
      form.setValues({
        name: showEditResponse.name,
        value: showEditResponse.value,
        type: showEditResponse.type,
        subtype: showEditResponse.subtype,
      });
    }
  }, [showEditResponse]);

  return (
    <Page>
      <CustomLoader loading={updateOptionLoad || showOptionLoad} />

      <Container w={400}>
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Editar Máquina
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit(() => handleSubmit())}>
          <TextInput
            withAsterisk
            required
            label="Nome"
            placeholder="nome da opção"
            mb={16}
            type="text"
            name="name"
            maxLength={255}
            {...form.getInputProps('name')}
          />
          <TextInput
            withAsterisk
            required
            label="Descrição"
            placeholder="descrição da máquina"
            mb={16}
            type="text"
            name="value"
            maxLength={255}
            {...form.getInputProps('value')}
          />
          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
