import { AxiosResponse } from 'axios';
import { Option } from '../../models/option';
import { api } from '../../providers/base-api';
import {
  CreateOptionRequestData,
  ListOptionRequestQuery,
  UpdateOptionRequestData,
} from '../../utils/types/data/services/options';

export async function createOptionRequest(
  data: CreateOptionRequestData,
): Promise<AxiosResponse<Option>> {
  const response = await api.post<Option>(`options`, data);

  return response;
}

export async function updateOptionRequest(
  id: number,
  data: UpdateOptionRequestData,
): Promise<AxiosResponse<Option>> {
  const response = await api.put<Option>(`options/${id}`, data);

  return response;
}

export async function showOptionRequest(
  id: number,
): Promise<AxiosResponse<Option>> {
  const response = await api.get<Option>(`options/${id}`);

  return response;
}

export async function listOptionsRequest(
  query?: ListOptionRequestQuery,
): Promise<AxiosResponse<Option[]>> {
  const response = await api.get<Option[]>(`options`, { params: query });

  return response;
}

export async function deleteOptionRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`options/${id}`);

  return response;
}
