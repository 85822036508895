import {
  Button,
  Flex,
  Group,
  Modal,
  Paper,
  Select,
  TextInput,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { CustomLoader } from '../../components/CustomLoader';
import { FilePreview } from '../../components/FilePreview';
import { Page } from '../../components/Page';
import {
  useGetOrderFileReceiptsToApproval,
  useSetOrderFileReceiptApproval,
} from '../../data/hooks/financial';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { FileFinancialApprove } from '../../models/order-file';
import { errorNotification } from '../../providers/mantine-notifications';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import { GetOrderFilesToApproveResponse } from '../../utils/types/data/services/financial';

export function OrderFileReceiptsApproval() {
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [pageModal, setPageModal] = useState(false);
  const [selectedReceipt, setSelectedReceipt] =
    useState<GetOrderFilesToApproveResponse>();
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const {
    fetch: getOrderFileReceiptsToApproveFetcher,
    reponse: getOrderFileReceiptsToApproveData,
    loading: getOrderFileReceiptsToApproveLoader,
  } = useGetOrderFileReceiptsToApproval();
  const {
    fetch: setOrderFileApprovalFetcher,
    loading: setOrderFileApprovalLoader,
  } = useSetOrderFileReceiptApproval();
  const filterForm = useForm({
    initialValues: {},
  });

  function handleChangePage(pageNumber: number) {
    currentPage.current = pageNumber;
    getReceipts();
  }

  function handleChangePageLimit(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getReceipts();
  }

  function handleClear() {
    filterForm.reset();
    getReceipts(true);
  }

  function handleSubmit() {
    getReceipts();
  }

  function handleSelecReceiptToApprove(
    receipt: GetOrderFilesToApproveResponse,
  ) {
    setSelectedReceipt(receipt);
    setPageModal(true);
  }

  async function getReceipts(isReset = false) {
    let dynamicFields: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        dynamicFields[key] = val;
      }
    });

    if (isReset) {
      dynamicFields = {};
    }

    await getOrderFileReceiptsToApproveFetcher({
      query: {
        page: currentPage.current,
        limit: currentPageLimit.current,
        ...dynamicFields,
      },
    });
  }

  async function approveReceipt(fileId: number, approval: boolean) {
    await setOrderFileApprovalFetcher({
      fileId,
      data: {
        approval: approval
          ? FileFinancialApprove.APPROVED
          : FileFinancialApprove.REPROVED,
      },
    });
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  useEffect(() => {
    getReceipts();
    getCompanies();
  }, []);

  return (
    <Page title="Listagem de Comprovantes">
      <CustomLoader
        loading={
          getOrderFileReceiptsToApproveLoader || setOrderFileApprovalLoader
        }
      />
      <Flex direction="column" w="99%">
        <form onSubmit={filterForm.onSubmit(handleSubmit)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="OS"
                placeholder="digite a os"
                mb={16}
                mr={8}
                type="text"
                name="code"
                {...filterForm.getInputProps('os')}
              />
              <Select
                clearable
                searchable
                name="company"
                label="Cliente"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...filterForm.getInputProps('company')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={() => handleClear()}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>
        <DataTable
          minHeight={500}
          noRecordsText="Sem comprovantes"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={currentPage.current}
          totalRecords={getOrderFileReceiptsToApproveData?.meta?.totalItems}
          recordsPerPage={10}
          onPageChange={(page) => handleChangePage(page)}
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 100, 200]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          onRowClick={(record) => handleSelecReceiptToApprove(record)}
          records={getOrderFileReceiptsToApproveData?.items}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'companyName',
              title: 'Cliente',
            },
            {
              accessor: 'fileName',
              title: 'Nome anexo',
            },
          ]}
        />
      </Flex>
      <Modal
        size={500}
        opened={pageModal}
        onClose={() => setPageModal(false)}
        title={<Text fw="bold">Confirmação de comprovante</Text>}
      >
        <Text>Este pagamento foi comprovado ?</Text>
        {selectedReceipt && (
          <FilePreview
            file={{
              ext: selectedReceipt?.fileName.split('.')[1],
              id: selectedReceipt?.fileId,
              name: selectedReceipt?.fileName,
              url: selectedReceipt?.fileUrl,
              type: '',
            }}
            fileSizes={{
              height: 200,
              width: 200,
            }}
          />
        )}
        <Group mt={16}>
          <Button
            onClick={() => {
              if (!selectedReceipt) return;

              approveReceipt(selectedReceipt?.fileId, true);
              setSelectedReceipt(undefined);
              setPageModal(false);
              getReceipts();
            }}
            color="green"
            type="submit"
          >
            Sim
          </Button>
          <Button
            onClick={() => {
              if (!selectedReceipt) return;

              approveReceipt(selectedReceipt?.fileId, false);
              setSelectedReceipt(undefined);
              setPageModal(false);
              getReceipts();
            }}
            color="red"
            type="submit"
          >
            Não
          </Button>
        </Group>
      </Modal>
    </Page>
  );
}
