/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';

interface CorteLaserFormItemProps {
  form: UseFormReturnType<any>;
}

export function CorteLaserFormItem({ form }: CorteLaserFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        width: '',
        height: '',
        thickness: '',
        hasSample: false,
      },
    });
  }, []);

  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Group grow>
        <NumberInput
          required
          withAsterisk
          label="Espessura do material"
          placeholder="em mm*"
          mb={16}
          min={0}
          name="thickness"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.thickness')}
        />
      </Group>
    </>
  );
}
