import {
  Badge,
  Button,
  Flex,
  Group,
  Modal,
  Paper,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { ActivityLayoutApproveFormType, FilterLayoutsFormType } from '..';
import { ActivityLayoutItem } from '../../../components/ActivityLayout';
import { getFilterCompaniesListRequest } from '../../../data/services/filters';
import {
  ActivityLayout,
  ActivityLayoutStatus,
} from '../../../models/activity-layout';
import { ActivityLayoutFile } from '../../../models/activity-layout-file';
import { formatLocale } from '../../../providers/dayjs-plugins';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  activityLayoutStatusColor,
  activityLayoutStatusHumanized,
  activityLayoutStatusList,
} from '../../../utils/constants/activity-layout';
import { PaginatedResponse } from '../../../utils/types';
import { ActivityLayoutsToApproveResponse } from '../../../utils/types/data/services/activity-layouts';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';

interface VendorLayoutListProps {
  handleSelectLayout(item: ActivityLayoutsToApproveResponse): void;
  getAtivityLayoutToApproveData:
    | PaginatedResponse<ActivityLayoutsToApproveResponse>
    | undefined;
  handlePaginate(newPage: number): void;
  page: React.MutableRefObject<number>;
  selectedLayout: ActivityLayoutsToApproveResponse | undefined;
  setSelectedLayout: React.Dispatch<
    React.SetStateAction<ActivityLayoutsToApproveResponse | undefined>
  >;
  approveActivityForm: UseFormReturnType<
    ActivityLayoutApproveFormType,
    (values: ActivityLayoutApproveFormType) => ActivityLayoutApproveFormType
  >;
  getActivityLayoutFilesData: ActivityLayoutFile[] | undefined;
  handleApproveLayout(values: ActivityLayoutApproveFormType): Promise<void>;
  handleRequestClientApproval(): Promise<void>;
  pageModal: boolean;
  setPageModal: React.Dispatch<React.SetStateAction<boolean>>;
  filterLayoutForm: UseFormReturnType<FilterLayoutsFormType>;
  handleFilter(): void;
  handleClear(): void;
}

export function VendorLayoutList({
  handleSelectLayout,
  getAtivityLayoutToApproveData,
  handlePaginate,
  page,
  selectedLayout,
  approveActivityForm,
  getActivityLayoutFilesData,
  handleApproveLayout,
  handleRequestClientApproval,
  pageModal,
  setPageModal,
  filterLayoutForm,
  handleClear,
  handleFilter,
}: VendorLayoutListProps) {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <Flex direction="column" w="99%">
        <form onSubmit={filterLayoutForm.onSubmit(handleFilter)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...filterLayoutForm.getInputProps('os')}
              />
              <Select
                clearable
                searchable
                name="company"
                label="Cliente"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...filterLayoutForm.getInputProps('company')}
              />
              <Select
                clearable
                searchable
                name="status"
                label="Status"
                placeholder="selecione o status"
                data={activityLayoutStatusList}
                mb={16}
                mr={8}
                {...filterLayoutForm.getInputProps('status')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClear}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>
        <DataTable
          onRowClick={(record) => handleSelectLayout(record)}
          minHeight={500}
          noRecordsText="Sem layouts"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={page.current}
          totalRecords={getAtivityLayoutToApproveData?.meta.totalItems}
          recordsPerPage={10}
          onPageChange={handlePaginate}
          records={getAtivityLayoutToApproveData?.items}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'teamName',
              title: 'Time',
            },
            {
              accessor: 'companyName',
              title: 'Cliente',
            },
            {
              accessor: 'productGroup',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'engravingText',
              title: 'Logo',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={activityLayoutStatusColor[status]}>
                  {activityLayoutStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            },
            {
              accessor: 'approval',
              title: 'Atualizado em',
              render: ({ approval }) =>
                approval && formatLocale(approval, 'DD/MM/YY'),
            },
          ]}
        />
      </Flex>
      <Modal
        size={520}
        opened={pageModal}
        onClose={() => setPageModal(false)}
        title="Avaliar Layout"
      >
        {selectedLayout && (
          <>
            <ActivityLayoutItem
              activityLayout={selectedLayout as ActivityLayout}
              files={getActivityLayoutFilesData}
            />
            {selectedLayout.status !== ActivityLayoutStatus.APPROVED &&
              selectedLayout.status !== ActivityLayoutStatus.REPROVED && (
                <form
                  onSubmit={approveActivityForm.onSubmit(handleApproveLayout)}
                >
                  <Flex direction="column">
                    <Textarea
                      required
                      withAsterisk
                      maxLength={255}
                      mt={16}
                      label="Observações"
                      placeholder="escreva aqui qualquer observação de aprovação/reprovação..."
                      name="vendorNote"
                      {...approveActivityForm.getInputProps('vendorNote')}
                    />
                    <Group position="right" mt={8}>
                      <Button
                        disabled={
                          selectedLayout.status ===
                          ActivityLayoutStatus.CLIENT_APPROVE
                        }
                        w={150}
                        variant="light"
                        type="button"
                        color="yellow"
                        onClick={handleRequestClientApproval}
                      >
                        Enviar p/ Cliente
                      </Button>
                      <Button
                        w={100}
                        variant="light"
                        type="submit"
                        onClick={() => {
                          approveActivityForm.setFieldValue(
                            'isApproved',
                            false,
                          );
                        }}
                        color="red"
                      >
                        Reprovar
                      </Button>
                      <Button
                        w={100}
                        variant="light"
                        type="submit"
                        color="green"
                        onClick={() => {
                          approveActivityForm.setFieldValue('isApproved', true);
                        }}
                      >
                        Aprovar
                      </Button>
                    </Group>
                  </Flex>
                </form>
              )}
          </>
        )}
      </Modal>
    </>
  );
}
