import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { UseRemoveOrderFileProps } from '../../../utils/types/data/hooks/order-files';
import { removeOrderFileRequest } from '../../services/order-files';

export function useRemoveOrderFile() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ fileId, onSuccess }: UseRemoveOrderFileProps) =>
    fetchData({
      fetcher: () => removeOrderFileRequest(fileId),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover arquivo.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
