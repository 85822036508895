import {
  Button,
  Flex,
  Group,
  Menu,
  Paper,
  Select,
  TextInput,
  Badge,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconCertificate,
  IconDotsVertical,
  IconEdit,
  IconEye,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { NavigateFunction } from 'react-router-dom';
import { PageModalState } from '..';
import { CustomLoader } from '../../../components/CustomLoader';
import { formatLocale } from '../../../providers/dayjs-plugins';
import {
  postalStatusColor,
  postalStatusHumanized,
} from '../../../utils/constants/postal';
import { AppRoutePaths } from '../../../utils/enums';
import { formatBRL } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';
import { ListPostalsResponse } from '../../../utils/types/data/services/postals';

interface FinancialPostalListProps {
  navigation: NavigateFunction;
  formFilter: UseFormReturnType<any>;
  handleSubmit(): Promise<void>;
  handleClear(): Promise<void>;
  companyList: GetFilterCompaniesListResponse[];
  currentPage: React.MutableRefObject<number>;
  listPostalsResponse: PaginatedResponse<ListPostalsResponse> | undefined;
  handleChangePage(pageNumber: number): void;
  handleChangePageLimit(pageLimitNumber: number): void;
  handleGetPostalDetails(postal: ListPostalsResponse): Promise<void>;
  setSelectedPostal: React.Dispatch<
    React.SetStateAction<ListPostalsResponse | undefined>
  >;
  setPostalInvoicedLoader: boolean;
  setPageModalVisible: React.Dispatch<React.SetStateAction<PageModalState>>;
}

export function FinancialPostalList({
  navigation,
  formFilter,
  handleSubmit,
  handleClear,
  companyList,
  currentPage,
  listPostalsResponse,
  handleChangePage,
  handleChangePageLimit,
  handleGetPostalDetails,
  setSelectedPostal,
  setPostalInvoicedLoader,
  setPageModalVisible,
}: FinancialPostalListProps) {
  function setSelectedToInvoice(postal: ListPostalsResponse) {
    setPageModalVisible('postal-invoice');
    setSelectedPostal(postal);
  }

  return (
    <>
      <CustomLoader loading={setPostalInvoicedLoader} />
      <form onSubmit={formFilter.onSubmit(handleSubmit)}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="Código"
              placeholder="digite o código"
              mb={16}
              mr={8}
              type="text"
              name="code"
              {...formFilter.getInputProps('code')}
            />
            <TextInput
              label="CEP"
              placeholder="digite o CEP"
              mb={16}
              mr={8}
              type="text"
              name="addressZipCode"
              {...formFilter.getInputProps('addressZipCode')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <Select
              clearable
              searchable
              name="company"
              label="Cliente"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={() => handleClear()}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Group>
        </Paper>
      </form>
      <DataTable
        minHeight={500}
        noRecordsText="Sem correio"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={listPostalsResponse?.meta?.totalItems}
        recordsPerPage={10}
        onPageChange={(page) => handleChangePage(page)}
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[50, 100, 200]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        records={listPostalsResponse?.items}
        columns={[
          {
            accessor: 'code',
            title: 'Código',
          },
          {
            accessor: 'receiver',
            title: 'Enviado para',
          },
          {
            accessor: 'address_zip_code',
            title: 'CEP',
          },
          {
            accessor: 'address',
            title: 'Endereço',
            render: ({
              address_street,
              address_number,
              address_complement,
              address_city,
              address_state,
            }) =>
              `${address_street}, ${address_number}, ${address_complement} - ${address_city} ${address_state}`,
          },
          {
            accessor: 'postal_type',
            title: 'Tipo de envio',
          },
          {
            accessor: 'total_price',
            title: 'Preço final',
            render: ({ total_price }) => formatBRL(total_price),
          },
          {
            accessor: 'user',
            title: 'Enviado por',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={postalStatusColor[status]}>
                {postalStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'createdAt',
            title: 'Criado em',
            render: ({ created_at }) =>
              created_at && formatLocale(created_at, 'DD/MM/YY'),
          },
          {
            accessor: 'invoicedAt',
            title: 'Faturado em',
            render: ({ invoiced_at }) =>
              invoiced_at && formatLocale(invoiced_at, 'DD/MM/YY'),
          },
          {
            accessor: 'menu',
            title: '',
            render: (postal) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() => handleGetPostalDetails(postal)}
                    icon={<IconEye size={14} />}
                  >
                    Detalhes
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      navigation(
                        AppRoutePaths.POSTAL_EDIT.replace(
                          ':id',
                          String(postal.id),
                        ),
                      )
                    }
                    icon={<IconEdit size={14} />}
                  >
                    Editar
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setSelectedToInvoice(postal)}
                    icon={<IconCertificate size={14} />}
                  >
                    Faturar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </>
  );
}
