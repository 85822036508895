import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { paginatedReceiptsRequest } from '../data/services/receipt';
import { errorNotification } from '../providers/mantine-notifications';
import { PageMeta } from '../utils/types';
import {
  ReceiptPaginatedParams,
  ReceiptPaginatedResponse,
  ReceiptResponse,
} from '../utils/types/data/services/receipts';

interface InitialStateProps {
  items: ReceiptResponse[];
  meta: PageMeta | null;
  loading: boolean;
}

const initialState: InitialStateProps = {
  items: [],
  meta: null,
  loading: false,
};

export const receiptsSlice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    setReceipts: (state, action: PayloadAction<ReceiptPaginatedResponse>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    initLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
  },
});

export const receiptsActions = receiptsSlice.actions;
export default receiptsSlice.reducer;

export const getReceiptsPaginatedDispatcher =
  (params: ReceiptPaginatedParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(receiptsActions.initLoading());
      const response = await paginatedReceiptsRequest(params);
      dispatch(receiptsActions.endLoading());
      dispatch(receiptsActions.setReceipts(response));
    } catch (error) {
      dispatch(receiptsActions.endLoading());
      errorNotification({
        title: 'Erro ao buscar recibos!',
        message: 'tente novamente 🤞',
      });
    }
  };
