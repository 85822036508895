import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseDeleteOrderMaterialFileProps,
  UseDeleteOrderMaterialProps,
  UseGetOrderMaterialFilesProps,
} from '../../../utils/types/data/hooks/order-materials';
import { OrderMaterialFileResponse } from '../../../utils/types/data/services/order-material-files';
import {
  deleteOrderMaterialFileRequest,
  deleteOrderMaterialRequest,
  getOrderMaterialFilesRequest,
} from '../../services/order-material';

export function useDeleteOrderMaterial() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseDeleteOrderMaterialProps) =>
    fetchData({
      fetcher: () => deleteOrderMaterialRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao excluir AR.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrderMaterialFiles() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderMaterialFileResponse[]>();

  const fetch = ({ id, onSuccess }: UseGetOrderMaterialFilesProps) =>
    fetchData({
      fetcher: () => getOrderMaterialFilesRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useDeleteOrderMaterialFile() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ fileId, onSuccess }: UseDeleteOrderMaterialFileProps) =>
    fetchData({
      fetcher: () => deleteOrderMaterialFileRequest(fileId),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover arquivo.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
