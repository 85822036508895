import { Flex, Loader, Modal, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconFileDownload } from '@tabler/icons';

interface ExportableModalProps {
  opened: boolean;
  onClose: () => void;
  title?: string;
  loading: boolean;
}

export function ExportableModal({
  onClose,
  opened,
  title = 'Exportavel',
  loading,
}: ExportableModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Text fw="bold">{title}</Text>}
    >
      <Flex direction="column" justify="center" align="center">
        <Flex justify="center" align="center">
          <ThemeIcon
            radius="xl"
            p={11}
            color="orange"
            variant="light"
            size={80}
          >
            <IconFileDownload size={60} />
          </ThemeIcon>
          <Text ml={10}>Aguarde enquanto seu exportável é carregado...</Text>
        </Flex>
        {loading && <Loader color="orange.6" size={50} variant="bars" />}
        {!loading && <IconCheck color="green" size={60} />}
      </Flex>
    </Modal>
  );
}
