import { useState } from 'react';
import {
  useGetNotifications,
  useSetNotificationsReaded,
} from '../data/hooks/notifications';
import { Notification } from '../models/notification';
import { LocalStorageKeys } from '../utils/enums';
import { useLocalStorage } from './use-local-storage';

export type NotificationsState = Notification[];

export const useNotifications = () => {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const { fetch: getNotificationFetcher } = useGetNotifications();
  const { fetch: setNotificationsReadedFetcher } = useSetNotificationsReaded();

  const [notifications, setNotifications] = useState<NotificationsState>(() => {
    const notifications = getItem(LocalStorageKeys.NOTIFICATIONS);
    if (notifications) {
      return JSON.parse(notifications);
    }

    return { list: [] };
  });

  const listNotifications = async () => {
    await getNotificationFetcher({
      onSuccess: (notList) => set(notList ?? []),
    });
  };

  const setNotificationsReaded = async (ids: number[]) => {
    await setNotificationsReadedFetcher({
      data: { ids },
      onSuccess: () => listNotifications(),
    });
  };

  const set = (notifications: NotificationsState) => {
    setItem(LocalStorageKeys.NOTIFICATIONS, JSON.stringify(notifications));
    setNotifications(notifications);
  };

  const remove = () => {
    removeItem(LocalStorageKeys.NOTIFICATIONS);
    setNotifications([]);
  };

  return {
    set,
    remove,
    listNotifications,
    setNotificationsReaded,
    notifications,
  };
};
