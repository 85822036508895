import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PaginatedResponse } from '../../../utils/types';
import { UseGetActivityReportProps } from '../../../utils/types/data/hooks/activities';
import { GetActivityReportResponse } from '../../../utils/types/data/services/activities';
import {
  CustomActivity,
  GetActivitiesListParams,
  getActivitiesListRequest,
  getActivityReportRequest,
} from '../../services/activities';
import {
  UpdateActivityData,
  updateActivityRequest,
} from '../../services/activities';

interface UseUpdateActivityProps {
  id: number;
  data: UpdateActivityData;
}

export function useUpdateActivity() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<CustomActivity>();

  const fetch = ({ id, data }: UseUpdateActivityProps) =>
    fetchData({
      fetcher: () => updateActivityRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar atividade.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivities() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<CustomActivity>>();

  const fetch = (params: GetActivitiesListParams) =>
    fetchData({
      fetcher: () => getActivitiesListRequest(params),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar atividades.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityReport() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<GetActivityReportResponse>>();

  const fetch = ({ query }: UseGetActivityReportProps) =>
    fetchData({
      fetcher: () => getActivityReportRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar realatorio.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
