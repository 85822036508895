import {
  Badge,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Select,
  Stepper,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconAlertCircle,
  IconArrowLeft,
  IconCircleCheck,
  IconLock,
  IconLockOpen,
  IconSearch,
  IconTrash,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { useCreatePostal } from '../../data/hooks/postals';
import { getAddressByCepOnProxy } from '../../data/services/cep';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { paginatedOrdersRequest } from '../../data/services/orders';
import { validateAddOrderRequest } from '../../data/services/postals';
import { FinancialStatus, Order } from '../../models/order';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { orderStatusColor, orderStatusHumanized } from '../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
} from '../../utils/constants/order';
import { clearMask, formatCEP } from '../../utils/formater';
import { formatBRL, realToUSCash } from '../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';

export type PageModalState = 'add-order-postal' | 'edit-order-postal' | null;

export type PageModalConfirmationState = 'confirm-add-order' | null;

type PostalOrderItem = {
  id: number;
  os: string;
  product: string;
  totalQuantity: number;
  sendQuantity: number;
  companyId: number;
};

type PostalOrderItemProps = {
  item: PostalOrderItem;
  onDelete: (id: number) => void;
};

function PostalOrderItem({ item, onDelete }: PostalOrderItemProps) {
  return (
    <Card shadow="xs" mb={8}>
      <Flex justify="space-between">
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            OS
          </Text>
          <Text>{item.os}</Text>
        </Flex>
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            Produto
          </Text>
          <Text>{item.product}</Text>
        </Flex>
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            Quantidade total
          </Text>
          <Text>{item.totalQuantity}</Text>
        </Flex>
        <Flex direction="column">
          <Text fw="bold" color="gray" size={12}>
            Quantidade enviada
          </Text>
          <Text>{item.sendQuantity}</Text>
        </Flex>
        <Flex direction="column">
          <Button
            color="red"
            variant="light"
            type="button"
            onClick={() => onDelete(item.id)}
          >
            <IconTrash size={20} />
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}

export function PostalCreate() {
  const { fetch: createPostalFetcher, loading: createPostalLoad } =
    useCreatePostal();
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedOrdersToPostal, setSelectedOrdersToPostal] = useState<
    PostalOrderItem[]
  >([]);
  const [pageModalConfirmationVisible, setPageModalConfirmationVisible] =
    useState<PageModalConfirmationState>(null);

  const form = useForm({
    initialValues: {
      orders: [],
      receiver: '',
      postalType: '',
      description: '',
      price: '',
      taxPrice: '',
      totalPrice: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressCity: '',
      addressState: '',
      addressCountry: 'BR',
      addressZipCode: '',
    },
  });
  const orderFilterForm = useForm({
    initialValues: {
      os: '',
      company: '',
    },
  });
  const addOrderToPostalForm = useForm({
    initialValues: {
      quantity: '',
    },
  });

  function handleSubmit() {
    createPostalFetcher({
      data: {
        ...form.values,
        price: realToUSCash(form.values.price),
        taxPrice: realToUSCash(form.values.taxPrice),
        totalPrice: realToUSCash(form.values.totalPrice),
        addressZipCode: clearMask(form.values.addressZipCode),
        orders: selectedOrdersToPostal.map((item) => ({
          id: item.id,
          quantity: item.sendQuantity,
        })),
      },
      onSuccess: () => {
        rootNavigateGoBack();
      },
    });
  }

  function handleFilterOrders() {
    getOrdersPaginated();
    setSelectedOrder(undefined);
  }

  function handleAddPostalOrder(order: Order, quantity: string) {
    const cloneList = selectedOrdersToPostal;

    const foundedIndex = cloneList.findIndex((item) => item.os === order.os);
    const anotherCompany = cloneList.find(
      (item) => item.companyId !== order.company.id,
    );

    if (foundedIndex !== -1) {
      errorNotification({
        title: 'esta OS ja foi incluida!',
        message: 'remova da lista antes de adiciona-la',
      });
      return;
    }

    if (anotherCompany) {
      errorNotification({
        title: 'não é permitido adicionar OS de clientes diferentes!',
        message: 'tente outra OS',
      });
      return;
    }

    cloneList.push({
      id: order.id,
      os: order.os,
      product: `${order.product}, ${order.productColor}, ${order.productMaterial}`,
      totalQuantity: order.quantity,
      sendQuantity: Number(quantity),
      companyId: order.company.id,
    });

    setSelectedOrdersToPostal([...cloneList]);
    setSelectedOrder(undefined);
    setPageModalVisible(null);
    addOrderToPostalForm.reset();
  }

  function handleRemovePostalOrder(id: number) {
    const cloneList = selectedOrdersToPostal;
    const foundedIndex = cloneList.findIndex((item) => item.id === id);

    if (foundedIndex !== -1) {
      cloneList.splice(foundedIndex, 1);
      setSelectedOrdersToPostal([...cloneList]);
    }
  }

  function handleSelectOrder(order: Order) {
    if (order.needsPostal) {
      setSelectedOrder(order);
    }

    if (!order.needsPostal) {
      errorNotification({
        title: 'Correio não disponível.',
        message: 'correio desabilitado para esta OS',
      });
    }
  }

  async function getOrdersPaginated() {
    const validatedFilter: any = {};

    Object.entries(orderFilterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setPageLoading(true);
      const result = await paginatedOrdersRequest({
        page: 1,
        limit: 10,
        ...validatedFilter,
      });
      setOrderList(result.items);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getAddress(cep: string) {
    form.setFieldValue('addressCity', '');
    form.setFieldValue('addressState', '');
    form.setFieldValue('addressStreet', '');

    setPageLoading(true);
    const address = await getAddressByCepOnProxy(cep.replace('-', ''));
    setPageLoading(false);
    if (address) {
      form.setFieldValue('addressCity', address?.city);
      form.setFieldValue('addressCountry', 'BR');
      form.setFieldValue('addressState', address?.state);
      form.setFieldValue('addressStreet', address?.address);
    }
  }

  async function handleValidateAddOrder(order: Order) {
    if (order.financialStatus === FinancialStatus.INVOICED) {
      errorNotification({
        title: 'Pedido já faturado',
        message: 'não é possivel selecionar',
      });
      return;
    }

    const response = await validateAddOrderRequest(order.id);

    if (response) {
      handleSelectOrder(order);
      setPageModalConfirmationVisible('confirm-add-order');
    } else {
      handleSelectOrder(order);
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Page>
      <CustomLoader loading={pageLoading || createPostalLoad} />
      <Container>
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={30} fw="bolder" color="orange" order={1} align="center">
          Novo Correio
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit(() => handleSubmit())}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Correio" description="dados do correio">
              <Group position="right" mb={8}>
                <Button
                  color="blue"
                  type="button"
                  onClick={() => setPageModalVisible('add-order-postal')}
                >
                  Adicionar Pedido
                </Button>
              </Group>
              {selectedOrdersToPostal.map((item) => (
                <PostalOrderItem
                  item={item}
                  key={item.id}
                  onDelete={(id) => handleRemovePostalOrder(id)}
                />
              ))}
              <Grid gutter="xs" mt={8} maw={900} columns={4}>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Destinatário"
                    placeholder="digite o nome da empresa/pessoa"
                    type="text"
                    name="receiver"
                    {...form.getInputProps('receiver')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <Select
                    required
                    withAsterisk
                    searchable
                    name="postalType"
                    label="Tipo de Envio"
                    placeholder="selecione o tipo de envio"
                    data={[
                      {
                        label: 'ENCOMENDA PAC',
                        value: 'pac',
                      },
                      {
                        label: 'SEDEX',
                        value: 'sedex',
                      },
                      {
                        label: 'SEDEX 10',
                        value: 'sedex_10',
                      },
                      {
                        label: 'SEDEX 12',
                        value: 'sedex_12',
                      },
                      {
                        label: 'E-SEDEX',
                        value: 'e-sedex',
                      },
                      {
                        label: 'SEDEX HOJE',
                        value: 'sedex_hoje',
                      },
                      {
                        label: 'Outro',
                        value: 'other',
                      },
                    ]}
                    {...form.getInputProps('postalType')}
                  />
                </Grid.Col>
                <Grid.Col xl={1} lg={1} md={1} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Valor a pagar"
                    placeholder="digite o valor"
                    type="text"
                    name="price"
                    value={formatBRL(form.values.price ?? '')}
                    onChange={(e) => {
                      const clearValue =
                        Number(realToUSCash(e.target.value)) * 10;

                      form.setValues({
                        price: formatBRL(e.target.value),
                        taxPrice: formatBRL(
                          Number(clearValue + clearValue * 0.07).toFixed(2),
                        ),
                      });
                    }}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    readOnly
                    variant="filled"
                    label="Valor com taxa"
                    placeholder="digite o valor no campo ao lado"
                    type="text"
                    name="taxPrice"
                    {...form.getInputProps('taxPrice')}
                  />
                </Grid.Col>
                <Grid.Col xl={1} lg={1} md={1} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Valor cobrado"
                    placeholder="digite o valor"
                    type="text"
                    name="totalPrice"
                    value={formatBRL(form.values.totalPrice ?? '')}
                    onChange={(e) =>
                      form.setValues({
                        totalPrice: formatBRL(e.target.value),
                      })
                    }
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text size={16} color="dark.4">
                    Endereço
                  </Text>
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="CEP"
                    placeholder="digite o CEP do endereço"
                    type="text"
                    name="addressZipCode"
                    onChange={async (e) => {
                      form.setFieldValue(
                        'addressZipCode',
                        formatCEP(e.target.value),
                      );
                      if (e.target.value.length == 9) {
                        await getAddress(e.target.value);
                      }
                    }}
                    value={form.values.addressZipCode}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Endereço"
                    placeholder="digite o endereço"
                    type="text"
                    name="addressStreet"
                    {...form.getInputProps('addressStreet')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Número"
                    placeholder="digite o número"
                    type="text"
                    name="addressNumber"
                    {...form.getInputProps('addressNumber')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    label="Complemento"
                    placeholder="digite o complemento do endereço"
                    type="text"
                    name="addressComplement"
                    {...form.getInputProps('addressComplement')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Estado"
                    placeholder="digite o estado ex: SP"
                    type="text"
                    name="addressState"
                    {...form.getInputProps('addressState')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Cidade"
                    placeholder="digite a cidade"
                    type="text"
                    name="addressCity"
                    {...form.getInputProps('addressCity')}
                  />
                </Grid.Col>
                <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                  <Textarea
                    maxLength={255}
                    mt={16}
                    label="Observações"
                    placeholder="escreva aqui qualquer observação que queira fazer"
                    name="description"
                    {...form.getInputProps('description')}
                  />
                </Grid.Col>
              </Grid>
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
      <Modal
        size={820}
        opened={pageModalVisible === 'add-order-postal'}
        onClose={() => setPageModalVisible(null)}
        title="Buscar pedidos"
      >
        <form onSubmit={orderFilterForm.onSubmit(handleFilterOrders)}>
          <Grid gutter="xs" columns={5} mb={8}>
            <Grid.Col span={2}>
              <TextInput
                label="OS"
                placeholder="digite a OS"
                type="text"
                name="os"
                {...orderFilterForm.getInputProps('os')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Select
                clearable
                searchable
                name="company"
                label="Cliente"
                placeholder="selecione o cliente"
                data={companyList.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                {...orderFilterForm.getInputProps('company')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Button color="blue" mt={24} type="submit">
                <IconSearch size={20} />
              </Button>
            </Grid.Col>
          </Grid>
        </form>
        <DataTable
          mb={16}
          height={320}
          noRecordsText="Sem pedidos"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          onRowClick={(order) => handleValidateAddOrder(order)}
          records={orderList}
          rowStyle={(order) => (order.needsPostal ? {} : { opacity: 0.5 })}
          columns={[
            {
              accessor: 'selected',
              title: '',
              render: ({ id }) =>
                selectedOrder?.id === id ? (
                  <IconCircleCheck color="green" size="28" />
                ) : null,
            },
            {
              accessor: 'available',
              title: '',
              render: ({ needsPostal }) =>
                needsPostal ? (
                  <IconLockOpen color="green" />
                ) : (
                  <IconLock color="red" />
                ),
            },
            {
              accessor: 'os',
              title: 'OS',
              width: 110,
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'financialStatus',
              title: 'Status Fiscal',
              render: ({ financialStatus }) =>
                financialStatus && (
                  <Badge color={financialStatusColor[financialStatus]} mb={6}>
                    {financialStatusTypeHumanized[financialStatus]}
                  </Badge>
                ),
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
              width: 90,
            },
            {
              accessor: 'internalNumber',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Quantidade',
              width: 70,
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY HH:mm'),
              width: 110,
            },
          ]}
        />
        {selectedOrder && (
          <form
            onSubmit={addOrderToPostalForm.onSubmit(
              (values) =>
                selectedOrder &&
                handleAddPostalOrder(selectedOrder, values.quantity),
            )}
          >
            <TextInput
              w={200}
              description="essa é a quantidade que vai ser enviada por correio*"
              label="Quantidade enviada"
              placeholder="digite a quantidade"
              type="number"
              name="quantity"
              {...addOrderToPostalForm.getInputProps('quantity')}
            />
            <Group position="right">
              <Button color="dark" type="submit">
                Incluir
              </Button>
            </Group>
          </form>
        )}
      </Modal>
      <Modal
        size={420}
        opened={pageModalConfirmationVisible === 'confirm-add-order'}
        onClose={() => {
          setPageModalConfirmationVisible(null);
          setSelectedOrder(undefined);
        }}
        closeOnClickOutside={false}
        closeOnEscape={false}
        title=""
      >
        <Flex justify="center" align="center" mb={16}>
          <IconAlertCircle color="orange" size="84" />
        </Flex>
        <Flex justify="center" align="center">
          <Text size={16}>
            Já existe um correio criado para essa OS: <b>{selectedOrder?.os}</b>
          </Text>
        </Flex>
        <Flex justify="center" align="center" mb={16}>
          <Text size={12}>Deseja incluir este pedido mesmo assim?</Text>
        </Flex>
        <Flex justify="flex-end">
          <Button
            color="ltpBlue"
            onClick={() => {
              setPageModalConfirmationVisible(null);
            }}
          >
            Confirmar
          </Button>
          <Button
            color="ltpBlue.9"
            ml={16}
            onClick={() => {
              setPageModalConfirmationVisible(null);
              setSelectedOrder(undefined);
            }}
          >
            Cancelar
          </Button>
        </Flex>
      </Modal>
    </Page>
  );
}
