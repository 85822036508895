import React from 'react';
import { useSelector } from 'react-redux';
import { UserType } from '../models/user';
import { RootState } from '../providers/store';

export function useUserConditionalRender() {
  const { user } = useSelector((state: RootState) => state.auth);

  function isAdminType(children: React.ReactNode) {
    if (user?.type === UserType.MASTER) {
      return children;
    }
    return null;
  }

  function isUserType(children: React.ReactNode) {
    if (user?.type === UserType.USER) {
      return children;
    }
    return null;
  }

  function isUserTypeWithRole(role: string, children: React.ReactNode) {
    if (user?.type === UserType.USER && user.role === role) {
      return children;
    }
    return null;
  }

  function isUserTypeWithRoles(roles: string[], children: React.ReactNode) {
    if (user?.type === UserType.USER && roles.includes(user.role)) {
      return children;
    }
    return null;
  }

  function isMainUser(children: React.ReactNode) {
    if (user?.isMain) {
      return children;
    }
    return null;
  }

  return {
    isAdminType,
    isUserType,
    isUserTypeWithRole,
    isUserTypeWithRoles,
    isMainUser,
  };
}
