import { Accordion, Card, Flex, Grid, Indicator, Text } from '@mantine/core';
import {
  GetPostalOrdersResponse,
  MissingOrders,
  OrderPostal,
} from '../../utils/types/data/services/postals';
import { formatBRL } from '../../utils/helpers';
import React from 'react';
import { IconCircleCheck, IconCircleX } from '@tabler/icons';

interface PostalOrderItemProps {
  orders: GetPostalOrdersResponse[];
  missingOrders?: MissingOrders[];
}

interface NfPostalItemProps {
  postals: OrderPostal[] | undefined;
  missingOrders?: MissingOrders[];
}

function PostalOrderItem({ orders, missingOrders }: PostalOrderItemProps) {
  return (
    <React.Fragment>
      {orders.map((item) => (
        <Card
          shadow="xs"
          withBorder
          mb={8}
          key={item.orderId}
          style={{
            background: `${
              missingOrders?.some((missingOrder) =>
                missingOrder.os.includes(item.os),
              )
                ? 'rgb(255, 0, 0, 0.2)'
                : ''
            }`,
          }}
        >
          <Flex justify="space-between">
            {missingOrders?.some((missingOrder) =>
              missingOrder.os.includes(item.os),
            ) ? (
              <Flex direction="column" justify="center">
                <IconCircleX color="red" />
              </Flex>
            ) : (
              <Flex direction="column" justify="center">
                <IconCircleCheck color="green" />
              </Flex>
            )}

            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                OS
              </Text>
              <Text>{item.os}</Text>
            </Flex>
            <Flex direction="column" w={170}>
              <Text fw="bold" color="gray" size={12}>
                Produto
              </Text>
              <Text>
                {item.product}, {item.productMaterial}, {item.productColor}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Quantidade total
              </Text>
              <Text>{item.orderQuantity}</Text>
            </Flex>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Quantidade enviada
              </Text>
              <Text>{item.quantity}</Text>
            </Flex>
          </Flex>
        </Card>
      ))}
    </React.Fragment>
  );
}

const Item = ({ postals, missingOrders }: NfPostalItemProps) => {
  return (
    <>
      {postals?.map((item) => (
        <Accordion.Item value={`postal-${item.postal.id}`} key={item.postal.id}>
          <Accordion.Control>
            <Indicator
              mt={8}
              disabled={
                !missingOrders?.some(
                  (missingOrder) => missingOrder.postal === item.postal.code,
                )
              }
              inline
              size={12}
              color="red"
              withBorder
            >
              <b>Correio: </b>
              {item.postal.code}
            </Indicator>
          </Accordion.Control>
          <Accordion.Panel>
            <Grid gutter="xs" columns={2}>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Código</Text>
                  <Text color="gray">{item.postal.code}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Destinatário</Text>
                  <Text color="gray">{item.postal.receiver}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Tipo</Text>
                  <Text color="gray">
                    {item.postal.postalType.toUpperCase()}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Descrição</Text>
                  <Text color="gray">
                    {item.postal.description ? item.postal.description : '----'}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Preço total</Text>
                  <Text color="gray">{formatBRL(item.postal.totalPrice)}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col mt={8} mb={8} span={2}>
                <Text fw="bold" color="gray">
                  Pedidos
                </Text>
              </Grid.Col>
            </Grid>
            <PostalOrderItem
              missingOrders={missingOrders}
              orders={item.orders}
            />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </>
  );
};

export const NfPostalItem = Item;
