import { ThemeIcon, NavLink } from '@mantine/core';
import { IconList } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { UserRole } from '../../../models/user';
import { RootState } from '../../../providers/store';
import { AppRoutePaths } from '../../../utils/enums';

interface OrdersLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function OrdersLink({ navigate, themeColor }: OrdersLinkProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Pedidos"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="orange" variant="light">
          <IconList size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Andamento"
        onClick={() => navigate(AppRoutePaths.ORDERS)}
      />
      {user?.role === UserRole.FINANCIAL && (
        <NavLink
          label="Comprovantes"
          onClick={() => navigate(AppRoutePaths.ORDER_FILE_RECEIPTS_APPROVAL)}
        />
      )}
      <NavLink
        label="Histórico"
        onClick={() => navigate(AppRoutePaths.ORDER_HISTORIC)}
      />
      {user?.role &&
        [UserRole.VENDOR, UserRole.OTHER, UserRole.FINANCIAL].includes(
          user?.role,
        ) && (
          <NavLink
            label="Pendência Financeira"
            onClick={() => navigate(AppRoutePaths.ORDERS_LATE)}
          />
        )}
      {user?.role !== UserRole.COMPANY && (
        <NavLink
          label="PCP"
          onClick={() => navigate(AppRoutePaths.ORDER_PCP)}
        />
      )}
    </NavLink>
  );
}
