import { Button, Grid, Group, Text, Paper } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons';
import { formatCNPJ, formatCPF } from '../../utils/formater';
import { AddCompanyDocumentsData } from '../../utils/types/data/services/company-documents';

type CompanyDocumentItem = AddCompanyDocumentsData & { onEdit: boolean };

interface CompanyDocItemProps {
  docItem: CompanyDocumentItem;
  handleDeleteDocument(): void;
  handleSetDocumentToEdit(): void;
}

export function CompanyDocItem({
  docItem,
  handleDeleteDocument,
  handleSetDocumentToEdit,
}: CompanyDocItemProps) {
  return (
    <Paper shadow="md" m={8} p={16} w={426}>
      <Grid gutter="xs" columns={4}>
        <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
          <Group position="right">
            <Button
              onClick={handleDeleteDocument}
              color="red"
              variant="light"
              mt={8}
              p={0}
              w={30}
              h={30}
            >
              <IconTrash />
            </Button>
            <Button
              onClick={handleSetDocumentToEdit}
              color="blue"
              variant="light"
              mt={8}
              p={0}
              w={30}
              h={30}
            >
              <IconPencil />
            </Button>
          </Group>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Nome Fantasia
          </Text>
          <Text size={14}>{docItem.nomeFantasia}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Razão Social
          </Text>
          <Text size={14}>{docItem.razaoSocial}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Tipo de Documento
          </Text>
          <Text size={14}>{docItem.documentType}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Número
          </Text>
          <Text size={14}>
            {docItem.documentType === 'cnpj'
              ? formatCNPJ(docItem.document)
              : formatCPF(docItem.document)}
          </Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Inscrição Estadual
          </Text>
          <Text size={14}>{docItem.inscricaoEstadual}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Inscrição Municipal
          </Text>
          <Text size={14}>{docItem.inscricaoMunicipal}</Text>
        </Grid.Col>
        <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Email
          </Text>
          <Text size={14}>{docItem.email}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            CEP
          </Text>
          <Text size={14}>{docItem.addressZipCode}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Endereço
          </Text>
          <Text size={14}>{docItem.addressStreet}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Número
          </Text>
          <Text size={14}>{docItem.addressNumber}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Estado
          </Text>
          <Text size={14}>{docItem.addressState}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Cidade
          </Text>
          <Text size={14}>{docItem.addressCity}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Complemento
          </Text>
          <Text size={14}>{docItem.addressComplement}</Text>
        </Grid.Col>
        <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
          <Text color="dark.3" fw="bold" size={14}>
            Código de Estado IBGE
          </Text>
          <Text size={14}>{docItem.addressStateIBGECode}</Text>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
