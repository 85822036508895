import { useFetch } from '../../../hooks/use-fetch';
import { ActivityLayout } from '../../../models/activity-layout';
import { ActivityLayoutFile } from '../../../models/activity-layout-file';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseAddActivityLayoutFileProps,
  UseClientApproveActivityLayoutProps,
  UseCreateActivityLayoutProps,
  UseGetActivityLayoutFileProps,
  UseGetActivityLayoutProps,
  UseGetActivityLayoutToApproveProps,
  UseRequestClientApprovalActivityLayoutProps,
  UseVendorApproveActivityLayoutProps,
} from '../../../utils/types/data/hooks/activity-layouts';
import { ActivityLayoutsToApproveResponse } from '../../../utils/types/data/services/activity-layouts';
import {
  addActivityLayoutFilesRequest,
  clientApproveActivityLayoutRequest,
  createActivityLayoutRequest,
  getActivityLayoutFilesRequest,
  getActivityLayoutsRequest,
  getActivityLayoutToApproveRequest,
  requestClientApprovalActivityLayoutRequest,
  vendorApproveActivityLayoutRequest,
} from '../../services/activity-layouts';

export function useCreateActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({ activityId, onSuccess }: UseCreateActivityLayoutProps) =>
    fetchData({
      fetcher: () => createActivityLayoutRequest(activityId),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar layout.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useVendorApproveActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({
    data,
    id,
    onSuccess,
  }: UseVendorApproveActivityLayoutProps) =>
    fetchData({
      fetcher: () => vendorApproveActivityLayoutRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao aprovar layout.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRequestClientApprovalActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({
    id,
    onSuccess,
  }: UseRequestClientApprovalActivityLayoutProps) =>
    fetchData({
      fetcher: () => requestClientApprovalActivityLayoutRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao solicitar aprovação layout.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useClientApproveActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({
    data,
    id,
    onSuccess,
  }: UseClientApproveActivityLayoutProps) =>
    fetchData({
      fetcher: () => clientApproveActivityLayoutRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao aprovar layout.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout[]>();

  const fetch = ({ activityId }: UseGetActivityLayoutProps) =>
    fetchData({
      fetcher: () => getActivityLayoutsRequest(activityId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useAddActivityLayoutFile() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseAddActivityLayoutFileProps) =>
    fetchData({
      fetcher: () => addActivityLayoutFilesRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar aquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutFile() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayoutFile[]>();

  const fetch = ({ query }: UseGetActivityLayoutFileProps) =>
    fetchData({
      fetcher: () => getActivityLayoutFilesRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutToApprove() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<ActivityLayoutsToApproveResponse>>();

  const fetch = ({ query }: UseGetActivityLayoutToApproveProps) =>
    fetchData({
      fetcher: () => getActivityLayoutToApproveRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
