import { ActivityLayoutStatus } from '../../models/activity-layout';

export const activityLayoutStatusHumanized = {
  [ActivityLayoutStatus.APPROVED]: 'aprovado',
  [ActivityLayoutStatus.REPROVED]: 'reprovado',
  [ActivityLayoutStatus.CLIENT_APPROVE]: 'aguard. cliente',
  [ActivityLayoutStatus.VENDOR_APPROVE]: 'aguard. vendedor',
};

export const activityLayoutStatusColor = {
  [ActivityLayoutStatus.APPROVED]: 'green',
  [ActivityLayoutStatus.REPROVED]: 'red',
  [ActivityLayoutStatus.CLIENT_APPROVE]: 'orange',
  [ActivityLayoutStatus.VENDOR_APPROVE]: 'orange',
};

export const activityLayoutStatusList = [
  { label: 'aprovado', value: ActivityLayoutStatus.APPROVED },
  { label: 'aguard. cliente', value: ActivityLayoutStatus.CLIENT_APPROVE },
  { label: 'aguard. vendedor', value: ActivityLayoutStatus.VENDOR_APPROVE },
  { label: 'reprovado', value: ActivityLayoutStatus.REPROVED },
];
