/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Radio,
  Select,
  Stepper,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { CustomDateTimePicker } from '../../../components/CustoDateTimePicker';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomMultipleFileInput } from '../../../components/CustomMutipleFileInput';
import { rootNavigateGoBack } from '../../../components/CustomRouter';
import { getFilterCompaniesListRequest } from '../../../data/services/filters';
import { TeamConfigAlias } from '../../../models/team-config';
import { RootState } from '../../../providers/store';
import { orderPaymentTypeList } from '../../../utils/constants/order';
import {
  formatBRL,
  realToUSCash,
  textCapitalize,
} from '../../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';

interface VendorCreateOrderMobileProps {
  navigation: NavigateFunction;
  form: UseFormReturnType<any>;
  handleSubmit(values: any): void;
  services: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
  handleSelectService(id: string): void;
  servicerFormRender: any;
  setSelectedService: React.Dispatch<React.SetStateAction<string>>;
  selectedService: string;
}

const CLIENT_ID_360 = '40';

export function VendorCreateOrderMobile({
  form,
  handleSubmit,
  services,
  handleSelectService,
  servicerFormRender,
  selectedService,
}: VendorCreateOrderMobileProps) {
  const [companyList, setCompanyList] =
    useState<GetFilterCompaniesListResponse[]>();
  const companyOptions = companyList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));
  const { user, userTeam } = useSelector((state: RootState) => state.auth);

  const teamConfilterFilter = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.TEAM_FILTER,
    );
  }, []);

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest({
      team:
        teamConfilterFilter?.value === 'false' ? undefined : Number(user?.team),
    });
    setCompanyList(response);
  }

  const price = Number(realToUSCash(String(form.values.price))) || 0;
  const samplePrice =
    Number(realToUSCash(String(form.values.samplePrice))) || 0;
  const photolitoPrice =
    Number(realToUSCash(String(form.values.pholitoPrice))) || 0;
  const manipulationPrice =
    Number(realToUSCash(String(form.values.manipulationPrice))) || 0;
  const vectorizationPrice =
    Number(realToUSCash(String(form.values.vectorizationPrice))) || 0;
  const clichePrice =
    Number(realToUSCash(String(form.values.clichePrice))) || 0;
  const quantity = Number(form.values.quantity);
  const unitPrice = Math.fround(price / quantity);
  const orderUnitPrice = formatBRL(unitPrice.toFixed(2));
  const orderTotalPrice = formatBRL(
    (
      price +
      samplePrice +
      photolitoPrice +
      manipulationPrice +
      vectorizationPrice +
      clichePrice
    ).toFixed(2),
  );

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    const photolitoServices: string[] = [];
    const clicheServices: string[] = [];
    const silkService: string[] = [];

    if (form.values.company !== CLIENT_ID_360) {
      services.forEach((item) => {
        if (item.label === 'Tampografia') {
          photolitoServices.push(item.value);
        } else if (item.label === 'Hot Stamping') {
          clicheServices.push(item.value);
        } else if (item.label === 'Silkscreen') {
          silkService.push(item.value);
        }
      });

      form.setFieldValue('pholitoPrice', formatBRL(''));
      form.setFieldValue('clichePrice', formatBRL(''));

      if (photolitoServices.includes(form.values.service)) {
        let initialPrice = 7000;
        const additionalPrice = 2500;
        const colorsAmount = Number(form.values?.details?.colorsAmount);

        if (colorsAmount > 1) {
          const additionalValue = (colorsAmount - 1) * additionalPrice;
          initialPrice += additionalValue;
        }

        form.setFieldValue('clichePrice', formatBRL(initialPrice.toString()));
        form.setFieldValue('pholitoPrice', formatBRL(''));
      } else if (clicheServices.includes(form.values.service)) {
        form.setFieldValue('pholitoPrice', formatBRL(''));
        form.setFieldValue('clichePrice', formatBRL(''));
      } else if (silkService.includes(form.values.service)) {
        form.setFieldValue('clichePrice', formatBRL(''));
        form.setFieldValue('pholitoPrice', formatBRL('3500'));
      }
    } else {
      form.setFieldValue('pholitoPrice', formatBRL(''));
      form.setFieldValue('clichePrice', formatBRL(''));
    }
  }, [
    form.values.pholitoPrice,
    form.values.service,
    form.values.details?.colorsAmount,
    form.values.company,
  ]);

  return (
    <Container>
      <Grid gutter="xs" columns={5}>
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center"></Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex align="center" justify="end"></Flex>
        </Grid.Col>
      </Grid>
      <Title size={40} fw="bolder" color="orange" order={1} align="center">
        Novo Pedido
      </Title>
      <Divider m={16} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stepper
          color="orange"
          orientation="horizontal"
          iconSize={37}
          active={0}
        >
          <Stepper.Step label="Pedido" description="dados do pedido">
            <TextInput
              label="Identificação interna"
              placeholder="código customizavel para identificar o pedido"
              mb={16}
              type="text"
              name="internalNumber"
              value={form.values.internalNumber}
              maxLength={255}
              {...form.getInputProps('internalNumber')}
            />
            <Select
              searchable
              required
              withAsterisk
              name="company"
              label="Cliente"
              placeholder="selecione o cliente do pedido"
              data={companyOptions ?? []}
              mb={16}
              {...form.getInputProps('company')}
            />
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Produto"
                placeholder="descreva o produto ex: Caneta, Caneca"
                mb={16}
                type="text"
                name="product"
                value={form.values.product}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'product',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <NumberInput
                required
                name="quantity"
                withAsterisk
                label="Quantidade"
                placeholder="digite a quantidade"
                mb={16}
                min={0}
                {...form.getInputProps('quantity')}
              />
            </Group>
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Cor"
                placeholder="cor do produto"
                mb={16}
                type="text"
                value={form.values.productColor}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'productColor',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <TextInput
                required
                withAsterisk
                label="Material"
                placeholder="descreva se ex: plástico, metal e etc..."
                mb={16}
                type="text"
                value={form.values.productMaterial}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'productMaterial',
                    textCapitalize(event.target.value),
                  )
                }
              />
            </Group>
            <Group grow>
              <TextInput
                readOnly
                label="Preço Clichê"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="clichePrice"
                value={formatBRL(form.values.clichePrice ?? '')}
                onChange={(e) =>
                  form.setValues({ clichePrice: formatBRL(e.target.value) })
                }
              />
              <TextInput
                readOnly
                label="Preço Fotolito"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="pholitoPrice"
                value={formatBRL(form.values.pholitoPrice ?? '')}
                onChange={(e) =>
                  form.setValues({ pholitoPrice: formatBRL(e.target.value) })
                }
              />
            </Group>
            <Group grow>
              <TextInput
                label="Preço Manuseio"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="manipulationPrice"
                value={formatBRL(form.values.manipulationPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    manipulationPrice: formatBRL(e.target.value),
                  })
                }
              />
              <TextInput
                label="Preço Vetorização"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="vectorizationPrice"
                value={formatBRL(form.values.vectorizationPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    vectorizationPrice: formatBRL(e.target.value),
                  })
                }
              />
            </Group>
            <Group grow>
              <TextInput
                label="Preço Amostra"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="samplePrice"
                value={formatBRL(form.values.samplePrice ?? '')}
                onChange={(e) =>
                  form.setValues({ samplePrice: formatBRL(e.target.value) })
                }
              />
              <TextInput
                label="Preço (total gravação)"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="price"
                value={formatBRL(form.values.price ?? '')}
                onChange={(e) =>
                  form.setFieldValue('price', formatBRL(e.target.value))
                }
              />
            </Group>
            <Group grow>
              <div>
                <Text>Preço unitário: </Text>
                <Text color="gray">{orderUnitPrice}</Text>
              </div>
              <div>
                <Text>Preço final: </Text>
                <Text color="gray">{orderTotalPrice}</Text>
              </div>
            </Group>
            <Group grow>
              <Select
                required
                name="paymentType"
                withAsterisk
                label="Forma de pagamento"
                placeholder="selecione uma forma de pagamento"
                data={orderPaymentTypeList}
                mb={16}
                {...form.getInputProps('paymentType')}
              />
            </Group>
            <Select
              required
              name="service"
              withAsterisk
              searchable
              label="Serviço"
              placeholder="selecione um serviço"
              data={services}
              mb={16}
              onChange={handleSelectService}
            />
            {selectedService !== '' &&
              servicerFormRender?.[selectedService as any] &&
              servicerFormRender[selectedService as any]()}
            <TextInput
              withAsterisk
              required
              label="Nome do logotipo"
              placeholder="texto da gravação"
              mb={16}
              type="text"
              name="engravingText"
              maxLength={255}
              {...form.getInputProps('engravingText')}
            />
            <Group grow>
              <Checkbox
                label="Precisa de amostra?"
                description="(Você está criando uma amostra além do pedido)"
                color="green"
                name="needSample"
                mt={16}
                mb={16}
                disabled={form.values.isSample}
                w={300}
                {...form.getInputProps('needSample')}
              />
              <Checkbox
                label="É uma amostra?"
                description="(Você está criando apenas uma OS de amostra)"
                color="green"
                name="isSample"
                mt={16}
                mb={16}
                w={300}
                disabled={form.values.needSample}
                {...form.getInputProps('isSample')}
              />
            </Group>
            <Text size={14}>Possui Correio</Text>
            <Text size={12} color="gray">
              este pedido será enviado por correio?
            </Text>
            <Group mb={16} mt={8}>
              <Button
                color={form.values.needsPostal === 'true' ? 'green' : 'gray'}
                onClick={() => form.setFieldValue('needsPostal', 'true')}
              >
                Sim
              </Button>
              <Button
                color={form.values.needsPostal === 'false' ? 'red' : 'gray'}
                onClick={() => form.setFieldValue('needsPostal', 'false')}
              >
                Não
              </Button>
            </Group>
            <TextInput
              label="Fornecedor"
              placeholder="nome do fornecedor"
              mb={16}
              type="text"
              name="supplier"
              value={form.values.supplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'supplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            <TextInput
              label="Código do produto"
              placeholder="para identificação do produto no fornecedor"
              mb={16}
              type="text"
              name="productCodeSupplier"
              value={form.values.productCodeSupplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'productCodeSupplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            <Radio.Group
              name="orderAccess"
              label="Chegada do material na empresa"
              description="nos diga como vamos receber o material"
              withAsterisk
              required
              {...form.getInputProps('orderAccess')}
            >
              <Radio
                value="delivered"
                label="o pedido será entregue na empresa"
              />
              <Radio value="collected" label="a empresa irá retirar o pedido" />
            </Radio.Group>
            {form.values.orderAccess === 'collected' ? (
              <>
                <TextInput
                  withAsterisk
                  label="Local da retirada"
                  placeholder="nome do fornecedor + endereço completo"
                  mb={16}
                  type="text"
                  name="pickUpLocation"
                  maxLength={255}
                  {...form.getInputProps('pickUpLocation')}
                />
                <TextInput
                  withAsterisk
                  label="Resposável pela retirada"
                  placeholder="com quem falar na retirada"
                  mb={16}
                  type="text"
                  name="pickUpName"
                  maxLength={255}
                  {...form.getInputProps('pickUpName')}
                />
                <TextInput
                  withAsterisk
                  label="Código para identificação no fornecedor"
                  placeholder="necessário para retirada de pedidos na SPOT,ASIA"
                  mb={16}
                  type="text"
                  name="pickUpNumber"
                  maxLength={255}
                  {...form.getInputProps('pickUpNumber')}
                />
                <CustomDatePicker
                  label="Previsão para retirada de material"
                  value={form.values.pickUpForecast}
                  description="data em que devemos retirar o material no portador"
                  {...form.getInputProps('pickUpForecast')}
                />
              </>
            ) : (
              <>
                <TextInput
                  withAsterisk
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  maxLength={255}
                  {...form.getInputProps('carrierName')}
                />
                <CustomDatePicker
                  label="Previsão de recebimento do material"
                  value={form.values.receiptForecast}
                  description="data em que o material deve chegar"
                  {...form.getInputProps('receiptForecast')}
                />
              </>
            )}
            <CustomDateTimePicker
              label="Prazo de finalização"
              date={form.values.finishVendorForecast}
              onChange={(dateTime) =>
                form.setFieldValue('finishVendorForecast', dateTime)
              }
            />
            <CustomMultipleFileInput
              name="files"
              accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.ps,.bin"
              label="Arquivos"
              placeholder="selecione arquivo por vez"
              formRef={form}
            />
            <Textarea
              maxLength={255}
              mt={16}
              label="Observações"
              placeholder="escreva aqui qualquer observação que queira fazer sobre seu pedido"
              name="note"
              {...form.getInputProps('note')}
            />
            <CustomMultipleFileInput
              name="fileReceipts"
              accept="application/pdf,image/png,image/jpeg,image/jpg"
              label="Comprovantes"
              placeholder="selecione arquivo por vez"
              inputName="fileReceipts"
              formRef={form}
            />
          </Stepper.Step>
        </Stepper>
        <Group position="right" mt="xl">
          <Button color="ltpBlue.9" type="submit">
            Salvar
          </Button>
        </Group>
      </form>
    </Container>
  );
}
