import {
  Avatar,
  Flex,
  Group,
  Menu,
  UnstyledButton,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { IconLogout } from '@tabler/icons';
import { User } from '../../models/user';
import { userRoleHumanized } from '../../utils/constants';
import { getFullNameInitials } from '../../utils/get-full-name-initials';

interface UserMenuProps {
  user: User;
  onLogout: () => void;
}

export function UserMenu({ onLogout, user }: UserMenuProps) {
  return (
    <Menu>
      <Menu.Target>
        <UnstyledButton>
          <Group>
            <Avatar radius={17.5} size={35} color="orange">
              {getFullNameInitials(user.username)}
            </Avatar>
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown
        miw={200}
        ml={-16}
        mt={-8}
        style={{ position: 'absolute' }}
      >
        <Flex direction="column" ml={8} mt={8}>
          <Text>{user.username}</Text>
          <Text size="xs" color="dimmed">
            {user.email}
          </Text>
          <Text size="xs" color="orange.4">
            {userRoleHumanized?.[user.role]}
          </Text>
        </Flex>
        <Menu.Item
          onClick={onLogout}
          fw="bold"
          icon={
            <ThemeIcon variant="light" color="violet">
              <IconLogout size={16} />
            </ThemeIcon>
          }
        >
          Sair
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
