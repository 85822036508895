import {
  Button,
  Collapse,
  Flex,
  Group,
  Paper,
  Select,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconFilter, IconFilterOff } from '@tabler/icons';
import { ReactNode } from 'react';
import { FilterFormType } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';

import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
  GetFilterTeamsListResponse,
} from '../../../utils/types/data/services/filters';

type PCPListPageFilterProps = {
  isCollapsed: boolean;
  formFilter: UseFormReturnType<
    FilterFormType,
    (values: FilterFormType) => FilterFormType
  >;
  customInputs?: () => ReactNode;
  handleSubmit(values: FilterFormType): void;
  companyList: GetFilterCompaniesListResponse[];
  serviceList: GetFilterServicesListResponse[];
  teamList: GetFilterTeamsListResponse[];
  handleClear: () => void;
};

type OrderListFilterButtonProps = {
  onClick: () => void;
  isCollapsed: boolean;
};

const FilterButton = ({
  isCollapsed,
  ...props
}: OrderListFilterButtonProps) => {
  return (
    <Button
      variant="light"
      color="blue"
      leftIcon={isCollapsed ? <IconFilterOff /> : <IconFilter />}
      {...props}
    >
      Filtros
    </Button>
  );
};

const PCPListPageFilter = ({
  isCollapsed,
  formFilter,
  handleSubmit,
  companyList,
  serviceList,
  handleClear,
  customInputs,
  teamList,
}: PCPListPageFilterProps) => {
  return (
    <Collapse in={isCollapsed}>
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} withBorder>
          <Flex wrap="wrap">
            {customInputs ? (
              customInputs()
            ) : (
              <>
                <TextInput
                  label="OS"
                  placeholder="numero da OS"
                  mb={16}
                  mr={8}
                  type="text"
                  name="os"
                  {...formFilter.getInputProps('os')}
                />
                <TextInput
                  label="Produto"
                  placeholder="nome do produto"
                  mb={16}
                  mr={8}
                  type="text"
                  name="os"
                  {...formFilter.getInputProps('product')}
                />
                <Select
                  searchable
                  name="company"
                  label="Clientes"
                  placeholder="selecione o cliente"
                  data={companyList?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('company')}
                />
                <Select
                  searchable
                  clearable
                  name="team"
                  label="Equipe"
                  placeholder="selecione uma equipe"
                  data={teamList?.map((item) => ({
                    label: item.name,
                    value: String(item.id),
                  }))}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('team')}
                />
                <Select
                  searchable
                  clearable
                  name="service"
                  label="Serviço"
                  placeholder="selecione um serviço"
                  data={serviceList?.map((item) => ({
                    label: item.name,
                    value: String(item.id),
                  }))}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('service')}
                />
                <CustomDatePicker
                  withAsterisk={false}
                  required={false}
                  placeholder="selecione uma data"
                  label="Finalizado em"
                  lang="br"
                  name="finishedAt"
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('finishedAt')}
                />
              </>
            )}
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Group>
        </Paper>
      </form>
    </Collapse>
  );
};

PCPListPageFilter.Button = FilterButton;

export default PCPListPageFilter;
