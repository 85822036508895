export enum PostalStatus {
  PENDING = 'pending',
  INVOICED = 'invoiced',
}

export type Postal = {
  id: number;
  code: string;
  user: string;
  status: PostalStatus;
  invoicedBy: string;
  receiver: string;
  postalType: string;
  description: string;
  price: string;
  taxPrice: string;
  totalPrice: string;
  addressStreet: string;
  addressNumber: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  addressZipCode: string;
  invoicedAt: null | Date;
  createdAt: Date;
};
