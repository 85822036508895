import { ThemeIcon, NavLink } from '@mantine/core';
import { IconCash } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface BillsLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function BillsLink({ navigate, themeColor }: BillsLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Contas"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="blue.9" variant="light">
          <IconCash size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Nova"
        onClick={() => navigate(AppRoutePaths.BILLS_CREATE)}
      />
      <NavLink label="Listagem" onClick={() => navigate(AppRoutePaths.BILLS)} />
    </NavLink>
  );
}
