import { HandlingType } from '../../models/order-handling';

export const orderHandlingTypeHumanized = {
  [HandlingType.HANDLING]: 'manuseio',
  [HandlingType.PAUSE]: 'em pausa',
  [HandlingType.ASSEMBLE]: 'montar peça',
  [HandlingType.DISASSEMBLE]: 'desmontar peça',
  [HandlingType.PRESS]: 'prensa',
  [HandlingType.PRINT]: 'impressão',
  [HandlingType.RESIN]: 'resina',
};
