import { useFetch } from '../../../hooks/use-fetch';
import { Option } from '../../../models/option';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateOptionProps,
  UseDeleteOptionProps,
  UseListOptionProps,
  UseUpdateOptionProps,
} from '../../../utils/types/data/hooks/options';
import {
  createOptionRequest,
  deleteOptionRequest,
  listOptionsRequest,
  showOptionRequest,
  updateOptionRequest,
} from '../../services/options';

export function useCreateOption() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Option>();

  const fetch = ({ data, onSuccess }: UseCreateOptionProps) =>
    fetchData({
      fetcher: () => createOptionRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar opção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useUpdateOption() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Option>();

  const fetch = ({ id, data, onSuccess }: UseUpdateOptionProps) =>
    fetchData({
      fetcher: () => updateOptionRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar opção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useShowOption() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Option>();

  const fetch = ({ id }: { id: number }) =>
    fetchData({
      fetcher: () => showOptionRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar opção.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListOptions() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Option[]>();

  const fetch = ({ query }: UseListOptionProps) =>
    fetchData({
      fetcher: () => listOptionsRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar opções.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useDeleteOptions() {
  const {
    fetchData,
    state: { error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseDeleteOptionProps) =>
    fetchData({
      fetcher: () => deleteOptionRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao deletar opção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    error,
    loading,
  };
}
