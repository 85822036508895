import { useEffect, useState } from 'react';
import { dayjsPlugins } from '../providers/dayjs-plugins';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

interface UseTimerProps {
  deadline: string;
  interval?: number;
}

export function useTimer({ deadline, interval = SECOND }: UseTimerProps) {
  const [timespan, setTimespan] = useState(
    dayjsPlugins().diff(dayjsPlugins(deadline), 'milliseconds'),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimespan(dayjsPlugins().diff(dayjsPlugins(deadline), 'milliseconds'));
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval, deadline, timespan]);

  useEffect(() => {
    setTimespan(dayjsPlugins().diff(dayjsPlugins(deadline), 'milliseconds'));
  }, [deadline]);

  return {
    days: Math.floor(timespan / DAY),
    hours: Math.floor((timespan / HOUR) % 24),
    minutes: Math.floor((timespan / MINUTE) % 60),
    seconds: Math.floor((timespan / SECOND) % 60),
    span: timespan,
  };
}
