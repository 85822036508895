import { Checkbox, Group, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface EtiquetaResinadaFormItemProps {
  form: UseFormReturnType<any>;
}

export function EtiquetaResinadaEditItem({
  form,
}: EtiquetaResinadaFormItemProps) {
  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem manuseio de aplicação no produto acima ?"
        description="(embalagem/desembalagem dos produtos)"
        color="green"
        name="hasManipulation"
        mb={16}
        checked={form.values.details?.hasManipulation}
        {...form.getInputProps('details.hasManipulation')}
      />
      <Checkbox
        label="É adesivo vinil fundo transparente ?"
        color="green"
        name="isTransparent"
        mb={16}
        checked={form.values.details.isTransparent}
        {...form.getInputProps('details.isTransparent')}
      />
    </>
  );
}
