import { PostalStatus } from '../../models/postal';

export const postalStatusHumanized: Record<string, string> = {
  [PostalStatus.INVOICED]: 'faturado',
  [PostalStatus.PENDING]: 'pendente',
};
export const postalStatusColor = {
  [PostalStatus.INVOICED]: 'green',
  [PostalStatus.PENDING]: 'orange',
};
