import { AxiosResponse } from 'axios';
import { OrderPacking, PackingType } from '../../models/order-packing';
import { api } from '../../providers/base-api';

export type CreateOrderPacking = {
  type: PackingType;
  description?: string;
};

export type SetPackUnpackOrderPackingsRequestData = {
  amount: number;
  defectAmount: number;
};

export async function getOrderPackingsRequest(
  orderId: number,
): Promise<AxiosResponse<OrderPacking[]>> {
  const response = await api.get<OrderPacking[]>(`order-packings/${orderId}`);

  return response;
}

export async function getLastOrderPackingRequest(
  orderId: number,
): Promise<AxiosResponse<OrderPacking>> {
  const response = await api.get<OrderPacking>(
    `order-packings/last/${orderId}`,
  );

  return response;
}

export async function setOrderPackingRequest(
  orderId: number,
  data: CreateOrderPacking,
): Promise<AxiosResponse<OrderPacking>> {
  const response = await api.post<OrderPacking>(
    `order-packings/${orderId}`,
    data,
  );

  return response;
}

export async function setOutOrderPackingRequest(
  id: number,
): Promise<AxiosResponse<OrderPacking>> {
  const response = await api.put<OrderPacking>(`order-packings/set-out/${id}`);

  return response;
}

export async function setPackUnpackOrderPackingClockoutRequest(
  id: number,
  data: SetPackUnpackOrderPackingsRequestData,
): Promise<AxiosResponse<OrderPacking>> {
  const response = await api.put<OrderPacking>(
    `order-packings/pack-unpack-set-out/${id}`,
    data,
  );

  return response;
}
