import { Checkbox, Group, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface DTFUVFormItemProps {
  form: UseFormReturnType<any>;
}

export function DTFUVFormItem({ form }: DTFUVFormItemProps) {
  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem manuseio de aplicação ?"
        description="aplicação no produto acima"
        color="green"
        name="hasManipulation"
        mb={16}
        {...form.getInputProps('details.hasManipulation')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}
