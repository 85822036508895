import { Company } from './company';
import { Team } from './team';

export enum UserRole {
  COMPANY = 'company',
  VENDOR = 'vendor',
  RECEPTIONIST = 'receptionist',
  MANAGER = 'manager',
  OTHER = 'other',
  TECHNICAL = 'technical',
  FINAL_ART = 'final_art',
  PACKER = 'packer',
  HANDLER = 'handler',
  FINANCIAL = 'financial',
}

export enum UserType {
  MASTER = 'master',
  USER = 'user',
}

export type User = {
  id: number;
  username: string;
  password: string;
  email: string;
  role: UserRole;
  type: UserType;
  isActive: boolean;
  isMain: boolean;
  team: number | null;
  company: number | null;
  createdAt: string;
};

export type UserPopulated = Omit<User, 'company' | 'team' | 'role'> & {
  team: Team;
  company: Company;
  role: UserRole;
};
