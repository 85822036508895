import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';

export async function removeOrderFileRequest(
  fileId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`order-files/${fileId}`);

  return response;
}
