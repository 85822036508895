import {
  Text,
  Image,
  Title,
  Alert,
  Button,
  Group,
  Container,
  Paper,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components/Page';
import { IconAlertCircle } from '@tabler/icons';
import { AppRoutePaths } from '../../utils/enums';
import { appConfig } from '../../config';

export function PageNotFound() {
  localStorage.setItem('redirect_url', window.location.pathname);

  const navigation = useNavigate();

  function handleNavigate() {
    navigation({
      pathname: AppRoutePaths.LOGIN,
    });
  }

  return (
    <Page>
      <Container p={0}>
        <Paper maw={500} mt={75} radius={8} p={24} shadow="lg">
          <Image src={appConfig.sidebarImage} />
          <Title align="center" order={1} size={80}>
            404
          </Title>
          <Text align="center">Página não encontrada!</Text>
          <Alert
            mb={16}
            mt={16}
            icon={<IconAlertCircle size={16} />}
            title="Ops!"
            color="orange"
            radius="lg"
          >
            Você não devia estar aqui, clique no botão abaixo para voltar.
          </Alert>
          <Group align="center" grow>
            <Button color="orange" onClick={handleNavigate}>
              <Text size={20} align="center">
                Voltar
              </Text>
            </Button>
          </Group>
        </Paper>
      </Container>
    </Page>
  );
}
