import { AxiosResponse } from 'axios';
import { Company } from '../../models/company';
import { Order, OrderPaymentStatus } from '../../models/order';
import { OrderPaymentCustomResponse } from '../../models/order-payment';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  GenerateServiceNfToPaymentRequestData,
  GetFincialNFProviderResponse,
  GetOrderFilesToApproveRequestQuery,
  GetOrderFilesToApproveResponse,
  GetOrderPaymentsReportRequestQuery,
  GetOrdersByPaymentResponseData,
  OrderFilesApprovalRequestData,
} from '../../utils/types/data/services/financial';

export type EmmitNFSEData = {
  discrimination: string;
  taxesPercentage: number;
  serviceCode: string;
  orderPrice: number;
  financialNote: string;
  orders: number[];
  postals?: number[];
  companyDocumentId: number;
  emiterCNPJ: string;
};

export type SetOrderInvoicedData = {
  financialNote: string;
  paymentDeadline: Date;
  orderIds: number[];
  postals?: number[];
};

export type SetOrderPaymentData = {
  paymentStatus: OrderPaymentStatus;
  paymentNote?: string;
  transactionType: string;
  paidAt: Date;
  paymentDeadline: Date;
  paymentValue: string;
  taxValue?: string;
  billetNumber?: string;
};

export type ShowServiceNfResponse = {
  cnpj_prestador: string;
  ref: string;
  numero_rps: string;
  serie_rps: string;
  tipo_rps: string;
  status: string;
  numero: string;
  codigo_verificacao: string;
  data_emissao: string;
  url: string;
  caminho_xml_nota_fiscal: string;
  url_danfse: string;
};

export type CreateServiceNfResponse = {
  cnpj_prestador: string;
  ref: string;
  numero_rps: string;
  serie_rps: string;
  tipo_rps: string;
  status: string;
};

export type GetOrderInvoicesParams = {
  page: number;
  limit: number;
  os?: string[];
  status?: string;
  paymentStatus?: OrderPaymentStatus;
  paymentDeadline?: string;
  paidAt?: string;
  issueDate?: string;
  financialStatus?: string;
  financialNote?: string;
  paymentType?: string;
  paymentNote?: string;
  team?: number;
  createdAt?: string;
  receiptCode?: string;
};

export async function getCompanyToFinance(id: number): Promise<Company> {
  const response = await api.get<Company>(`companies/finance/${id}`);

  return response.data;
}

export async function emmitNFSeRequest(
  data: EmmitNFSEData,
): Promise<CreateServiceNfResponse> {
  const response = await api.post<CreateServiceNfResponse>(
    `financial/nfse`,
    data,
  );

  return response.data;
}

export async function generateNFSeToPaymentRequest(
  paymentId: number,
  data: GenerateServiceNfToPaymentRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`financial/${paymentId}/nfse`, data);

  return response;
}

export async function showNFSeRequest(
  orderId: number,
): Promise<ShowServiceNfResponse> {
  const response = await api.get<ShowServiceNfResponse>(
    `financial/nfse/${orderId}`,
  );

  return response.data;
}

export async function cancelNFSeRequest(paymentId: number): Promise<void> {
  await api.delete<void>(`financial/nfse/${paymentId}`);
}

export async function cancelPaymentRequest(
  paymentId: number,
): Promise<AxiosResponse<void>> {
  return await api.delete<void>(`financial/${paymentId}`);
}

export async function setOrderInvoicedRequest(
  data: SetOrderInvoicedData,
): Promise<void> {
  await api.post<void>(`financial/invoiced`, data);
}

export async function setOrderPaymentRequest(
  orderId: number,
  data: SetOrderPaymentData,
): Promise<void> {
  await api.post<void>(`financial/payment/${orderId}`, data);
}

export async function getOrderInvoicesRequest(
  params: GetOrderInvoicesParams,
): Promise<PaginatedResponse<Order>> {
  const response = await api.get<PaginatedResponse<Order>>(
    `financial/order-invoices`,
    { params },
  );

  return response.data;
}

export async function getOrderPaymentsRequest(
  params: GetOrderInvoicesParams,
): Promise<PaginatedResponse<OrderPaymentCustomResponse>> {
  const response = await api.get<PaginatedResponse<OrderPaymentCustomResponse>>(
    `financial/order-payments`,
    { params },
  );

  return response.data;
}

export async function getOrderPaymentsReportRequest(
  params: GetOrderPaymentsReportRequestQuery,
): Promise<PaginatedResponse<OrderPaymentCustomResponse>> {
  const response = await api.get<PaginatedResponse<OrderPaymentCustomResponse>>(
    `financial/payments-report`,
    { params },
  );

  return response.data;
}

export async function getOrdersByPaymentRequest(
  paymentId: number,
): Promise<AxiosResponse<GetOrdersByPaymentResponseData[]>> {
  const response = await api.get<GetOrdersByPaymentResponseData[]>(
    `financial/by-payment/${paymentId}`,
  );

  return response;
}

export async function getNfProvidersRequest(): Promise<
  AxiosResponse<GetFincialNFProviderResponse[]>
> {
  const response = await api.get<GetFincialNFProviderResponse[]>(
    `financial/nf-providers`,
  );

  return response;
}

export async function setOrderPaymentPaidRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.put(`financial/set-paid/${id}`);

  return response;
}

export async function getOrderFilesReceiptToApproveRequest(
  query: GetOrderFilesToApproveRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<GetOrderFilesToApproveResponse>>> {
  const response = await api.get<
    PaginatedResponse<GetOrderFilesToApproveResponse>
  >(`financial/order-file-receipts`, { params: query });

  return response;
}

export async function orderFilesReceiptApprovalRequest(
  fileId: number,
  data: OrderFilesApprovalRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `financial/file-receipt-approval/${fileId}`,
    data,
  );

  return response;
}
