import {
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Radio,
  Select,
  Stepper,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomMultipleFileInput } from '../../../components/CustomMutipleFileInput';
import { rootNavigateGoBack } from '../../../components/CustomRouter';
import { RootState } from '../../../providers/store';
import { textCapitalize } from '../../../utils/helpers';
import { GetUserCompaniesResponseData } from '../../../utils/types/data/services/companies';

interface CompanyCreateOrderMobileProps {
  navigation: NavigateFunction;
  form: UseFormReturnType<any>;
  handleSubmit(values: any): void;
  services: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
  handleSelectService(id: string): void;
  servicerFormRender: any;
  selectedService: string;
  getUserCompaniesData: GetUserCompaniesResponseData[] | undefined;
}

export function CompanyCreateOrderMobile({
  form,
  handleSubmit,
  services,
  handleSelectService,
  servicerFormRender,
  selectedService,
  getUserCompaniesData,
}: CompanyCreateOrderMobileProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    form.setFieldValue('company', user?.company);
  }, []);

  return (
    <Container>
      <Grid gutter="xs" columns={5}>
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center"></Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex align="center" justify="end"></Flex>
        </Grid.Col>
      </Grid>
      <Title size={40} fw="bolder" color="orange" order={1} align="center">
        Novo Pedido
      </Title>
      <Divider m={16} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stepper
          color="orange"
          orientation="horizontal"
          iconSize={37}
          active={0}
        >
          <Stepper.Step label="Pedido" description="dados do pedido">
            <Select
              searchable
              required
              withAsterisk
              name="company"
              label="Empresa"
              placeholder="selecione a empresa do pedido"
              data={
                getUserCompaniesData?.map((item) => ({
                  value: item.id,
                  label: item.name,
                })) ?? []
              }
              mb={16}
              {...form.getInputProps('company')}
            />
            <TextInput
              label="Identificação interna"
              placeholder="código customizavel para identificar o pedido"
              mb={16}
              type="text"
              name="internalNumber"
              value={form.values.internalNumber}
              maxLength={255}
              {...form.getInputProps('internalNumber')}
            />
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Produto"
                placeholder="descreva o produto ex: Caneta, Caneca"
                mb={16}
                type="text"
                name="product"
                value={form.values.product}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'product',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <NumberInput
                required
                name="quantity"
                withAsterisk
                label="Quantidade"
                placeholder="digite a quantidade"
                mb={16}
                min={0}
                {...form.getInputProps('quantity')}
              />
            </Group>
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Cor"
                placeholder="cor do produto"
                mb={16}
                type="text"
                value={form.values.productColor}
                onChange={(event) =>
                  form.setFieldValue(
                    'productColor',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <TextInput
                required
                withAsterisk
                label="Material"
                placeholder="descreva se ex: plástico, metal e etc..."
                mb={16}
                type="text"
                value={form.values.productMaterial}
                onChange={(event) =>
                  form.setFieldValue(
                    'productMaterial',
                    textCapitalize(event.target.value),
                  )
                }
              />
            </Group>
            <Select
              required
              name="service"
              withAsterisk
              searchable
              label="Serviço"
              placeholder="selecione um serviço"
              data={services}
              mb={16}
              onChange={handleSelectService}
            />
            {selectedService !== '' &&
              servicerFormRender?.[selectedService as any] &&
              servicerFormRender[selectedService as any]()}
            <TextInput
              withAsterisk
              required
              label="Nome do logotipo"
              placeholder="texto da gravação"
              mb={16}
              maxLength={255}
              type="text"
              name="engravingText"
              {...form.getInputProps('engravingText')}
            />
            <Group grow>
              <Checkbox
                label="Precisa de amostra?"
                description="(Você está criando uma amostra além do pedido)"
                color="green"
                name="needSample"
                mt={16}
                mb={16}
                disabled={form.values.isSample}
                w={300}
                {...form.getInputProps('needSample')}
              />
              <Checkbox
                label="É uma amostra?"
                description="(Você está criando apenas uma OS de amostra)"
                color="green"
                name="isSample"
                mt={16}
                mb={16}
                w={300}
                disabled={form.values.needSample}
                {...form.getInputProps('isSample')}
              />
            </Group>
            <TextInput
              label="Fornecedor"
              placeholder="nome do fornecedor"
              mb={16}
              type="text"
              name="supplier"
              value={form.values.supplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'supplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            <TextInput
              label="Código do produto"
              placeholder="para identificação do produto no fornecedor"
              mb={16}
              type="text"
              name="productCode"
              value={form.values.productCodeSupplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'productCodeSupplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            <Radio.Group
              name="orderAccess"
              label="Chegada do material na empresa"
              description="nos diga como vamos receber o material"
              withAsterisk
              required
              {...form.getInputProps('orderAccess')}
            >
              <Radio
                value="delivered"
                label="o pedido será entregue na empresa"
              />
              <Radio value="collected" label="a empresa irá retirar o pedido" />
            </Radio.Group>
            {form.values.orderAccess === 'collected' ? (
              <>
                <TextInput
                  withAsterisk
                  label="Local da retirada"
                  placeholder="nome do fornecedor + endereço completo"
                  mb={16}
                  type="text"
                  name="pickUpLocation"
                  maxLength={255}
                  {...form.getInputProps('pickUpLocation')}
                />
                <TextInput
                  withAsterisk
                  label="Resposável pela retirada"
                  placeholder="com quem falar na retirada"
                  mb={16}
                  type="text"
                  name="pickUpName"
                  maxLength={255}
                  {...form.getInputProps('pickUpName')}
                />
                <TextInput
                  withAsterisk
                  label="Codigo para identificação no fornecedor"
                  placeholder="necessário para retirada de pedidos na SPOT,ASIA"
                  mb={16}
                  type="text"
                  name="pickUpNumber"
                  maxLength={255}
                  {...form.getInputProps('pickUpNumber')}
                />
                <CustomDatePicker
                  label="Previsão para retirada de material"
                  value={form.values.pickUpForecast}
                  description="data em que devemos retirar o material no portador"
                  {...form.getInputProps('pickUpForecast')}
                />
              </>
            ) : (
              <>
                <TextInput
                  withAsterisk
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  maxLength={255}
                  {...form.getInputProps('carrierName')}
                />
                <CustomDatePicker
                  label="Previsão de recebimento do material"
                  value={form.values.receiptForecast}
                  description="data em que o material deve chegar"
                  {...form.getInputProps('receiptForecast')}
                />
              </>
            )}
            <CustomDatePicker
              label="Prazo de finalização"
              value={form.values.finishForecast}
              description="nos diga a data que seu pedido precisa estar pronto"
              {...form.getInputProps('finishForecast')}
            />
            <Textarea
              maxLength={255}
              mt={16}
              label="Observações"
              placeholder="escreva aqui qualquer observação que queira fazer sobre seu pedido"
              name="note"
              {...form.getInputProps('note')}
            />
            <CustomMultipleFileInput
              name="files"
              accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
              label="Arquivos"
              placeholder="selecione arquivo por vez"
              formRef={form}
            />
          </Stepper.Step>
        </Stepper>
        <Group position="right" mt="xl">
          <Button color="ltpBlue.9" type="submit">
            Salvar
          </Button>
        </Group>
      </form>
    </Container>
  );
}
