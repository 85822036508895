import { Button, Card, Flex, Image, Text } from '@mantine/core';
import { IconFile } from '@tabler/icons';
import { File } from '../../models/file';

export interface FilePreviewProps {
  file: File;
  onDelete?: () => void;
  onDownload?: (url: string) => void;
  fileSizes?: { width: number; height: number };
}

export function FilePreview({
  file,
  onDelete,
  onDownload,
  fileSizes,
}: FilePreviewProps) {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      mt={16}
      p={8}
      withBorder
    >
      {onDelete && (
        <Button
          color="red"
          p={0}
          m={0}
          w={20}
          h={20}
          pos="absolute"
          variant="light"
          style={{ zIndex: 100, top: 8, right: 8 }}
          onClick={onDelete}
        >
          x
        </Button>
      )}
      <Image
        width={fileSizes ? fileSizes.width : 100}
        height={fileSizes ? fileSizes.height : 100}
        src={file.url}
        alt="file preview"
        withPlaceholder
      />
      <Flex
        onClick={
          onDownload
            ? () =>
                onDownload(
                  file.url.includes('http') ? file.url : `https://${file.url}`,
                )
            : undefined
        }
        align="center"
      >
        <IconFile size={14} style={{ marginRight: 5 }} />
        <a
          target="_blank"
          href={
            !onDownload
              ? file.url.includes('http')
                ? file.url
                : `https://${file.url}`
              : undefined
          }
          style={{ display: 'flex', cursor: 'pointer' }}
          rel="noreferrer"
        >
          <Text
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: 100,
              display: 'inline-block',
            }}
          >
            {file.name}
          </Text>
          <Text>{file.ext}</Text>
        </a>
      </Flex>
    </Card>
  );
}
