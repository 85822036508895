export const receiptInstalments = [
  { label: 'A VISTA', value: '0' },
  { label: '1x %0,97', value: '1x %0,97' },
  { label: '2x %0,96', value: '2x %0,96' },
  { label: '3x %0,94', value: '3x %0,94' },
  { label: '4x %0,92', value: '4x %0,92' },
  { label: '5x %0,90', value: '5x %0,90' },
  { label: '6x %0,88', value: '6x %0,88' },
  { label: '7x %0,86', value: '7x %0,86' },
  { label: '8x %0,84', value: '8x %0,84' },
  { label: '9x %0,82', value: '9x %0,82' },
  { label: '10x %0,80', value: '10x %0,80' },
  { label: '11x %0,78', value: '11x %0,78' },
  { label: '12x %0,76', value: '12x %0,76' },
];
