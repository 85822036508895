import { createContext, ReactNode } from 'react';
import { useNotifications } from '../hooks/use-notifications';
import { Notification } from '../models/notification';

interface NotificationContext {
  list: Notification[];
  getNotifications: () => void;
  readNotifications: (ids: number[]) => void;
  setNotifications: (list: Notification[]) => void;
}

export const NotificationContext = createContext<NotificationContext>({
  list: [],
  getNotifications: () => {},
  readNotifications: () => {},
  setNotifications: () => {},
});

const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { listNotifications, setNotificationsReaded, notifications, set } =
    useNotifications();

  return (
    <NotificationContext.Provider
      value={{
        list: notifications,
        getNotifications: listNotifications,
        readNotifications: setNotificationsReaded,
        setNotifications: set,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
