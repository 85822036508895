import { Order } from './order';
import { User } from './user';

export enum HandlingType {
  HANDLING = 'handling',
  PAUSE = 'pause',
  PRINT = 'print',
  PRESS = 'press',
  RESIN = 'resin',
  DISASSEMBLE = 'disassemble',
  ASSEMBLE = 'assemble',
}

export type OrderHandling = {
  id: number;
  order: Order;
  clockIn: string;
  clockOut: string | null;
  user: User;
  type: HandlingType;
  timeAmount: number;
  description: string;
  amount: number;
  defectAmount: number;
};
