import {
  ActivityReport,
  ArtActivity,
  ArtProductionType,
  PopulatedArtActivity,
} from '../../models/art-activity';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';

export type CreateArtActivityData = {
  type: ArtProductionType;
};

export type GetArtActivityReportParams = {
  team?: number;
  company?: number;
  artUser?: number;
  clockIn?: string;
};

export async function createArtActivityRequest(
  activityId: number,
  data: CreateArtActivityData,
): Promise<PopulatedArtActivity> {
  const response = await api.post<PopulatedArtActivity>(
    `art-activities/${activityId}`,
    data,
  );

  return response.data;
}

export async function setArtActivityClockoutRequest(
  id: number,
): Promise<ArtActivity> {
  const response = await api.put<ArtActivity>(`art-activities/clock-out/${id}`);

  return response.data;
}

export async function getUserLasArtActivityRequest(
  activityId: number,
): Promise<PopulatedArtActivity> {
  const response = await api.get<PopulatedArtActivity>(
    `art-activities/${activityId}`,
  );

  return response.data;
}

export async function getArtActivityListRequest(
  activityId: number,
): Promise<PopulatedArtActivity[]> {
  const response = await api.get<PopulatedArtActivity[]>(
    `art-activities/list/${activityId}`,
  );

  return response.data;
}

export async function getArtActivityReportRequest(
  params: GetArtActivityReportParams,
): Promise<PaginatedResponse<ActivityReport>> {
  const response = await api.get<PaginatedResponse<ActivityReport>>(
    `art-activities/report`,
    {
      params,
    },
  );

  return response.data;
}
