import {
  Button,
  Flex,
  Grid,
  Group,
  Image,
  Paper,
  Select,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCoin, IconSearch } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomDatePicker } from '../../components/CustomDatePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { GoalCard } from '../../components/GoalCard';
import { Page } from '../../components/Page';
import { appConfig } from '../../config';
import { useGetOrdersGoalMetrics } from '../../data/hooks/orders';
import { getFilterTeamsListRequest } from '../../data/services/filters';
import { TeamType } from '../../models/team';
import { UserRole, UserType } from '../../models/user';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { formatBRL } from '../../utils/helpers';
import { GetFilterTeamsListResponse } from '../../utils/types/data/services/filters';

const userGuide: any = {
  vendor:
    'https://seasoned-neon-d8b.notion.site/Guia-de-usu-rio-vendedor-635fa9c111b845f796db45f647e51bc3?pvs=4',
  company:
    'https://seasoned-neon-d8b.notion.site/Guia-do-usu-rio-cliente-2ec53b1d1ef045b29fca64230302c1ce?pvs=4',
  final_art:
    'https://seasoned-neon-d8b.notion.site/Guia-de-usu-rio-arte-final-b35e8acb44db47449563f5ea46b1f9c9?pvs=4',
  technical:
    'https://seasoned-neon-d8b.notion.site/Guia-de-usu-rio-produ-o-547626a71a4a4833a1a736919f148d70?pvs=4',
  packer:
    'https://seasoned-neon-d8b.notion.site/Guia-de-usu-rio-embalagem-1-130471eb04584ba78b64a8827042d076?pvs=4',
  financial:
    'https://seasoned-neon-d8b.notion.site/Guia-de-usu-rio-financeiro-0af2322518694ae4af33d97839b59d00?pvs=4',
};

const userGuideImage: any = {
  vendor:
    'https://orders-files.nyc3.cdn.digitaloceanspaces.com/media%2Fguia-vendedor-thumb.png',
  company:
    'https://orders-files.nyc3.cdn.digitaloceanspaces.com/media%2Fguia-cliente-thumb.png',
  final_art:
    'https://orders-files.nyc3.cdn.digitaloceanspaces.com/media%2Fguia-art-final-thumb.png',
  technical:
    'https://orders-files.nyc3.cdn.digitaloceanspaces.com/media%2Fguia-producao-thumb.png',
  packer:
    'https://orders-files.nyc3.cdn.digitaloceanspaces.com/media%2Fguia-embalagem-thumb.png',
  financial:
    'https://orders-files.nyc3.cdn.digitaloceanspaces.com/media/guia-financeiro-thumb.png',
};

type MetricGoalFilter = {
  interval?: string;
  status?: string;
};

export function Home() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const {
    fetch: getOrderMetricsFetcher,
    loading: getOrderMetricsLoader,
    response: getOrderMetricsResponse,
  } = useGetOrdersGoalMetrics();

  const formMetricGoalFilter = useForm<MetricGoalFilter>({
    initialValues: {
      interval: dayjsPlugins().format('YYYY-MM-DD'),
      status: '',
    },
  });

  async function getVendorTeam() {
    try {
      setPageLoading(true);
      const response = await getFilterTeamsListRequest({
        type: TeamType.VENDOR,
      });
      setTeamList(response);
      setSelectedTeam(
        user?.type === UserType.MASTER ? response[0].id : user?.team,
      );
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function getMetricsGoals() {
    const validatedFilter: any = {};

    Object.entries(formMetricGoalFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    await getOrderMetricsFetcher({
      query: validatedFilter,
    });
  }

  useEffect(() => {
    getVendorTeam();
    getMetricsGoals();
  }, []);

  return (
    <Page title="Home">
      <CustomLoader loading={getOrderMetricsLoader || pageLoading} />

      {user?.role &&
        [UserRole.VENDOR, UserRole.OTHER, UserRole.FINANCIAL].includes(
          user?.role,
        ) && (
          <>
            <form
              onSubmit={formMetricGoalFilter.onSubmit(() => getMetricsGoals())}
            >
              <Flex gap={'xs'} align={'flex-start'} justify={'center'} w={400}>
                {user?.type === UserType.MASTER && (
                  <Select
                    size="xs"
                    name="team"
                    placeholder="Equipe"
                    data={teamList?.map((item) => ({
                      label: item.name,
                      value: String(item.id),
                    }))}
                    onChange={(value) => {
                      setSelectedTeam(value);
                    }}
                    value={String(selectedTeam)}
                    mb={3}
                    searchable
                    w={150}
                  />
                )}
                <CustomDatePicker
                  placeholder="Data referência"
                  withAsterisk={false}
                  required={false}
                  lang="br"
                  name="interval"
                  onChange={(value) => {
                    value
                      ? formMetricGoalFilter.setFieldValue(
                          'interval',
                          dayjsPlugins(value).format('YYYY-MM-DD'),
                        )
                      : formMetricGoalFilter.setFieldValue(
                          'interval',
                          dayjsPlugins().format('YYYY-MM-DD'),
                        );
                  }}
                  size="xs"
                />
                <Group>
                  <Button size="xs" radius={'sm'} type="submit" variant="light">
                    <IconSearch />
                  </Button>
                </Group>
              </Flex>
            </form>

            {user?.type === UserType.MASTER && (
              <Grid grow w="100%" mb={'xs'}>
                <Grid.Col span={1}>
                  <GoalCard
                    currentGoal={formatBRL(
                      getOrderMetricsResponse?.goalTotalValue.toFixed(2),
                    )}
                    title="Meta Geral"
                    icon={
                      <ThemeIcon
                        radius="md"
                        size="xl"
                        color="yellow"
                        variant="outline"
                      >
                        <IconCoin />
                      </ThemeIcon>
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <GoalCard
                    currentGoal={formatBRL(
                      Number(getOrderMetricsResponse?.orderTotalPrice).toFixed(
                        2,
                      ),
                    )}
                    title="Vendidos Geral"
                    icon={
                      <ThemeIcon
                        radius="md"
                        size="xl"
                        color="yellow"
                        variant="outline"
                      >
                        <IconCoin />
                      </ThemeIcon>
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <GoalCard
                    currentGoal={formatBRL(
                      Number(
                        getOrderMetricsResponse?.paymentTotalValue,
                      ).toFixed(2),
                    )}
                    title="Pagos Geral"
                    icon={
                      <ThemeIcon
                        radius="md"
                        size="xl"
                        color="yellow"
                        variant="outline"
                      >
                        <IconCoin />
                      </ThemeIcon>
                    }
                  />
                </Grid.Col>
              </Grid>
            )}

            {getOrderMetricsResponse?.teams[selectedTeam] ? (
              <>
                <Grid grow mb={'xs'} w="100%">
                  <Grid.Col span={1}>
                    <GoalCard
                      currentGoal={formatBRL(
                        getOrderMetricsResponse?.teams[
                          selectedTeam
                        ].goal.toFixed(2),
                      )}
                      title={`Meta de ${getOrderMetricsResponse?.teams[selectedTeam].name}`}
                      icon={
                        <ThemeIcon
                          radius="md"
                          size="xl"
                          color="yellow"
                          variant="outline"
                        >
                          <IconCoin />
                        </ThemeIcon>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <GoalCard
                      currentGoal={formatBRL(
                        Number(
                          getOrderMetricsResponse?.teams[selectedTeam].created
                            ?.total,
                        ).toFixed(2),
                      )}
                      title={`OS's Criadas ${getOrderMetricsResponse?.teams[selectedTeam].name}`}
                      icon={
                        <ThemeIcon
                          radius="md"
                          size="xl"
                          color="yellow"
                          variant="outline"
                        >
                          <IconCoin />
                        </ThemeIcon>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <GoalCard
                      currentGoal={`${formatBRL(
                        getOrderMetricsResponse?.teams[
                          selectedTeam
                        ].total.toFixed(2) || '0',
                      )} / ${formatBRL(
                        getOrderMetricsResponse?.teams[
                          selectedTeam
                        ].goal.toFixed(2) || '0',
                      )}`}
                      title={`Vendidos por ${getOrderMetricsResponse?.teams[selectedTeam].name}`}
                      icon={
                        <ThemeIcon
                          radius="md"
                          size="xl"
                          color="yellow"
                          variant="outline"
                        >
                          <IconCoin />
                        </ThemeIcon>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <GoalCard
                      currentGoal={formatBRL(
                        Number(
                          getOrderMetricsResponse?.teams[selectedTeam]
                            .paymentValue,
                        ).toFixed(2),
                      )}
                      title={`Pagos ${getOrderMetricsResponse?.teams[selectedTeam].name}`}
                      icon={
                        <ThemeIcon
                          radius="md"
                          size="xl"
                          color="yellow"
                          variant="outline"
                        >
                          <IconCoin />
                        </ThemeIcon>
                      }
                    />
                  </Grid.Col>
                </Grid>
              </>
            ) : (
              <Text fz={'sm'} fw={'bold'}>
                Sem métricas
              </Text>
            )}
          </>
        )}

      <Paper m={16} p={16} shadow="sm" w="99%">
        <Title size={24}>Bem-vindo(a) a {appConfig.name} 🥳🎉</Title>
        <Text>
          Esta é sua tela inicial, navegue pelo menu lateral no canto esquerdo.
        </Text>
        <Text>
          Saiba mais sobre as funcionalidades clicando no botão abaixo
        </Text>
        {user && (
          <>
            <Image maw={800} src={userGuideImage?.[user?.role]} placeholder />
            <a href={userGuide?.[user?.role]} target="_blank" rel="noreferrer">
              <Button mt={8} color="ltpBlue.9">
                Ver Guia
              </Button>
            </a>
          </>
        )}
      </Paper>
    </Page>
  );
}
