import { useFetch } from '../../../hooks/use-fetch';
import { CompanyDocument } from '../../../models/company-document';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseAddCompanyDocumentsProps,
  UseGetCompanyDocumentsPaginatedProps,
  UseGetCompanyDocumentsProps,
  UseRemoveCompanyDocumentsProps,
} from '../../../utils/types/data/hooks/company-documents';
import { GetCompanyDocumentsPaginatedResponse } from '../../../utils/types/data/services/company-documents';
import {
  addCompanyDocumentsRequest,
  getCompanyDocumentsPaginatedRequest,
  getCompanyDocumentsRequest,
  removeCompanyDocumentsRequest,
} from '../../services/company-documents';

export function useGetCompanyDocuments() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<CompanyDocument[]>();

  const fetch = ({ companyId }: UseGetCompanyDocumentsProps) =>
    fetchData({
      fetcher: () => getCompanyDocumentsRequest(companyId),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(err, 'Erro ao buscar CNPJs.'),
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useAddCompanyDocuments() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseAddCompanyDocumentsProps) =>
    fetchData({
      fetcher: () => addCompanyDocumentsRequest(data),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(err, 'Erro ao adicionar CNPJs.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRemoveCompanyDocuments() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseRemoveCompanyDocumentsProps) =>
    fetchData({
      fetcher: () => removeCompanyDocumentsRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover CNPJs.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetCompanyDocumentsPaginated() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetCompanyDocumentsPaginatedResponse>();

  const fetch = ({ query }: UseGetCompanyDocumentsPaginatedProps) =>
    fetchData({
      fetcher: () => getCompanyDocumentsPaginatedRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar empresas.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
