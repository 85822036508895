import { Checkbox, Group, Text, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface DigitalUVFormItemProps {
  form: UseFormReturnType<any>;
}

export function DigitalUVEditItem({ form }: DigitalUVFormItemProps) {
  return (
    <>
      <Text size="sm" fw="normal">
        Tamanho da gravação
      </Text>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem aplicação de verniz ?"
        color="green"
        name="hasVarnish"
        mb={16}
        checked={form.values.details.hasVarnish}
        {...form.getInputProps('details.hasVarnish')}
      />
      <Checkbox
        label="Tem dados variados ?"
        value="dados variados"
        color="green"
        name="hasManyData"
        mb={16}
        checked={form.values.details.hasManyData}
        {...form.getInputProps('details.hasManyData')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        value={form.values.details.personalizationSides}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}
