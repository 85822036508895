import { NavLink, ThemeIcon } from '@mantine/core';
import { IconSettings } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface OptionsLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function OptionsLink({ navigate, themeColor }: OptionsLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Opções"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="indigo" variant="light">
          <IconSettings size={16} color="black" />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.OPTION_LIST)}
      />
    </NavLink>
  );
}
