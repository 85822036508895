import { ThemeIcon, NavLink } from '@mantine/core';
import { IconAffiliate } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface TeamsLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function TeamsLink({ navigate, themeColor }: TeamsLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Times"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="red" variant="light">
          <IconAffiliate size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Novo"
        onClick={() => navigate(AppRoutePaths.TEAMS_CREATE)}
      />
      <NavLink label="Listagem" onClick={() => navigate(AppRoutePaths.TEAMS)} />
    </NavLink>
  );
}
