import { useFetch } from '../../../hooks/use-fetch';
import { User } from '../../../models/user';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseCreateUserProps,
  UseUpdateUserProps,
} from '../../../utils/types/data/hooks/users';
import { createUserResquest, updateUserResquest } from '../../services/users';

export function useCreateUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<User>();

  const fetch = ({ data, onSuccess }: UseCreateUserProps) =>
    fetchData({
      fetcher: () => createUserResquest(data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao criar usuário.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdateUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<User>();

  const fetch = ({ id, data, onSuccess }: UseUpdateUserProps) =>
    fetchData({
      fetcher: () => updateUserResquest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao atualizar usuário.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
