import { Group, NumberInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';

interface EnvelopamentoFormItemProps {
  form: UseFormReturnType<any>;
}

export function EnvelopamentoFormItem({ form }: EnvelopamentoFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        height: '',
        width: '',
        personalizationArea: '',
        hasSample: false,
      },
    });
  }, []);

  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <TextInput
        required
        withAsterisk
        label="Local da personalização"
        placeholder="ex: canto superior esquerdo"
        mb={16}
        name="personalizationArea"
        maxLength={255}
        {...form.getInputProps('details.personalizationArea')}
      />
    </>
  );
}
