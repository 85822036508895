import {
  Badge,
  Button,
  Flex,
  Group,
  Modal,
  Paper,
  Textarea,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { ActivityLayoutApproveFormType, FilterLayoutsFormType } from '..';
import { ActivityLayoutItem } from '../../../components/ActivityLayout';
import { CustomMultipleFileInput } from '../../../components/CustomMutipleFileInput';
import {
  ActivityLayout,
  ActivityLayoutStatus,
} from '../../../models/activity-layout';
import { ActivityLayoutFile } from '../../../models/activity-layout-file';
import { formatLocale } from '../../../providers/dayjs-plugins';
import {
  activityLayoutStatusColor,
  activityLayoutStatusHumanized,
} from '../../../utils/constants/activity-layout';
import { PaginatedResponse } from '../../../utils/types';
import { ActivityLayoutsToApproveResponse } from '../../../utils/types/data/services/activity-layouts';

interface CompanyLayoutListProps {
  handleSelectLayout(item: ActivityLayoutsToApproveResponse): void;
  getAtivityLayoutToApproveData:
    | PaginatedResponse<ActivityLayoutsToApproveResponse>
    | undefined;
  handlePaginate(newPage: number): void;
  page: React.MutableRefObject<number>;
  selectedLayout: ActivityLayoutsToApproveResponse | undefined;
  approveActivityForm: UseFormReturnType<
    ActivityLayoutApproveFormType,
    (values: ActivityLayoutApproveFormType) => ActivityLayoutApproveFormType
  >;
  getActivityLayoutFilesData: ActivityLayoutFile[] | undefined;
  handleApproveLayout(values: ActivityLayoutApproveFormType): Promise<void>;
  pageModal: boolean;
  setPageModal: React.Dispatch<React.SetStateAction<boolean>>;
  formLayoutFile: UseFormReturnType<{ files: never[] }>;
  handleAddLayoutFile(values: { files: never[] }): Promise<void>;
  filterLayoutForm: UseFormReturnType<FilterLayoutsFormType>;
  handleFilter(): void;
  handleClear(): void;
}

export function CompanyLayoutList({
  handleSelectLayout,
  getAtivityLayoutToApproveData,
  handlePaginate,
  page,
  selectedLayout,
  approveActivityForm,
  getActivityLayoutFilesData,
  handleApproveLayout,
  pageModal,
  setPageModal,
  formLayoutFile,
  handleAddLayoutFile,
  filterLayoutForm,
  handleFilter,
  handleClear,
}: CompanyLayoutListProps) {
  return (
    <>
      <Flex direction="column" w="99%">
        <form onSubmit={filterLayoutForm.onSubmit(handleFilter)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...filterLayoutForm.getInputProps('os')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClear}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>
        <DataTable
          onRowClick={(record) => handleSelectLayout(record)}
          minHeight={500}
          noRecordsText="Sem layouts"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={page.current}
          totalRecords={getAtivityLayoutToApproveData?.meta.totalItems}
          recordsPerPage={10}
          onPageChange={handlePaginate}
          records={getAtivityLayoutToApproveData?.items}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'teamName',
              title: 'Time',
            },
            {
              accessor: 'companyName',
              title: 'Cliente',
            },
            {
              accessor: 'productGroup',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'engravingText',
              title: 'Logo',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={activityLayoutStatusColor[status]}>
                  {activityLayoutStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            },
            {
              accessor: 'approval',
              title: 'Atualizado em',
              render: ({ approval }) =>
                approval && formatLocale(approval, 'DD/MM/YY'),
            },
          ]}
        />
      </Flex>
      <Modal
        size={520}
        opened={pageModal}
        onClose={() => setPageModal(false)}
        title="Avaliar Layout"
      >
        {selectedLayout && (
          <>
            <ActivityLayoutItem
              activityLayout={selectedLayout as ActivityLayout}
              files={getActivityLayoutFilesData}
            />
            {selectedLayout.status === ActivityLayoutStatus.CLIENT_APPROVE && (
              <>
                <form onSubmit={formLayoutFile.onSubmit(handleAddLayoutFile)}>
                  <CustomMultipleFileInput
                    required
                    withAsterisk
                    name="files"
                    accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
                    label="Arquivos"
                    placeholder="selecione arquivo por vez"
                    formRef={formLayoutFile}
                  />
                </form>
                <form
                  onSubmit={approveActivityForm.onSubmit(handleApproveLayout)}
                >
                  <Flex direction="column">
                    <Textarea
                      required
                      withAsterisk
                      maxLength={255}
                      mt={16}
                      label="Observações"
                      placeholder="escreva aqui qualquer observação de aprovação/reprovação..."
                      name="clientNote"
                      {...approveActivityForm.getInputProps('clientNote')}
                    />
                    <Group position="right" mt={8}>
                      <Button
                        w={100}
                        variant="light"
                        type="submit"
                        onClick={() => {
                          approveActivityForm.setFieldValue(
                            'isApproved',
                            false,
                          );
                        }}
                        color="red"
                      >
                        Reprovar
                      </Button>
                      <Button
                        w={100}
                        variant="light"
                        type="submit"
                        color="green"
                        onClick={() => {
                          approveActivityForm.setFieldValue('isApproved', true);
                        }}
                      >
                        Aprovar
                      </Button>
                    </Group>
                  </Flex>
                </form>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
