import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconArrowLeft,
  IconClock,
  IconPlayerPlay,
  IconPlayerStop,
} from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';

import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import {
  useGetLastOrderPacking,
  useGetOrderPackings,
  useSetOrderPacking,
  useSetOutOrderPacking,
  useSetPackUnpackOrderPackings,
} from '../../data/hooks/order-packings';
import {
  getOrderDetailsRequest,
  getOrderRequest,
  setOrderPackingFinishedProblemRequest,
  setOrderPackingFinishedRequest,
} from '../../data/services/orders';
import { useTimer } from '../../hooks/use-timer';
import { Option, OptionSubTypes, OptionTypes } from '../../models/option';
import { Order, OrderStatus } from '../../models/order';
import { OrderDetail } from '../../models/order-detail';
import {
  OrderPacking as OrderPackingModel,
  PackingType,
} from '../../models/order-packing';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  orderPackingTypeHumanized,
  orderStatusColor,
  orderStatusHumanized,
} from '../../utils/constants';
import { secondsToTime } from '../../utils/helpers';
import { errorNotification } from '../../providers/mantine-notifications';
import { orderDefectOptions } from '../../utils/constants/order';

type PackingPageModalVisible = 'finish-step' | 'finish' | null;

export function OrderPacking() {
  const { id } = useParams();
  const [pageModalVisible, setPageModalVisible] =
    useState<PackingPageModalVisible>(null);
  const [order, setOrder] = useState<Order>();
  const [orderDetails, setOrderDetails] = useState<OrderDetail>();
  const [orderPackingList, setOrderPackingList] = useState<OrderPackingModel[]>(
    [],
  );
  const [lastPacking, setLastPacking] = useState<OrderPackingModel>();
  const [isOnProgress, setisOnProgress] = useState(false);
  const [hasDefect, setHasDefect] = useState<boolean>(false);
  const { fetch: setPackUnpackFetcher, loading: setPackUnpackLoading } =
    useSetPackUnpackOrderPackings();
  const { fetch: getOrderPackingsFetcher, loading: getOrderPacksLoading } =
    useGetOrderPackings();
  const {
    fetch: getLastOrderPackingFetcher,
    loading: getLastOrderPackLoading,
  } = useGetLastOrderPacking();
  const { fetch: setOrderPackingFetcher, loading: setOrderPackLoading } =
    useSetOrderPacking();
  const { fetch: setOutOrderPackingFetcher, loading: setOutOrderPackLoading } =
    useSetOutOrderPacking();
  const {
    fetch: listStepsOptionsFetcher,
    reponseData: listStepsOptionsData,
    loading: listStepsOptionsLoader,
  } = useListOptions();

  const form = useForm({
    initialValues: {
      type: '',
      description: '',
      amount: '',
      defectAmount: '',
    },
  });

  const defectForm = useForm({
    initialValues: {
      defectType: '',
      defectAmount: 0,
    },
  });

  async function getOrder() {
    const orderResponse = await getOrderRequest(Number(id));
    const orderDetailResponse = await getOrderDetailsRequest(Number(id));

    setOrder(orderResponse);
    setOrderDetails(orderDetailResponse);
  }

  async function getOrderPacking() {
    await getOrderPackingsFetcher({
      orderId: Number(id),
      onSuccess: (res) => {
        setOrderPackingList([...res]);
      },
    });
    await getLastOrderPackingFetcher({
      orderId: Number(id),
      onSuccess: (res) => {
        setLastPacking(res.id ? { ...res } : undefined);
      },
    });
  }

  async function handleSubmit(values: typeof form.values) {
    if (values.type === 'finish') {
      await setOrderPackingFinishedRequest(Number(id));
      setPageModalVisible(null);
    } else {
      if (!isOnProgress && orderPackingList) {
        await setOrderPackingFetcher({
          orderId: Number(id),
          data: {
            type: values.type as PackingType,
            description: values.description,
          },
          onSuccess: (res) => {
            setOrderPackingList([
              ...orderPackingList,
              { ...res, clockOut: null },
            ]);
            setLastPacking({ ...res, clockOut: null });
          },
        });
      } else {
        if (
          values.type === PackingType.PACK ||
          values.type === PackingType.UNPACK
        ) {
          await setPackUnpackFetcher({
            id: Number(lastPacking?.id),
            data: {
              amount: Number(values.amount),
              defectAmount: Number(values.defectAmount),
            },
            onSuccess: async () => {
              await getOrderPacking();
              setisOnProgress(false);
              setPageModalVisible(null);
              form.reset();
            },
          });

          return;
        }

        await setOutOrderPackingFetcher({
          id: Number(lastPacking?.id),
          onSuccess: async () => {
            await getOrderPacking();
            setisOnProgress(false);
          },
        });
      }
    }
    form.reset();
    await getOrder();
  }

  async function handleSubmitDefect() {
    if (hasDefect && !defectForm.values.defectType) {
      errorNotification({
        title: 'Selecione um defeito.',
        message: 'campo de defeito obrigatório',
      });
      return;
    }

    await setOrderPackingFinishedProblemRequest(Number(id), {
      hasDefect: defectForm.values.defectType,
      defectQuantity: Number(defectForm.values.defectAmount),
    });
    setPageModalVisible(null);
    window.location.reload();
  }

  async function getStepsOptions() {
    await listStepsOptionsFetcher({
      query: {
        type: OptionTypes.PACKING,
        subtype: OptionSubTypes.STEPS,
      },
    });
  }

  function finishStep() {
    if (lastPacking?.type !== PackingType.PAUSE) {
      setPageModalVisible('finish-step');
    } else {
      handleSubmit(form.values);
    }
  }

  const isAllowedToPacking =
    order?.status === OrderStatus.MATERIAL_RECEIVED ||
    order?.status === OrderStatus.PRODUCED ||
    order?.status === OrderStatus.WAITING_PACKAGING;

  useEffect(() => {
    getOrder();
    getOrderPacking();
    getStepsOptions();
  }, [id]);

  const productionMetrics = useMemo(() => {
    let pauseTime = 0;
    let packTime = 0;
    let unpackTime = 0;
    let unpackAmount = 0;
    let unpackDefectAmount = 0;
    let packAmount = 0;
    let packDefectAmount = 0;

    orderPackingList?.forEach((item) => {
      if (item.clockOut !== null) {
        if (item.type === PackingType.PAUSE) {
          pauseTime += item.timeAmount;
        }
        if (item.type === PackingType.PACK) {
          packTime += item.timeAmount;
          packAmount += item.amount || 0;
          packDefectAmount += item.defectAmount || 0;
        }
        if (item.type === PackingType.UNPACK) {
          unpackTime += item.timeAmount;
          unpackAmount += item.amount || 0;
          unpackDefectAmount += item.defectAmount || 0;
        }
      }
    });

    const {
      hours: pauseHours,
      minutes: pauseMinutes,
      seconds: pauseSeconds,
    } = secondsToTime(pauseTime);
    const {
      hours: packHours,
      minutes: packMinutes,
      seconds: packSeconds,
    } = secondsToTime(packTime);
    const {
      hours: unpackHours,
      minutes: unpackMinutes,
      seconds: unpackSeconds,
    } = secondsToTime(unpackTime);

    return {
      pause: {
        hours: pauseHours,
        minutes: pauseMinutes,
        seconds: pauseSeconds,
      },
      pack: {
        hours: packHours,
        minutes: packMinutes,
        seconds: packSeconds,
        amount: packAmount,
        defectAmount: packDefectAmount,
      },
      unpack: {
        hours: unpackHours,
        minutes: unpackMinutes,
        seconds: unpackSeconds,
        amount: unpackAmount,
        defectAmount: unpackDefectAmount,
      },
    };
  }, [orderPackingList]);

  useEffect(() => {
    if (lastPacking && lastPacking.clockOut === null) {
      setisOnProgress(true);
      form.setFieldValue('type', lastPacking.type);
    }
    defectForm.setFieldValue(
      'defectAmount',
      productionMetrics.unpack.defectAmount,
    );
  }, [orderPackingList]);

  const { days, hours, minutes, seconds } = useTimer({
    deadline: lastPacking?.clockIn ?? '',
  });

  const filteredSteps: Option[] | undefined = listStepsOptionsData?.filter(
    function (item) {
      if (order?.status === OrderStatus.MATERIAL_RECEIVED)
        return item.value !== 'pack';
      if (order?.status === OrderStatus.WAITING_PACKAGING)
        return item.value !== 'unpack';
    },
  );

  if (!order || !orderDetails) return null;

  return (
    <Page>
      <CustomLoader
        loading={
          setPackUnpackLoading ||
          getOrderPacksLoading ||
          getLastOrderPackLoading ||
          setOrderPackLoading ||
          setOutOrderPackLoading ||
          listStepsOptionsLoader
        }
      />
      <Grid gutter="xs" columns={5} w="82vw">
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center">
            <Badge color={orderStatusColor[order.status]} mb={6}>
              {orderStatusHumanized[order.status]}
            </Badge>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid gutter="xs" columns={6} w="82vw">
        <Grid.Col span={3}>
          <Paper p={8} withBorder>
            {order && <OrderDetails orderId={order.id} sections={['basic']} />}
          </Paper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Paper p={16} shadow="md">
            <Text fw="bold">Controle de Embalagem/Desembalagem</Text>
            <Divider />
            <Text>
              você deve controlar através destes botões abaixo o andamento da
              embalagem deste pedido
            </Text>
            <Text>1- selecione a etapa</Text>
            <Text>2- clique no botão "inicar"</Text>
            <Text>
              3- quando terminar a produção selecione a etapa "finalizar" e
              clique em "salvar"
            </Text>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              {isAllowedToPacking && (
                <>
                  <Select
                    w={170}
                    disabled={isOnProgress}
                    required
                    withAsterisk
                    name="role"
                    label="Etapa"
                    placeholder="selecione a etapa"
                    data={
                      filteredSteps?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    mr={8}
                    {...form.getInputProps('type')}
                  />
                  {!isOnProgress && form.values.type === 'pause' && (
                    <Textarea
                      maxLength={255}
                      w={400}
                      mb={16}
                      label="Observações"
                      placeholder="escreva aqui qualquer observação que queira fazer..."
                      name="description"
                      {...form.getInputProps('description')}
                    />
                  )}
                  {form.values.type === 'finish' ? (
                    <>
                      {order.status === OrderStatus.MATERIAL_RECEIVED &&
                      !order.rework ? (
                        <Button onClick={() => setPageModalVisible('finish')}>
                          Salvar
                        </Button>
                      ) : (
                        <Button type="submit">Salvar</Button>
                      )}
                    </>
                  ) : (
                    <Flex align="center">
                      {lastPacking?.clockOut === null ? (
                        <Title mr={24} color="dark.3">
                          {days > 0 && `${days} ${days > 1 ? 'dias' : 'dia'}`}
                          {` ${hours}:${minutes}:${seconds}`}
                        </Title>
                      ) : (
                        <Title mr={24} color="dark.3">
                          00:00:00
                        </Title>
                      )}
                      <Button
                        disabled={isOnProgress}
                        p={0}
                        w={36}
                        h={36}
                        type="submit"
                        mb={-8}
                        radius={18}
                        mr={8}
                      >
                        <IconPlayerPlay color="white" size={26} />
                      </Button>
                      <Button
                        disabled={!isOnProgress}
                        onClick={finishStep}
                        color="red"
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                      >
                        <IconPlayerStop color="white" size={26} />
                      </Button>
                    </Flex>
                  )}
                </>
              )}
              <Divider m={8} />
              <ScrollArea mt={8} h={120}>
                {orderPackingList?.reverse().map((prodItem, index) => {
                  const { hours, minutes, seconds } = secondsToTime(
                    prodItem.timeAmount,
                  );

                  return (
                    <Flex key={index} mt={8} mb={8}>
                      <ThemeIcon p={4} mr={8} color="green" variant="light">
                        <IconClock size={26} />
                      </ThemeIcon>
                      <Text mr={8}>
                        {formatLocale(prodItem.clockIn, 'DD/MM/YY')}
                      </Text>
                      <Text mr={8}>
                        {orderPackingTypeHumanized[prodItem.type]} -
                      </Text>
                      <Text
                        maw={160}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                        mr={8}
                      >
                        {prodItem.packer.username}
                      </Text>
                      <Badge>
                        {prodItem.clockOut !== null &&
                          `${hours}h ${minutes}m ${seconds}s`}
                      </Badge>
                    </Flex>
                  );
                })}
              </ScrollArea>
            </form>
          </Paper>
          <Paper mt={36} p={16} withBorder>
            <Text fw="bold">Métricas</Text>
            <Divider />
            <Flex mt={8} align="center">
              <Text>Tempo em pausa:</Text>
              <Badge>{`${productionMetrics.pause.hours}h ${productionMetrics.pause.minutes}m ${productionMetrics.pause.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>Tempo embalagem:</Text>
              <Badge>{`${productionMetrics.pack.hours}h ${productionMetrics.pack.minutes}m ${productionMetrics.pack.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>Tempo desembalagem:</Text>
              <Badge>{`${productionMetrics.unpack.hours}h ${productionMetrics.unpack.minutes}m ${productionMetrics.unpack.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>Quantidade desembalagem:</Text>
              <Badge color="green.9">{productionMetrics.unpack.amount}</Badge>/
              <Badge color="red">{productionMetrics.unpack.defectAmount}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>Quantidade embalagem:</Text>
              <Badge color="green.9">{productionMetrics.pack.amount}</Badge>/
              <Badge color="red">{productionMetrics.pack.defectAmount}</Badge>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>

      <Modal
        size={500}
        opened={pageModalVisible === 'finish-step'}
        onClose={() => setPageModalVisible(null)}
        title="Finalizar Etapa"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid gutter="xs" columns={2}>
            <Grid.Col mt={8} mb={8} span={2}>
              <Text>
                Para finalizar esta etapa informe a quantidade de peças com
                defeito e as sem defeito nos campos abaixo:
              </Text>
            </Grid.Col>
            <Grid.Col mt={8} mb={8} span={1}>
              <TextInput
                max={order && order.quantity}
                withAsterisk
                required
                label="Quantidade sem defeito"
                placeholder="digite a quantidade"
                mb={16}
                type="number"
                name="amount"
                {...form.getInputProps('amount')}
              />
            </Grid.Col>

            <Grid.Col mt={8} mb={8} span={1}>
              <TextInput
                max={order && order.quantity}
                withAsterisk
                required
                label="Quantidade com defeito"
                placeholder="digite a quantidade"
                mb={16}
                type="number"
                name="defectAmount"
                {...form.getInputProps('defectAmount')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="ltpBlue.9" type="submit">
              Confirmar
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        size={500}
        opened={pageModalVisible === 'finish'}
        onClose={() => {
          setPageModalVisible(null);
          setHasDefect(false);
        }}
        title="Ocorência"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid gutter="xs" columns={2}>
            <Grid.Col mb={8} span={2}>
              <Flex direction="column" justify="center" align="center">
                <Text fw="bold">Encontrou um problema com o pedido?</Text>

                <Flex gap={12} mt="xs">
                  <Button
                    color={hasDefect ? 'green' : 'gray'}
                    onClick={() => setHasDefect(true)}
                  >
                    Sim
                  </Button>
                  <Button
                    color={!hasDefect ? 'red' : 'gray'}
                    onClick={() => setHasDefect(false)}
                  >
                    Não
                  </Button>
                </Flex>

                {hasDefect && (
                  <Flex direction="column" mt="xs">
                    <Select
                      required
                      withAsterisk
                      name="defect"
                      label="Problema"
                      placeholder="selecione o problema"
                      data={
                        orderDefectOptions?.map((option) => ({
                          label: option.label,
                          value: option.value,
                        })) ?? []
                      }
                      mb={16}
                      {...defectForm.getInputProps('defectType')}
                    />
                    <TextInput
                      max={order && order.quantity}
                      withAsterisk
                      required
                      label="Quantidade"
                      placeholder="digite a quantidade"
                      mb={16}
                      type="number"
                      name="defectAmount"
                      {...defectForm.getInputProps('defectAmount')}
                    />
                  </Flex>
                )}
              </Flex>
            </Grid.Col>
          </Grid>
          <Group position="right">
            {hasDefect ? (
              <Button color="ltpBlue.9" onClick={() => handleSubmitDefect()}>
                Salvar
              </Button>
            ) : (
              <Button color="ltpBlue.9" type="submit">
                Salvar
              </Button>
            )}
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
