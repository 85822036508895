import {
  Flex,
  Paper,
  TextInput,
  Select,
  Button,
  Menu,
  Badge,
  Group,
  Checkbox,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconClock, IconDotsVertical, IconMessage } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { NavigateFunction } from 'react-router-dom';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomActivity } from '../../../data/services/activities';
import { formatLocale } from '../../../providers/dayjs-plugins';
import {
  activityComplexityColor,
  activityComplexityHumanized,
  activityStatusColor,
  activityStatusHumanized,
  activityStatusList,
} from '../../../utils/constants/activity';
import { PageMeta } from '../../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
  GetFilterUsersListResponse,
} from '../../../utils/types/data/services/filters';

interface FinalArtActivityListWebProps {
  formFilter: UseFormReturnType<any>;
  companyList: GetFilterCompaniesListResponse[] | undefined;
  activityList: CustomActivity[];
  activityMeta: PageMeta | undefined;
  handleSubmit(): void;
  handlePaginate(page: number): void;
  handleClearFilter(): void;
  currentPage: React.MutableRefObject<number>;
  navigation: NavigateFunction;
  filterUserList: GetFilterUsersListResponse[];
  teamList: GetFilterTeamsListResponse[];
}

export function FinalArtActivityListWeb({
  formFilter,
  activityList,
  activityMeta,
  companyList,
  handleClearFilter,
  handlePaginate,
  handleSubmit,
  currentPage,
  navigation,
  filterUserList,
  teamList,
}: FinalArtActivityListWebProps) {
  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit(() => handleSubmit())}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <TextInput
              label="Identificador"
              placeholder="numero do identificador"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <Select
              clearable
              searchable
              name="team"
              label="Time"
              placeholder="selecione um time"
              data={teamList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('team')}
            />
            <Select
              clearable
              searchable
              name="company"
              label="Cliente"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <Select
              clearable
              searchable
              name="target"
              label="Usuário"
              placeholder="selecione o usuário"
              data={filterUserList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('target')}
            />
            <Select
              clearable
              searchable
              name="status"
              label="Status"
              placeholder="selecione o status"
              data={activityStatusList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('status')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Criado em"
              name="createdAt"
              withAsterisk={false}
              required={false}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('createdAt')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Finalizado em"
              name="finishedAt"
              withAsterisk={false}
              required={false}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('finishedAt')}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
            <Checkbox
              mt={40}
              label="Mostrar finalizados"
              {...formFilter.getInputProps('showDone')}
            />
          </Group>
        </Paper>
      </form>
      <DataTable
        minHeight={500}
        noRecordsText="Sem atividades"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={activityMeta?.totalItems}
        recordsPerPage={10}
        onPageChange={handlePaginate}
        records={activityList}
        columns={[
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'internal_number',
            title: 'Identificador',
          },
          {
            accessor: 'teamName',
            title: 'Time',
          },
          {
            accessor: 'companyName',
            title: 'Cliente',
          },
          {
            accessor: 'productGroup',
            title: 'Produto',
            render: ({ product, product_color, product_material }) =>
              `${product}, ${product_color}, ${product_material}`,
          },
          {
            accessor: 'obs',
            title: 'Descrição',
            render: ({ obs }) =>
              obs && (
                <Tooltip maw={200} multiline label={obs} withArrow>
                  <Button variant="subtle" p={0} children={<IconMessage />} />
                </Tooltip>
              ),
          },
          {
            accessor: 'quantity',
            title: 'Quantidade',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={activityStatusColor[status]}>
                {activityStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'complexity',
            title: 'Complexidade',
            render: ({ complexity }) => (
              <Badge color={activityComplexityColor[complexity]}>
                {activityComplexityHumanized[complexity]}
              </Badge>
            ),
          },
          {
            accessor: 'isPrioritary',
            title: 'Prioritário',
            render: ({ isPrioritary }) => (
              <Badge color={isPrioritary ? `red` : `gray`}>
                {isPrioritary ? `sim` : `não`}
              </Badge>
            ),
          },
          {
            accessor: 'targetName',
            title: 'Atribuido para',
          },
          {
            accessor: 'createdBy',
            title: 'Criado por',
          },
          {
            accessor: 'createdAt',
            title: 'Criado em',
            render: ({ createdAt }) =>
              createdAt && formatLocale(createdAt, 'DD/MM/YY'),
          },
          {
            accessor: 'finishedAt',
            title: 'Finalizado em',
            render: ({ finishedAt }) =>
              finishedAt && formatLocale(finishedAt, 'DD/MM/YY'),
          },
          {
            accessor: 'financial-menu',
            title: '',
            render: (activityItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() =>
                      navigation(`/art-activities/${activityItem.id}`)
                    }
                    icon={<IconClock size={14} />}
                  >
                    Iniciar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
