import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { UseGetReceiptsByOrderProps } from '../../../utils/types/data/hooks/order-receipts';
import { GetReceiptsByOrderResponse } from '../../../utils/types/data/services/order-receipts';
import { getReceiptsByOrderRequest } from '../../services/order-receipt';

export function useGetReceiptsByOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetReceiptsByOrderResponse[]>();

  const fetch = ({ query }: UseGetReceiptsByOrderProps) =>
    fetchData({
      fetcher: () => getReceiptsByOrderRequest(query.orderIds),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar recibos relacionados.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
