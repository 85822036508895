import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import CustomRouter from './components/CustomRouter';
import NotificationProvider from './context/notification-context';
import { injectStore } from './providers/base-api';
import { persistor, store } from './providers/store';

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationProvider>
        <PersistGate persistor={persistor}>
          <CustomRouter>
            <App />
          </CustomRouter>
        </PersistGate>
      </NotificationProvider>
    </Provider>
  </React.StrictMode>,
);
