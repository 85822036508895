import { Modal, ScrollArea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { OrderDetails } from '../../components/OrderDetails';
import { OrderFile } from '../../components/OrderFile';
import { Page } from '../../components/Page';
import { Order } from '../../models/order';
import { UserRole, UserType } from '../../models/user';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import { RootState } from '../../providers/store';
import { getHistoricOrdersPaginatedDispatcher } from '../../store/orders';
import { AdminHomeWeb } from './admin/web';
import { CompanyHomeWeb } from './company/web';
import { PackerHomeWeb } from './packer/web';
import { ReceptionistHomeWeb } from './receptionist/web';
import { TechnicalHomeWeb } from './technical/web';
import { VendorHomeWeb } from './vendor/web';

export type PageModalState = 'order-view' | 'order-files' | null;

export function OrderHistoric() {
  const navigation = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    historic: items,
    loading,
    meta,
  } = useSelector((state: RootState) => state.orders);
  const [filtersCollapse, setFiltersCollapse] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const currentPage = useRef(1);
  const dispatch = useDispatch<any>();
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);

  const formFilter = useForm({
    initialValues: {
      status: null,
      finishForecast: null,
      receiptForecast: null,
      dispatchedAt: null,
      company: null,
      os: '',
      internalNumber: '',
      product: '',
      quantity: undefined,
      engravingText: '',
      limit: 10,
      paymentType: '',
    },
  });

  const formDispatchChange = useForm({
    initialValues: {
      dispatchLocation: selectedOrder?.dispatchLocation ?? '',
      dispatchForecast:
        dayjsPlugins(selectedOrder?.dispatchForecast).toDate() ?? new Date(),
    },
  });

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setPageModalVisible('order-view');
  }

  function handleSubmit() {
    currentPage.current = 1;
    getOrderPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getOrderPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaginated();
  }

  function getOrderPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(getHistoricOrdersPaginatedDispatcher({ page: 1, limit: 10 }));
    } else {
      dispatch(
        getHistoricOrdersPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
        } as any),
      );
    }
  }

  useEffect(() => {
    getOrderPaginated();
  }, []);

  return (
    <Page title="Pedidos [Histórico]">
      <CustomLoader loading={loading} />
      {user?.role === UserRole.COMPANY && (
        <CompanyHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
          handleViewOrder={handleViewOrder}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
          handleViewOrder={handleViewOrder}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
        />
      )}
      {user?.role === UserRole.RECEPTIONIST && (
        <ReceptionistHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
          handleViewOrder={handleViewOrder}
        />
      )}
      {user?.type === UserType.MASTER && (
        <AdminHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
          handleViewOrder={handleViewOrder}
        />
      )}
      {user?.role === UserRole.TECHNICAL && (
        <TechnicalHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      {user?.role === UserRole.PACKER && (
        <PackerHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      {user?.role === UserRole.FINAL_ART && (
        <TechnicalHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      <Modal
        opened={pageModalVisible === 'order-files'}
        onClose={() => setPageModalVisible(null)}
        title="Arquivos"
      >
        <ScrollArea h={400}>
          <OrderFile files={selectedOrder?.files ?? []} />
        </ScrollArea>
      </Modal>
      <Modal
        opened={pageModalVisible === 'order-view'}
        onClose={() => setPageModalVisible(null)}
        title="Pedido"
        size="lg"
      >
        <ScrollArea h={600}>
          {selectedOrder && (
            <OrderDetails
              sections={[
                'basic',
                'prices',
                'service-details',
                'historic',
                'dates',
                'order-receipt-files',
              ]}
              orderId={selectedOrder?.id}
            />
          )}
        </ScrollArea>
      </Modal>
    </Page>
  );
}
