export const useFileDownload = () => {
  const download = (response: Blob, fileName: string) => {
    const url = URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  return {
    download,
  };
};
