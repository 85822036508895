import { AxiosResponse } from 'axios';
import { MaterialStatus, OrderMaterial } from '../../models/order-material';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import { OrderMaterialFileResponse } from '../../utils/types/data/services/order-material-files';

export type CreateOrderMaterialData = {
  company: { id: number };
  product: string;
  productColor: string;
  productMaterial: string;
  quantity: number;
  quantityVolume: number;
  note?: string;
  receivedAt: Date;
  hasNf: boolean;
  nfNumber?: string;
  files?: number[];
};

export type OrderMaterialsPaginatedResponse = PaginatedResponse<OrderMaterial>;

export type OrderMaterialsPaginatedParams = {
  page: number;
  limit: number;
  code?: string;
  product?: string;
  productColor?: string;
  productMaterial?: string;
  receivedAt?: string;
  company?: string;
  status?: string;
  showAssociates?: boolean;
  username?: string;
  quantity?: number;
  quantityVolume?: number;
};

export type ValidateOrderMaterialData = {
  productCondition: string;
  packageCondition: string;
  status: MaterialStatus;
  note: string;
};

export type UpdateOrderMaterialData = Omit<CreateOrderMaterialData, 'company'>;

export async function createOrderMaterialRequest(
  data: CreateOrderMaterialData,
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(`order-materials`, data);

  return response.data;
}

export async function paginatedOrderMaterialsRequest(
  params: OrderMaterialsPaginatedParams,
): Promise<OrderMaterialsPaginatedResponse> {
  const response = await api.get<OrderMaterialsPaginatedResponse>(
    'order-materials',
    { params },
  );

  return response.data;
}

export async function showOrderMaterialsRequest(
  id: number,
): Promise<OrderMaterial> {
  const response = await api.get<OrderMaterial>(`order-materials/${id}`);

  return response.data;
}

export async function updateOrderMaterialRequest(
  id: number,
  data: UpdateOrderMaterialData,
): Promise<OrderMaterial> {
  const response = await api.put<OrderMaterial>(`order-materials/${id}`, data);

  return response.data;
}

export async function associateOrderMaterialRequest(
  id: number,
  data: { orderId: number },
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(
    `order-materials/associate/${id}`,
    data,
  );

  return response.data;
}

export async function validateOrderMaterialRequest(
  id: number,
  data: ValidateOrderMaterialData,
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(
    `order-materials/validate/${id}`,
    data,
  );

  return response.data;
}

export async function deleteOrderMaterialRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`order-materials/${id}`);

  return response;
}

export async function getOrderMaterialFilesRequest(
  id: number,
): Promise<AxiosResponse<OrderMaterialFileResponse[]>> {
  const response = await api.get<OrderMaterialFileResponse[]>(
    `order-materials/files/${id}`,
  );

  return response;
}

export async function deleteOrderMaterialFileRequest(
  fileId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(
    `order-materials/remove-file/${fileId}`,
  );

  return response;
}
