import { Flex, Grid, Text } from '@mantine/core';

import { OrderDetail } from '../../models/order-detail';

interface SilkscreenDetailsProps {
  orderDetails: OrderDetail;
}

export function SilkscreenDetails({ orderDetails }: SilkscreenDetailsProps) {
  return (
    <>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Cores</Text>
          <Text color="gray">{orderDetails.colorsAmount}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Local da gravação</Text>
          <Text color="gray">{orderDetails.personalizationSides}</Text>
        </Flex>
      </Grid.Col>
    </>
  );
}
